import React, { Component } from 'react';
import SideNavBarItem from './SideNavBarItem';
import {permissionMenu} from "../../permission"

const styles = {
    content: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginTop: "5px",
        marginLeft: "10px",
        overflow: "auto"
    },
    title: {
        fontSize: "14pt",
        color: "black"
    },
    back: {
        color: 'gray',
        marginBottom: "20px",
        cursor: "pointer"
    },
    items: {
        color: "black",
    }
};


class SideNavBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemsData: [],
            selectedMenu: false,
            styleBack: null,
            anyClicked: false
        }

    }

    componentDidMount() {
        let itemsData = this.props.itemsData;
        let styleBack = styles.back;
        styleBack.visibility = this.props.selectedMenu ? "hidden" : "visible";

        let anyClicked = itemsData.filter(item => item.clicked)[0] ? true : false;

        if (this.props.setAnyClicked)
            this.props.setAnyClicked(anyClicked);

        this.setState({
            itemsData,
            selectedMenu: this.props.selectedMenu,
            styleBack,
            anyClicked
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedMenu !== this.props.selectedMenu) {
            let styleBack = {
                color: 'gray',
                marginBottom: "20px",
                cursor: "pointer",
                visibility: this.props.selectedMenu ? "hidden" : "visible"
            };
            
            this.setState({
                styleBack,
                selectedMenu: this.props.selectedMenu
            });
        }

        if (prevProps.itemsData !== this.props.itemsData) {
            let itemsData = this.props.itemsData;
            let anyClicked = itemsData.filter(item => item.clicked)[0] ? true : false;
            this.setState({ itemsData, anyClicked });
        }
    }

    menuItemOnClick = (selectedKey) => {
        let itemsData = this.state.itemsData;

        itemsData.forEach( item => item.id === selectedKey ? item.clicked = true : item.clicked = false);

        let anyClicked = itemsData.filter(item => item.clicked)[0] ? true : false;

        if (this.props.setAnyClicked)
            this.props.setAnyClicked(anyClicked);

        this.setState({ itemsData, anyClicked });

        if (this.props.sideMenuItemOnClick)
            this.props.sideMenuItemOnClick(itemsData.filter(item => item.id === selectedKey)[0])
    }

    menuItemOnHover = (selectedKey) => {
        let itemsData = this.state.itemsData;

        itemsData.forEach( item => item.id === selectedKey ? item.selected = true : item.selected = false);

        this.setState({ itemsData });
    }

    onBackClick = () => {
        let itemsData = this.state.itemsData;
        itemsData.forEach( item => item.clicked = false);

        let anyClicked = itemsData.filter(item => item.clicked)[0] ? true : false;
        
        if (this.props.setAnyClicked)
            this.props.setAnyClicked(anyClicked);

        this.setState({ itemsData, anyClicked });

        if (this.props.sideMenuItemOnClick)
            this.props.sideMenuItemOnClick(null);
    }

    renderBackItem = () => {
        return(
            <div style={this.state.styleBack} onClick={this.onBackClick}>
                {"< voltar"}
            </div>
        );
    };

    renderItems = () => {

        return this.state.itemsData.filter(item => {
            return !item.hidden && permissionMenu().includes(item.id)
        }).map( item => {
            return(
                <SideNavBarItem
                    id={item.id}
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    selected={item.selected}
                    clicked={item.clicked}
                    anyClicked={this.state.anyClicked}
                    onClick={this.menuItemOnClick}
                    onMouseHover={this.menuItemOnHover} />
            );
        });
    }

    render() {
        return (
            <div style={styles.content}>
                {this.renderBackItem()}

                <div style={styles.title}>
                    <b>Navegação</b>
                </div>
                
                <div style={styles.items}>
                    {this.renderItems()}    
                </div>
                
            </div>
        );
    }
}

export default SideNavBar;
