import React, { Component } from 'react';
import { Map, TileLayer, ZoomControl, Polygon, Polyline, Marker, Popup, LayerGroup, LayersControl } from 'react-leaflet'
import { images } from '../../assets';
import * as Leaflet from 'leaflet';
import "./LeafletStyles.css";
import AutoRouterReportMapLegend from "./AutoRouterReportMapLegend";
import AutoRouterActivityDetailsModal from "./AutoRouterActivityDetailsModal";
import  { Button } from 'antd';
import * as dateUtils from "../../utils/date_utils";

import moment from 'moment';


const { BaseLayer, Overlay } = LayersControl;

const DGTileUrl =
  'https://access.maxar.com/earthservice/tmsaccess/tms/1.0.0/DigitalGlobe:ImageryTileService@EPSG:3857@jpg/{z}/{x}/{y}.jpg?connectId=f9695616-1112-48f0-8c71-5293ae799786';

const styles = {
    mapDivContainer: {
        width: "100%",
        height: "600px"
    },
    popup: {
        container: { width: "100%", textAlign: "left", fontSize: "11pt", marginLeft: "10px", marginRight: "10px" },
        header: {
            container: { width: "100%", textAlign: "center", marginBottom: "10px" },
            title: { fontSize: "13pt" }
        },
        item: { marginLeft: "10px", marginRight: "10px" },
        action: { width: "100%", textAlign: "center", marginTop: "20px", marginBottom: "20px" }
    }
};

const WIDTH_MENU = 550;
const HEIHGT_MENU = 240;

const defaultCenter = {
    lat: -22.6981819,
    lng: -47.6363315,
    zoom: 13,
};
class AutoRouterReportMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // lat: -13.54384358203125,
            // lng: -56.36115008871822,
            centerPosition: {
                lat: -22.6981819,
                lng: -47.6363315,
                zoom: 10,
            },
            zoom: 13,
            mapStyle: { height: "600px", width: "100%", position: "relative" },
            autoRouterMapReportData: null,
            selectedTasks: null,
            selectedAreaSize: 0,
            selectedVehicleIds: [],
            mapRef: null,
            showActivityDetailsModal: false,
            vehicles: [],
            unityGroups: [],
            activityConfig: null,
            selectedDates: []
        }
    }

    componentDidMount() {
        // window.addEventListener('resize', this.updateScreenDimensions);

        this.setState({
            vehiclesData: this.props.vehiclesData,
            vehicles: this.convertVehicleInfoToVehicles(this.props.vehiclesData),
            unityGroups: this.props.unityGroups,
            activityConfig: this.props.activityConfig
        });

    }

    componentDidUpdate(prevProps) {
        if (prevProps.autoRouterMapReportData !== this.props.autoRouterMapReportData) {
            this.initSelectedDates(this.props.autoRouterMapReportData);

            if(!this.props.autoRouterMapReportData && prevProps.autoRouterMapReportData){
                let autoRouterMapReportData = this.props.autoRouterMapReportData;
                this.setState({ autoRouterMapReportData});
                this.flyToSelectUnity(defaultCenter);
            } else {
                let autoRouterMapReportData = this.props.autoRouterMapReportData;
                let centerPosition = this.extractPositionByAutoRouterMapReportData(autoRouterMapReportData);
                this.normalizeCoordinatesFromAreas(autoRouterMapReportData.protector_area_list);
                this.flyToSelectUnity(centerPosition);
                this.setState({ autoRouterMapReportData});
            }
        }

        if (prevProps.selectedVehicleIds !== this.props.selectedVehicleIds) {
            this.setState({ selectedVehicleIds: this.props.selectedVehicleIds });
        }

        if (prevProps.vehiclesData !== this.props.vehiclesData) {
            this.setState({
                vehicles: this.convertVehicleInfoToVehicles(this.props.vehiclesData),
                vehiclesData: this.props.vehiclesData
            });
        }

        if (prevProps.unityGroups !== this.props.unityGroups) {
            this.setState({ unityGroups: this.props.unityGroups });
        }

        if (prevProps.activityConfig !== this.props.activityConfig) {
            this.setState({ activityConfig: this.props.activityConfig });
        }
    }

    convertVehicleInfoToVehicles = (vehiclesData) => {
        
        if (!vehiclesData) {
            return null;
        }

        let vehiclesList = [];
        Object.entries(vehiclesData).forEach(entry => {
            let vehicles = entry[1];
            vehicles.filter(vehicle => !vehiclesList.filter(v => v.vehicle_identifier === vehicle.vehicle_identifier)[0]).forEach(vehicle => {
                vehiclesList.push(vehicle);
            })
        });

        return vehiclesList;
    }

    initSelectedDates = (autoRouterMapReportData) => {
        if (!autoRouterMapReportData)
            return null;

        let taskRequests = autoRouterMapReportData.task_requests;
        let selectedDates = [];

        let vehiclesData = this.state.vehiclesData;
        
        if (!vehiclesData) {
            return null;
        }

        Object.entries(taskRequests).forEach(entry => {
            let timestampDateStr = entry[0].toString();
            selectedDates.push(this.convertTimestampToShowLayerName(entry[0]));
            let tasks = entry[1];
            
            let vehiclesPerDay = vehiclesData[timestampDateStr];

            if (vehiclesPerDay) {
                let vehiclesLastDate = {};
                let vehiclesAccumTimeDate = {};
                let vehiclesAccumDistanceDate = {};

                tasks.forEach(task => {
                    let vehicle = vehiclesPerDay.filter(v => v.vehicle_identifier === task.vehicle_identifier)[0];

                    if (vehicle) {
                        if (vehiclesLastDate[vehicle.vehicle_identifier]) {
                            let distance = vehicle.distances ? vehicle.distances[vehiclesLastDate[vehicle.vehicle_identifier]] : 0;
                            let minutes = vehicle.times ? vehicle.times[vehiclesLastDate[vehicle.vehicle_identifier]] : 0;
                            
                            task.minutes = minutes - vehiclesAccumTimeDate[vehicle.vehicle_identifier]  
                            task.distance = distance;
                            vehiclesAccumTimeDate[vehicle.vehicle_identifier] = minutes;
                            vehiclesAccumDistanceDate[vehicle.vehicle_identifier] = distance + vehiclesAccumDistanceDate[vehicle.vehicle_identifier];
                            task.accumDistance = vehiclesAccumDistanceDate[vehicle.vehicle_identifier];
                            task.accumMinutes = vehiclesAccumTimeDate[vehicle.vehicle_identifier];
                            vehiclesLastDate[vehicle.vehicle_identifier] = vehiclesLastDate[vehicle.vehicle_identifier] + 1;
                        } else {
                            task.distance = vehicle.distances ? vehicle.distances[0] : 0;
                            task.minutes = vehicle.times ? vehicle.times[0] : 0;
                            task.accumDistance = vehicle.distances ? vehicle.distances[0] : 0;
                            task.accumMinutes = vehicle.times ? vehicle.times[0] : 0;
                            vehiclesLastDate[vehicle.vehicle_identifier] = 1;
        
                            vehiclesAccumTimeDate[vehicle.vehicle_identifier] = vehicle.times ? vehicle.times[0] : 0;
                            vehiclesAccumDistanceDate[vehicle.vehicle_identifier] = vehicle.distances ? vehicle.distances[0] : 0;
                        }
                    }
                });
            }
        });

        this.setState({ selectedDates });
    }

    convertTimestampToShowLayerName = (timestamp) => {
        return moment(parseInt(timestamp)).format("DD/MM/YYYY");
    }

    getLegendVehicleDistanceAndTime = () => {
        if (!this.state.vehicles)
            return null;

        return this.state.vehicles.filter(vehicle => this.state.autoRouterMapReportData.vehicles.find(v => v.identifier === vehicle.vehicle_identifier)).map(vehicle => {
            let reportDataVehicle = this.state.autoRouterMapReportData.vehicles.filter(v => v.id === vehicle.vehicle_identifier)[0];
            let index = this.state.autoRouterMapReportData.vehicles.indexOf(reportDataVehicle);

            let legendItem = {
                vehicle_identifier: vehicle.vehicle_identifier,
                color: this.decimalToHexString(index * 100000),
                minutes: 0,
                distance: 0
            };
            
            Object.entries(this.state.vehiclesData).forEach(entry => {
                let date = this.convertTimestampToShowLayerName(entry[0]);
                let vehicles = entry[1];

                if (this.state.selectedDates.includes(date)) {
                    let selectedVehicle = vehicles.filter(v => v.vehicle_identifier === vehicle.vehicle_identifier)[0];

                    if (selectedVehicle) {
                        legendItem.minutes += selectedVehicle.minutes;
                        legendItem.distance += selectedVehicle.distance;
                    }
                }
            });

            legendItem.minutes = dateUtils.formatMinutesToShow(legendItem.minutes);
            legendItem.title = legendItem.vehicle_identifier + " - " + legendItem.distance.toFixed(2) + " km - " + legendItem.minutes;
            

            return legendItem;
        });
    }    

    handleShowActivityDetailsOnClick = (taskRequests, areaSize) => {
        this.setState({ showActivityDetailsModal: true, selectedTaskRequests: taskRequests, selectedAreaSize: areaSize });
    }

    handleActivityDetailsOnClose = () => {
        this.setState({ showActivityDetailsModal: false });
    }

    handleOnAddDateToMap = layer => {
        let selectedDates = this.state.selectedDates;
        selectedDates.push(layer.name);
        this.setState({ selectedDates });
    }

    handleOnRemoveDateToMap = layer => {
        let selectedDates = this.state.selectedDates;
        let index = selectedDates.indexOf(layer.name);
        if (index >= 0) {
            selectedDates.splice(index, 1);
        }
        this.setState({ selectedDates });
    }

    setMapRef = ref => {
        this.setState({
            mapRef: ref && ref.leafletElement
        });
    }

    copyLocationToClipboard = (lat, lng) => {
        navigator.clipboard.writeText(lat + ", " + lng);
    }

    extractPositionByAutoRouterMapReportData = (autoRouterMapReportData) => {
        let areas = autoRouterMapReportData.protector_area_list;
        
        if (areas && areas.length) {
            if (autoRouterMapReportData.vehicles[0].unity) {
                return {
                    lat: autoRouterMapReportData.vehicles[0].unity.lat,
                    lng: autoRouterMapReportData.vehicles[0].unity.lng,
                    zoom: 13,
                };
            }
        }
        
        return null;
    }

    flyToSelectUnity = unityCoord => {
        if (!unityCoord)
            return null;
        
        this.state.mapRef.flyTo([unityCoord.lat, unityCoord.lng], unityCoord.zoom, {animated: true, duration: 2});
    }

    normalizeCoordinatesFromAreas = (areas) => {
        if (!areas) {
            return;
        }
        
        areas.forEach(area => {

            if (area.properties && area.properties.centroid && area.properties.centroid.length === 2) {
                area.centroide = {
                    lat: area.properties.centroid[1],
                    lng: area.properties.centroid[0],
                };
            } else {
                area.centroide = {
                    lat: area.geometry.coordinates[0][0][1],
                    lng: area.geometry.coordinates[0][0][0],
                };
            }

            

            area.geometry.coordinates.forEach(c1 => {
                c1.forEach(c2 => {
                    let temp = c2[0];
                    c2[0] = c2[1];
                    c2[1] = temp;
                });
            });
        });
    }

    updateScreenDimensions = () => {
        this.setState({mapStyle: { height: window.innerHeight - HEIHGT_MENU, width: window.innerWidth - WIDTH_MENU }});
    }

    getRouteLineCoordinates = (areas, vehicle) => {
        let positions = [];

        if (vehicle.unity) {
            positions.push(vehicle.unity);
        }

        positions.push.apply(positions, areas.filter(area => !!area).map(area => area.centroide));

        if (vehicle.unity) {
            positions.push(vehicle.unity);
        }

        return positions;
    }

    getVehicleById = vehicleId => {
        return this.state.autoRouterMapReportData.vehicles.filter(v => v.id === vehicleId)[0];
    }

    decimalToHexString = number => {
        if (number < 0) {
            number = 0xFFFFFFFF + number + 1;
        }

        let result = number.toString(16).toUpperCase();
        while (result.length < 6) {
            result = "0" + result;
        }

        return "#" + result;
    }

    getIndexOrder = (tasks, task) => {
        let areas = [...new Set(tasks.map(t => t.protector_area_id))];
        
        return areas.indexOf(task.protector_area_id) + 1;
    }

    renderUnityMarker = (selectedTaskRequests) => {
        let selectedVehiclesId = selectedTaskRequests.map(tr => tr.vehicle_identifier);

        // get unities from selected task requests
        let unityIds = this.state.autoRouterMapReportData.vehicles.filter(vehicle => 
            vehicle.unity && selectedVehiclesId.includes(vehicle.id)
        ).map(vehicle => vehicle.unity.id);
        
        // get unique unities
        unityIds = unityIds.filter((item, pos) => {
            return unityIds.indexOf(item) === pos;
        });

        let icon = Leaflet.icon({
            iconUrl: images.map_marker_icon,
            iconSize: [35, 45],
            popupAnchor: [0, -20],
            shadowUrl: 'my-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94]
        });

        return unityIds.map(unityId => {
            let unity = this.state.autoRouterMapReportData.vehicles.filter(v => v.unity && v.unity.id === unityId).map(v => v.unity)[0];
            
            return(
                <Marker
                    position={unity}
                    key={"UNITY" + unity.id}
                    color="black"
                    icon={icon}>
                    <Popup>
                        <div>
                            <div>
                                Sede da unidade {unity.name}
                            </div>
                        </div>
                    </Popup>
                </Marker>
            );
        });
    };


    renderTaskDescription = (taskRequests, areas, taskExecuted) => {

        let rendered = [];

        return taskRequests.map(taskRequest => {
            const area = areas.filter(a => parseInt(a.id) === taskRequest.protector_area_id)[0];
            if (!area)
                return null;
            const selectedTasksRequests = taskRequests.filter( t => (t.protector_area_id.toString() === area.id));
            const tasks = selectedTasksRequests.map( t => " " + t.activity_name).toString();
            const areaSize = area.properties.area ? (Math.round(area.properties.area * 10) / 10).toFixed(2) : 0.00;
            const accumMinutes = selectedTasksRequests.reduce((prev, current) => (prev.accumMinutes > current.accumMinutes) ? prev : current).accumMinutes
            const minutes = selectedTasksRequests.map(t => t.minutes).reduce((m1, m2) => m1 + m2, 0);
            
            const key = "TASK" + selectedTasksRequests[0].start_date + area.id;

            if (rendered.includes(key)) {
                return null;
            }

            rendered.push(key);

            return(
                <Marker position={area.centroide} key={key}>
                    <Popup>
                        <div style={styles.popup.container}>
                            <div style={styles.popup.header.container} onClick={() => this.copyLocationToClipboard(area.centroide.lat, area.centroide.lng)}>
                                <div style={styles.popup.header.title}>
                                    <b>Talhão: {taskRequest.raizen_farm_id}, {taskRequest.raizen_zone_id}, {taskRequest.raizen_area_id}</b>
                                </div>
                                <div>
                                    {areaSize} ha
                                </div>
                            </div>

                            <div style={styles.popup.item}>
                                Tarefas: {tasks}
                            </div>
                            <div style={styles.popup.item}>
                                Distância: {selectedTasksRequests[0].distance ? selectedTasksRequests[0].distance.toFixed(2) : "0"} km
                            </div>
                            <div style={styles.popup.item}>
                                Distância acumulada: {selectedTasksRequests[0].accumDistance ? selectedTasksRequests[0].accumDistance.toFixed(2) : "0"} km
                            </div>
                            <div style={styles.popup.item}>
                                Tempo: {dateUtils.formatMinutesToShow(minutes)}
                            </div>
                            <div style={styles.popup.item}>
                                Tempo acumulado: {dateUtils.formatMinutesToShow(accumMinutes)}
                            </div>
                            <div style={{ ...styles.popup.item, display: taskExecuted ? "block" : "none" }}>
                                {taskRequest.description}
                            </div>
                            <div style={{ ...styles.popup.item, display: taskExecuted ? "block" : "none" }}>
                                <i>Essa tarefa é a {this.getIndexOrder(taskRequests, taskRequest)}ª à ser executada</i>
                            </div>
                            <div style={styles.popup.action}>
                                <Button onClick={() => this.handleShowActivityDetailsOnClick(selectedTasksRequests, area.properties.area)}>Detalhes</Button>
                            </div>
                            
                        </div>
                    </Popup>
                </Marker>
            );
        });
    }

    renderAreaPolygons = (areas) => {
        return areas.map(area => {
            return(
                <Polygon key={"POLYGON" + area.id} color="purple" positions={area.geometry.coordinates} />
            );
        });
    }

    renderAreaPolygonsNotExecute = (areas) => {
        return areas.map(area => {
            return(
                <Polygon key={"POLYGON" + area.id} color="red" positions={area.geometry.coordinates} />
            );
        });
    }

    renderRouteLine = (taskRequests, areas) => {
        let vehicleIdentifiers = [...new Set(taskRequests.map(tr => tr.vehicle_identifier))];

        return vehicleIdentifiers.map(vehicleIdentifier => {
            let selectedAreas = taskRequests.filter(tr => tr.vehicle_identifier === vehicleIdentifier).map(tr => {
                return areas.filter(a => parseInt(a.id) === tr.protector_area_id)[0];
            });

            let selectedVehicle = this.state.autoRouterMapReportData.vehicles.filter(v => v.id === vehicleIdentifier)[0];
            let index = this.state.autoRouterMapReportData.vehicles.indexOf(selectedVehicle);

            let vehicle = this.getVehicleById(vehicleIdentifier);

            let positions = this.getRouteLineCoordinates(selectedAreas, vehicle);

            return(
                <Polyline key={"ROUTE" + vehicleIdentifier} color={this.decimalToHexString(index * 100000)} positions={positions} />
            );
        });

    }

    renderDataByDate = () => {
        if (!this.state.autoRouterMapReportData) {
            return null;
        }

        let areas = this.state.autoRouterMapReportData.protector_area_list;
        let autoRouterInputTasks = this.state.autoRouterMapReportData.task_requests;
        if (!autoRouterInputTasks) {
            return null;
        }

        let selectedVehicleIds = this.props.selectedVehicleIds ? this.props.selectedVehicleIds : [];

        let polygons = Object.entries(autoRouterInputTasks).map(entry => {
            let date = entry[0];
            let taskRequests = entry[1];
            let selectedTaskRequests = selectedVehicleIds.length ? 
                taskRequests.filter(tr => selectedVehicleIds.includes(tr.vehicle_identifier)) : taskRequests;

            let areaIds = selectedTaskRequests.map(tr => tr.protector_area_id);
            let selectedAreas = areas.filter(a => areaIds.includes(parseInt(a.id)));

            return(
                <Overlay key={date} name={this.convertTimestampToShowLayerName(date)} checked>
                    <LayerGroup>
                        {this.renderAreaPolygons(selectedAreas)}
                        {this.renderTaskDescription(selectedTaskRequests, selectedAreas, true)}
                        {this.renderRouteLine(selectedTaskRequests, selectedAreas)}
                        {this.renderUnityMarker(selectedTaskRequests)}
                    </LayerGroup>
                </Overlay>
            );
        });

        return polygons;
    }

    renderDataByTasksNotExecuted = () => {
        if (!this.state.autoRouterMapReportData) {
            return null;
        }

        let areas = this.state.autoRouterMapReportData.protector_area_list;
        let autoRouterInputNotExecutedTasks = this.state.autoRouterMapReportData.task_requests_not_executed;
        if (!autoRouterInputNotExecutedTasks) {
            return null;
        }
        let areaIds = autoRouterInputNotExecutedTasks.map(tr => tr.protector_area_id);
        areas = areas.filter(a => areaIds.includes(parseInt(a.id)));

        return(
            <Overlay key="not_executed" name="Não Executadas">
                <LayerGroup>
                    {this.renderAreaPolygonsNotExecute(areas)}
                    {this.renderUnityMarker(autoRouterInputNotExecutedTasks)}
                    {this.renderTaskDescription(autoRouterInputNotExecutedTasks, areas)}
                </LayerGroup>
            </Overlay>
        );

    }

    renderMapLegend = () => {
        if (!this.state.autoRouterMapReportData) {
            return null;
        }

        let legendItems = this.getLegendVehicleDistanceAndTime();

        return(
            <AutoRouterReportMapLegend legendItems={legendItems} />
        );
    }

    renderActivityDetails = () => {
        return(
            <AutoRouterActivityDetailsModal
                showModal={this.state.showActivityDetailsModal}
                onClose={this.handleActivityDetailsOnClose}
                tasks={this.state.selectedTaskRequests}
                vehicles={this.state.vehicles}
                activityConfig={this.state.activityConfig}
                areaSize={this.state.selectedAreaSize} />
        );
    }


    renderMap = () => {
        return(
            <div style={styles.mapDivContainer}>
                <Map ref={this.setMapRef} 
                    center={this.state.centerPosition} 
                    zoom={this.state.zoom} style={this.state.mapStyle} 
                    zoomControl={false}
                    onoverlayadd={this.handleOnAddDateToMap}
                    onoverlayremove={this.handleOnRemoveDateToMap} >
                    <ZoomControl position="topleft" />
                    <LayersControl position="topright">

                        <BaseLayer checked name="Mapa">
                            <TileLayer tms={true} attribution="(c) DigitalGlobe" url={DGTileUrl} maxZoom={25} maxNativeZoom={18} />
                        </BaseLayer>

                        {this.renderDataByDate()}
                        {this.renderDataByTasksNotExecuted()}
                    </LayersControl>

                    {this.renderMapLegend()}

                    {this.renderActivityDetails()}
                </Map>
            </div>
        );
    }

    render() {
        return this.renderMap();
    }
}

export default AutoRouterReportMap;