import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";

export const FETCH_UNITY_GROUP_LIST = "fetch_unity_group_list";


const baseAPI = process.env.REACT_APP_API_URL;

export const fetchUnityGroupList = () => async dispatch => {
    await axios.get(baseAPI + "unity-group/", { headers: getDefaultHeader() })
        .then(
            (res) => { defaultSuccessCallback(dispatch, res, FETCH_UNITY_GROUP_LIST) },
            (error) => { defaultErrorCallback(dispatch, error, FETCH_UNITY_GROUP_LIST) }
        );
}