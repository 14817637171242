


export const formatMinutesToShow = (minutes) => {
    if (!minutes) {
        return 0;
    }
    
    minutes = Math.round(minutes);

    if (minutes >= 60) {
        let hoursStr = parseInt(minutes / 60).toString();
        let minutesStr = (minutes % 60).toString();

        if (hoursStr.length < 2) {
            hoursStr = "0" + hoursStr;
        }
        if (minutesStr.length < 2) {
            minutesStr = "0" + minutesStr;
        }

        return hoursStr + "h" + minutesStr + "min";
    } else {
        return minutes + " min";
    }
}

export const formatMinutesToShowJustNumbers = (minutes) => {
    if (!minutes) {
        return 0;
    }
    
    minutes = Math.round(minutes);

    if (minutes >= 60) {
        let hoursStr = parseInt(minutes / 60).toString();
        let minutesStr = (minutes % 60).toString();

        if (hoursStr.length < 2) {
            hoursStr = "0" + hoursStr;
        }
        if (minutesStr.length < 2) {
            minutesStr = "0" + minutesStr;
        }

        return hoursStr + ":" + minutesStr;
    } else {
        return minutes;
    }
}