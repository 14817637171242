import React, {Component} from 'react';
import  { Button, Modal, Upload, Spin, Icon, Select, DatePicker} from 'antd';
import { images } from '../../assets';
import * as taskManagerActions from "../../actions/taskManagerActions";
import { connect } from "react-redux";

const styles = {
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
    },
    buttonOk: {
        backgroundColor: '#782777',
        color: '#fff',
        borderColor: '#782777',
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        shapefileButton: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: 0,
            color:  "#ffffff",
            fontSize: "12pt",
            marginRight: "16pt"
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        buttonDisabled: {
            backgroundColor: '#dddddd',
            borderColor: '#000',
            color: '#000',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        }
    },
    loading: {
        display: "inline-block",
        text: {
                display: "inline-block",
                marginLeft: "10px",
                marginRight: "20px"
            },
        icon: {
            display: "inline-block",
            color: "#782777",
            fontSize: 48
        }
    }
}

const { Option } = Select;

class ShapefileModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            uploading: false,
            data: {},
            fileList: null,
            activities: [],
            isUpdating: false
        }
    }


    componentDidMount() {
        this.props.getAllActivities();
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible !== this.state.visible){
            this.setState({
                visible: this.props.visible,
                fileList: null,
                data: {}
            })
        }
        
        if (prevProps.data !== this.props.data){
            let data = this.props.data;
            if (!data) {
                data = {};
            }
            
            this.setState({
                data,
                isUpdating: !!this.props.data,
                fileList: null
            })
        }

        if (prevProps.fetchAllActivities !== this.props.fetchAllActivities) {
            if (!this.props.fetchAllActivities.errorStatus){
                this.setState({activities: this.props.fetchAllActivities});
            } else {
                this.setState({uploading: false});
            }
        }

        if (prevProps.uploadFileShape !== this.props.uploadFileShape) {
            if (!this.props.uploadFileShape.errorStatus) {
                this.setState({uploading: false})
                this.props.onClose();
                this.props.getShapefileList();
            } else {
                this.setState({uploading: false})
            }
        }
    }

    isFilled = () => {
        if (this.state.isUpdating) {
            return this.state.data && this.state.data.dueDate; 
        } else {
            return this.state.data && this.state.fileList && this.state.data.dueDate && this.state.data.activity
        }
        
    }

    handleSelectShapeOK = () => {
    
        const params = {
            file: this.state.fileList && this.state.fileList[0],
            dueDate: this.state.data.dueDate.format("x"),
            activityId: this.state.data.activity.id,
            id: this.state.data.id
        };

        if (this.state.isUpdating) {
            this.props.updateShapefile(params);
        } else {
            this.props.uploadFileShapeToCreateTasks(params);
        }
        

        this.setState({
            uploading: true
        });
        
    }

    handleSelectShapeCancel = e => {
        this.props.onClose();
    }

    onChangeDueDate = date => {
        let data = this.state.data;

        if (!data) {
            data = {};
        }

        data.dueDate = date;
        
        this.setState({ data });
    }

    onChangeActivity = id => {
        this.setState({
            data: {
                ...this.state.data,
                activity: {
                    id: id
                }
            }
        })
    }

    renderLoading = () => {
        if (!this.state.uploading) {
            return null;
        }

        return (
            <Spin data-cy="edit-shapefile-modal-loading" indicator={<Icon type="loading" style={styles.loading.icon} spin />} ></Spin>
        );

    }
    renderUploadFileButton = () => {
        if (this.state.isUpdating) {
            return null;
        }
        
        const props = {
            onRemove: file => {
              this.setState(state => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    fileList: newFileList,
                };
              });
            },
            beforeUpload: file => {
              this.setState(state => ({
                    ...state,
                    fileList: [file],
                }
              ));
              return false;
            },
            fileList: this.state.fileList
        };

        return(
            <div>
                <p style={styles.upload.label}>Selecione um arquivo (.zip) </p>
                <Upload {...props}>
                    <Button data-cy="shapefile-edit-modal-upload-button" style={styles.upload.button} size={'large'} type="primary">
                        <img src={images.upload_icon} alt={"upload_icon"}></img>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                </Upload>
                
            </div>
        );
    }

    renderActivitySelectOptions = () => {
        if (!this.state.activities) {
            return null;
        }

        return this.state.activities.map(activity => 
            <Option data-cy={'shapefile-edit-modal-activity-'+activity.id} key={activity.id} value={activity.id}> {activity.name} </Option>
        )
    }

    renderModalContent = () => {
        if (!this.props.visible) {
            return null;
        }

        return (
            <div>
                <form>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 , flexFlow: 'row wrap'}}>
                        <label style={styles.form.label}>
                            ID:
                            <input disabled
                                type="text" 
                                name="name" 
                                defaultValue={this.state.isUpdating ? this.state.data.id : ''}
                                style={{...styles.form.input, width: 50, height: 32, marginRight: 10}} 
                                />
                        </label>
                        <label style={{...styles.form.label, flex: 1}}>
                            Data de validade
                            <DatePicker 
                                id='shapefile-edit-modal-validate-date'
                                placeholder="Selecione a data" 
                                value={this.state.data.dueDate}
                                onChange={this.onChangeDueDate} 
                                />
                        </label>
                    </div>
                    <label style={styles.form.label}>
                        Atividade:
                        <Select data-cy='shapefile-edit-modal-select-activity'
                                size={"large"}
                                showArrow={true}
                                value={this.state.data.activity ? this.state.data.activity.id : null}
                                onChange={this.onChangeActivity}
                                disabled={this.state.isUpdating}
                                >
                            {this.renderActivitySelectOptions()}
                        </Select>
                    </label>
                </form>

                {this.renderUploadFileButton()}
                {this.renderLoading()}
            </div>
        );
    }

    render() {
        return (
            <Modal
                title="Selecionar shape"
                visible={this.props.visible}
                onOk={this.handleSelectShapeOK}
                style={{paddingTop: "12px"}}
                onCancel={this.handleSelectShapeCancel}
                okButtonProps={{style: !this.isFilled() ? {...styles.buttonOk, opacity: 0.5} : styles.buttonOk, disabled: !this.isFilled(), data_cy:'shapefile-edit-modal-ok-button' }}
            >
                {this.renderModalContent()}
            </Modal>
        );
    }
}

function mapStateToProps({uploadFileShape, fetchAllActivities }) {
    return { uploadFileShape, fetchAllActivities };
}

export default connect(mapStateToProps, { ...taskManagerActions })(ShapefileModal);