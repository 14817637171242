import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";
import FileDownload from 'js-file-download';

export const UPLOAD_FILE_EXCEL = "upload_file"
export const UPLOAD_FILE_SHAPE = 'upload_file_shape'
export const DOWNLOAD_EXCEL_MODEL = "download_excel_model"
export const FETCH_ACTIVITY_SHAPEFILE = "fetch_activity_shapefile"
export const FETCH_ACTIVITIES = "fetch_activities"
export const FETCH_VEHICLE_TYPES = "fetch_vehicle_types"
export const REMOVE_SHAPEFILE = "remove_shapefile"

const baseAPI = process.env.REACT_APP_API_URL;

export const uploadFileExcelToCreateTasks = (params) => async dispatch => {
    let formData = new FormData()
    formData.append('file', params.file)
    await axios.post(baseAPI + "task/create-from-excel",
    formData,
    { headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, UPLOAD_FILE_EXCEL),
            (error) => defaultErrorCallback(dispatch, error, UPLOAD_FILE_EXCEL)
        );
}


export const updateShapefile = (params) => async dispatch => {
    let data = {
        id: params.id,
        "expiration_date": params.dueDate
    }

    await axios.put(baseAPI + "task/shapefile",
    data,
    { headers: { ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, UPLOAD_FILE_SHAPE),
            (error) => defaultErrorCallback(dispatch, error, UPLOAD_FILE_SHAPE)
        );
}

export const uploadFileShapeToCreateTasks = (params) => async dispatch => {
    let formData = new FormData()
    formData.append('file', params.file)
    formData.append('expiration_date', params.dueDate)
    formData.append('activity_id', params.activityId)
    await axios.post(baseAPI + "task/shapefile",
    formData,
    { headers: { "Content-Type": "multipart/form-data", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, UPLOAD_FILE_SHAPE),
            (error) => defaultErrorCallback(dispatch, error, UPLOAD_FILE_SHAPE)
        );
}

export const getActivityShapefile = () => async dispatch => {
    await axios.get(baseAPI + "task/shapefile",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_ACTIVITY_SHAPEFILE),
            (error) => defaultErrorCallback(dispatch, error, FETCH_ACTIVITY_SHAPEFILE)
        );
    
}

export const deleteShapefile = params => async dispatch => {
    await axios.delete(baseAPI + "task/shapefile/" + params,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    )
    .then(
        (res) => defaultSuccessCallback(dispatch, res, REMOVE_SHAPEFILE),
        (error) => defaultErrorCallback(dispatch, error, REMOVE_SHAPEFILE)
    );
}

export const getAllActivities = () => async dispatch => {
    await axios.get(baseAPI + "activity",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_ACTIVITIES),
            (error) => defaultErrorCallback(dispatch, error, FETCH_ACTIVITIES)
        );
    
}

export const getAllVehicleTypes = () => async dispatch => {
    await axios.get(baseAPI + "vehicle-type/list",
        { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    )
    .then(
        (res) => defaultSuccessCallback(dispatch, res, FETCH_VEHICLE_TYPES),
        (error) => defaultErrorCallback(dispatch, error, FETCH_VEHICLE_TYPES)
    );
}

export const downloadExcelModel = (setProgress) => async dispatch => {
    await axios.get(baseAPI + "task/download-excel-model",
    
    { onDownloadProgress: function(progressEvent) {
        setProgress(parseFloat(((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)));
    }, headers: { 'Content-Type': 'blob', ...getDefaultHeader() }, responseType: 'arraybuffer'},
        )
        .then(
            (res) => {
                defaultSuccessCallback(dispatch, res, DOWNLOAD_EXCEL_MODEL);
                FileDownload(res.data, "Agendamento massivo - Modelo.xlsx");
            },
            (error) => {
                defaultErrorCallback(dispatch, error, DOWNLOAD_EXCEL_MODEL);
            }
        );
}