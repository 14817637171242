import React, { Component } from "react";

import { Input, Divider, Button, Spin, Icon } from "antd";
import { images } from "../../assets";

import { connect } from "react-redux";
import * as authenticationActions from "../../actions/authenciationActions";

import { withRouter } from "react-router-dom";
import queryString from 'query-string'

const ACTIVATE_ADFS_LOGIN = process.env.REACT_APP_ACTIVATE_ADFS_LOGIN === "true";

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        height: '100%'
    },
    inputLabel: {
        marginBottom: "2px",
        marginLeft: "5px",
        fontSize: "10pt"
    },
    formContainer: {
        width: '50%'
    },
    inputContainer: {
        marginTop: "20px"
    },
    contentLeft: {
        display:'flex',
        flex: 1,
        alingItems:'center',
        justifyContent:'center'
    },
    contentRight: {
        flex: 1,
        height:'100%',
        width:'100%'
    },
    imageContainger: {
        width: "100%",
        height: "100%"
    },
    image: {
        width: "100%",
        height: "100%",
        backgroundImage: `url(${images.login_image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    headerImages: {
        marginBottom: "40px"
    },
    button: {
        width: "100%",
        marginTop: "40px"
    }
};

const cognitoRedirectUrl = process.env.REACT_APP_COGNITO_REDIRECT_URI; // "https://protector.strider.ag/user/oauth2/cognito/";
const raizenCognitoClientId ='3t4glrcei9l7ejfd652caeo386';
const cognitoAuthentication = 'Basic M3Q0Z2xyY2VpOWw3ZWpmZDY1MmNhZW8zODY6bHRudjRscDNzMjR2dTd2aGk3N3Zzb2FuazJ1c3ZlMHEyaTBhb3UxbXNqbjc2YzhsdDNw';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null,
            loading: false,
        }

    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        const code = values.code;
        if (code) {
            this.handleADFSRedirect(code);
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.fetchAuthentication !== this.props.fetchAuthentication) {
            this.setState({ loading: false });
            if (!this.props.fetchAuthentication.errorStatus) {
                this.props.history.push("/");
            }
        }

    }

    handleUsernameOnChange = ({ target: { value }}) => {
        this.setState({ username: value });
    };

    handlePasswordOnChange = ({ target: { value }}) => {
        this.setState({ password: value});
    }

    contentOnKeyPress = (e) => {
        if(e.key === 'Enter'){
            this.subimitLogin();
        }
    }

    subimitLogin = () => {
        let params = {
            username: this.state.username,
            password: this.state.password
        }

        this.props.sendAuthentication(params);
        this.setState({ loading: true });
    }

    submitADFSLogin = () => {
        this.setState({ loading: true });
        const url = `https://raizen-dev2.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Raizen&redirect_uri=${cognitoRedirectUrl}&response_type=CODE&client_id=${raizenCognitoClientId}&scope=aws.cognito.signin.user.admin openid`
        window.location.href = url;
    }

    handleADFSRedirect(code) {
        let params = {
            code : code,
            client_id: raizenCognitoClientId,
            redirect_uri: cognitoRedirectUrl,
            cognitoAuthentication: cognitoAuthentication
        }

        this.props.sendCognitoAuthentication(params);
        this.setState({ loading: true });
    }

    renderHeaderLogin = () => {
        return(
            <div>
                <div style={styles.headerImages}>
                    <img src={images.top_nav_bar_strider} alt={"top_nav_bar_strider"} />
                    <Divider type={""} />
                    <img src={images.top_nav_bar_raizen} alt={"top_nav_bar_raizen"}/>
                </div>
                <h2>Entrar no Painel Strider | Raízen</h2>
                <p>Acesse sua conta e comece agora.</p>

            </div>
        );
    }

    renderDataInputs = () => {
        return (
            <div>
                <div style={styles.inputContainer}>
                    <p style={styles.inputLabel}>Nome de usuário</p>
                    <Input 
                        placeholder="digite seu nome de usuário"
                        onChange={this.handleUsernameOnChange}
                        value={this.state.username}
                        size="large"
                        data-cy = "user-name" />
                </div>
                
                <div style={styles.inputContainer}>
                    <p style={styles.inputLabel}>Senha</p>
                    <Input.Password 
                        placeholder="digite sua senha"
                        value={this.state.password}
                        onChange={this.handlePasswordOnChange}
                        size="large" 
                        data-cy = "user-password"/>
                </div>
            </div>
        );
    }

    renderLoading = () => {
        return(
            <div>
                <Spin indicator={<Icon type="loading" style={{ fontSize: 48 }} spin />} />
            </div>
        );
    }

    renderADFSButton = () => {
        if (!ACTIVATE_ADFS_LOGIN) {
            return null;
        }

        if (this.state.loading) {
            return;
        } else {
            return(
                <Button size="large" style={styles.button} onClick={this.submitADFSLogin}>Entrar com ADFS</Button>
            );
        }        
    };

    renderButton = () => {
        if (this.state.loading) {
            return this.renderLoading();
        } else {
            return(
                <Button data-cy="login-button" size="large" style={styles.button} onClick={this.subimitLogin}>Entrar</Button>
            );
        }        
    };

    renderImage = () => {
        return(
            <div style={styles.image}>
            </div>
        );
    }

    render() {
        return (
            <div style={styles.container} onKeyPress={this.contentOnKeyPress}>
                <div style={styles.contentLeft}>
                    <div style={styles.formContainer}>
                        {this.renderHeaderLogin()}
                        {this.renderDataInputs()}
                        {this.renderButton()}
                        {this.renderADFSButton()}
                    </div>
                </div>
                <div style={styles.contentRight}>
                    {this.renderImage()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({ fetchAuthentication }) {
    return { fetchAuthentication };
}

export default connect(mapStateToProps, authenticationActions)(withRouter(Login));