import React, {Component} from 'react';
import { connect } from "react-redux";
import * as autoRouterActions from "../../actions/autoRouterActions";
import {Table,  Icon, Spin, Button, Form, Modal, TimePicker, notification} from 'antd';
import moment from "moment";

const FormItem = Form.Item;

const styles = {
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: 2
        },
        row: { 
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexFlow: "column wrap",
            height: "340px"
        },
        okButton : {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            color: '#ffffff'
        }
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px"
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
    }
}

const daysDict = [
    {
        id: "MONDAY",
        value: "Segunda"
    },
    {
        id: "TUESDAY",
        value: "Terça"
    },
    {
        id: "WEDNESDAY",
        value: "Quarta"
    },
    {
        id: "THURSDAY",
        value: "Quinta"
    },
    {
        id: "FRIDAY",
        value: "Sexta"
    },
    {
        id: "SATURDAY",
        value: "Sábado"
    },
    {
        id: "SUNDAY",
        value: "Domingo"
    }
]


class WorkHoursManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            workHoursData: [],
            editModalData: null,
            showEditModal: false
        }
    }

    componentDidMount() {
        this.props.getWorkHoursList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchWorkHours !== this.props.fetchWorkHours){
            if (!this.props.fetchWorkHours.errorStatus){
                this.setState({loading: false});
                this.formatWorkHoursData(this.props.fetchWorkHours);
            }
            this.setState({loading: false});
        }
        if (prevProps.editWorkHours !== this.props.editWorkHours){
            if (!this.props.editWorkHours.errorStatus){
                this.props.getWorkHoursList();
                this.uploadNotification();
            }
        }
    }

    formatWorkHoursData = workHours => {
        this.setState({
            workHoursData: workHours.map( (workHour, index) => ({
                key: index,
                unityGroupId: workHour.unity_group_id,
                unityGroupName: workHour.unity_group_name,
                MONDAY: workHour.workday.MONDAY.toString(),
                TUESDAY: workHour.workday.TUESDAY.toString(),
                WEDNESDAY: workHour.workday.WEDNESDAY.toString(),
                THURSDAY: workHour.workday.THURSDAY.toString(),
                FRIDAY: workHour.workday.FRIDAY.toString(),
                SATURDAY: workHour.workday.SATURDAY.toString(),
                SUNDAY: workHour.workday.SUNDAY.toString()
            }))
        })
    }

    handleShowEditWorkHours = rowData => {
        this.setState({ 
            showEditModal: true,
            editModalData: rowData
        });
    }

    handleCancelModal = () => {
        this.setState({showEditModal: false, editModalData: null});
    }

    handleOnChangeWorkdayHours = (value, id) => {
        let workHours = {...this.state.editModalData};
        workHours[id] = value;
        this.setState({editModalData: workHours});
    }

    handleEditWorkHoursModalOk = () => {
        const workHour = {
            unity_group_id: this.state.editModalData.unityGroupId,
            days: {
                MONDAY: this.state.editModalData.MONDAY,
                TUESDAY: this.state.editModalData.TUESDAY,
                WEDNESDAY: this.state.editModalData.WEDNESDAY,
                THURSDAY: this.state.editModalData.THURSDAY,
                FRIDAY: this.state.editModalData.FRIDAY,
                SATURDAY: this.state.editModalData.SATURDAY,
                SUNDAY: this.state.editModalData.SUNDAY
            }
        }
        this.setState({loading: true, showEditModal: false, editModalData: null});
        this.props.updateWorkHour(workHour);
    }


    dayData = day => ({
        "MONDAY": this.state.editModalData.MONDAY,
        "TUESDAY": this.state.editModalData.TUESDAY,
        "WEDNESDAY": this.state.editModalData.WEDNESDAY,
        "THURSDAY": this.state.editModalData.THURSDAY,
        "FRIDAY": this.state.editModalData.FRIDAY,
        "SATURDAY": this.state.editModalData.SATURDAY,
        "SUNDAY": this.state.editModalData.SUNDAY
    })[day];


    fieldProps = day => {
        let hour = this.dayData(day.id)
        if(!hour.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)){
            return {
                style: styles.form.label,
                label: day.value,
                labelAlign: "left",
                validateStatus: "error",
                help: "Quantidade de horas diárias inválida"
            };
        } else {
            return {style: styles.form.label, label: day.value};
        }
    }

    uploadNotification = () =>{
        notification.success({
          message: 'Horas diárias atualizadas com sucesso',
          },
        );
    };

    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56}} spin />} />
            </div>
        );
    }

    renderTable = () => {
        if (this.state.loading){
            return this.renderLoading();
        }

        const columns = [
            {
                title: "ID",
                dataIndex: "unityGroupId",
                align: "left",
                key: "unityGroupId"
            },
            {
                title: "Pólo",
                dataIndex: "unityGroupName",
                align: "left",
                key: "unityGroupName"
            },
            {
                title: "Seg",
                dataIndex: "MONDAY",
                align: "left",
                key: "MONDAY",
                width: "100px"
            },
            {
                title: "Ter",
                dataIndex: "TUESDAY",
                align: "left",
                key: "TUESDAY",
                width: "100px"
            },
            {
                title: "Qua",
                dataIndex: "WEDNESDAY",
                align: "left",
                key: "WEDNESDAY",
                width: "100px"
            },
            {
                title: "Qui",
                dataIndex: "THURSDAY",
                align: "left",
                key: "THURSDAY",
                width: "100px"
            },
            {
                title: "Sex",
                dataIndex: "FRIDAY",
                align: "left",
                key: "FRIDAY",
                width: "100px"
            },
            {
                title: "Sáb",
                dataIndex: "SATURDAY",
                align: "left",
                key: "SATURDAY",
                width: "100px"
            },
            {
                title: "Dom",
                dataIndex: "SUNDAY",
                align: "left",
                key: "SUNDAY",
                width: "100px"
            },
            {
                title: "Editar",
                width: "100px",
                dataIndex: "edit",
                fixed: 'right',
                align: "left",
                key: "edit",
                render: (text, record) => this.renderEdit(record)
            }
        ];

        return (
            <Table 
                columns={columns}
                dataSource={this.state.workHoursData}
                scroll={{ x: true, y: false }}
                style={{paddingBottom: 0}} 
                pagination={{
                    pageSize: 8
                }}
                />
        );
    }

    renderEditWorkHoursModalContent = () => {
        return (
            <div>
                <Form>
                    <div style={styles.form.row}>
                        {daysDict.map( day => 
                            (
                                <FormItem   key={day.id}
                                            style={styles.form.label}
                                            label={day.value}
                                            labelAlign="left"
                                >
                                    <TimePicker 
                                        defaultValue={moment(this.dayData(day.id), 'HH:mm')}
                                        format={'HH:mm'}
                                        onChange={(value, timeString) => this.handleOnChangeWorkdayHours(timeString, day.id)}
                                    />
                                </FormItem>
                            )
                            )}
                    </div>
                </Form>
            </div>
        )
    }

    renderEditWorkHoursModal = () => {
        return (
            <Modal
                title="Editar horas por dia "
                visible={this.state.showEditModal}
                onOk={() => this.handleEditWorkHoursModalOk()}
                bodyStyle={{height: "400px"}}
                style={{paddingTop: "12px"}}
                width={400}
                onCancel={this.handleCancelModal}
                okButtonProps={{style: styles.form.okButton}}
            >
                {this.state.editModalData && this.renderEditWorkHoursModalContent()}
            </Modal>
        )
    }
    
    renderEdit = (rowData) => {
        return(
            <div>
                <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.handleShowEditWorkHours(rowData)}>
                    <Icon type="edit" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Editar</p>
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <h2>Horas de trabalho</h2>
                {this.renderTable()}
                {this.renderEditWorkHoursModal()}
            </div>
        )
    }
}

function mapStateToProps({ fetchWorkHours, editWorkHours }) {
    return { fetchWorkHours, editWorkHours };
}

export default connect(mapStateToProps, { ...autoRouterActions })(WorkHoursManager); 