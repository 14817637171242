import React, { Component } from 'react';

const styles = {
    content: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "10px",
        paddingTop: "10px",
        border: "1px solid",
        borderColor: "green",
        color: "green",
        cursor: "pointer",
        borderRadius: "5px",
        fontSize: "11pt"
    }
};

class CustomButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            borderColor: null,
            text: null,
            tooltip: null,
            color: null,
            style: null
        };

    }

    componentDidMount() {
        this.setState({
            borderColor: this.props.borderColor,
            text: this.props.text,
            tooltip: this.props.tooltip
        });

        let style = styles.content;
        style.borderColor = this.props.color;
        style.color = this.props.color;

        this.setState({ style });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.color !== this.props.color) {
            this.setState({ color: this.props.color });
        }

    }

    componentOnClick = () => {

        if (this.props.onClick) {
            this.props.onClick();
        }

    }

    render() {
        return(
            <div style={this.state.style} onClick={this.componentOnClick}>
                {this.state.text}
            </div>
        );
    }
}


export default CustomButton;