import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import "antd/dist/antd.css";

import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";

const store = createStore(reducers, {}, compose(applyMiddleware(reduxThunk)));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

const clearCache = async (registration) => {
    const keys = await window.caches.keys()
    await Promise.all(keys.map(key => caches.delete(key))).then(() => {
      registration.unregister();
      window.location.reload(true);
    })
  }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.register({
    onUpdate: (registration, isFirstVerification) => {
        clearCache(registration);
    }
});
