import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";
import FileDownload from 'js-file-download';

export const CONVERT_TASK_EXCEL_TO_JSON = "convert_task_excel_to_json";
export const FETCH_VEHICLES = "fetch_vehicles";
export const DOWNLOAD_MODEL = "download_model";
export const EDIT_ACTIVITY = "edit_activity";
export const FETCH_WORKERS = "fetch_workers";
export const UPLOAD_FILE_EXCEL_VEHICLE = "upload_file_excel_vehicle";
export const UPLOAD_FILE_EXCEL_WORKER = "upload_file_excel_worker";
export const DOWNLOAD_EXCEL_MODEL = "download_excel_model";
export const DOWNLOAD_EXCEL_CONFIG = "download_excel_config";
const baseAPI = process.env.REACT_APP_API_URL;
export const FETCH_WORK_HOURS = "fetch_work_hours";
export const EDIT_WORK_HOUR = "edit_work_hour";
export const REMOVE_ALL_VEHICLES = "remove_all_vehicles";
export const REMOVE_ALL_WORKERS = "remove_all_workers";

export const uploadFileExcelToConvertJson = (params) => async dispatch => {
    let formData = new FormData()
    formData.append('file', params.file)

    await axios.post(baseAPI + "task/convert-excel-to-json",
        formData,
        { headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ...getDefaultHeader() } }
    ).then(
        (res) => {
            defaultSuccessCallback(dispatch, res, CONVERT_TASK_EXCEL_TO_JSON);
            FileDownload(JSON.stringify(res.data), "input.json");
        },
        (error) => defaultErrorCallback(dispatch, error, CONVERT_TASK_EXCEL_TO_JSON)
    );
}

export const updateActivity = data => async dispatch => {
    await axios.put(baseAPI + "activity",
    data,
    { headers: { ...getDefaultHeader() } }
        ).then(
            (res) => defaultSuccessCallback(dispatch, res, EDIT_ACTIVITY),
            (error) => defaultErrorCallback(dispatch, error, EDIT_ACTIVITY)
        );
    
}

export const postExcelVehicle = (params) => async dispatch => {
    let formData = new FormData();
    formData.append('file', params.file);
        await axios.post(baseAPI + "vehicles/create-from-excel",
        formData,
        { headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ...getDefaultHeader() } }
            ).then(
                (res) => defaultSuccessCallback(dispatch, res, UPLOAD_FILE_EXCEL_VEHICLE),
                (error) => defaultErrorCallback(dispatch, error, UPLOAD_FILE_EXCEL_VEHICLE)
            );
}

export const postExcelWorker = (params) => async dispatch => {
    let formData = new FormData();
    formData.append('file', params.file);
        await axios.post(baseAPI + "unity/users/create-from-excel",
        formData,
        { headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ...getDefaultHeader() } }
            ).then(
                (res) => defaultSuccessCallback(dispatch, res, UPLOAD_FILE_EXCEL_WORKER),
                (error) => defaultErrorCallback(dispatch, error, UPLOAD_FILE_EXCEL_WORKER)
            );
}

export const getVehicleList = () => async dispatch => {
    await axios.get(baseAPI + "vehicles/accumulated",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_VEHICLES),
            (error) => defaultErrorCallback(dispatch, error, FETCH_VEHICLES)
        );
    
}

export const getWorkerList = () => async dispatch => {
    await axios.get(baseAPI + "unity/users/accumulated",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => {
                defaultSuccessCallback(dispatch, res, FETCH_WORKERS);
            },
            (error) => defaultErrorCallback(dispatch, error, FETCH_WORKERS)
        );
    
}
export const downloadExcelTemplate = (params, setProgress) => async dispatch => {
    let filename;
    let url = baseAPI;

    if (params.type === "vehicle") {
        url = url.concat("vehicles/download-excel-model");
        filename = "Veículos - Modelo.xlsx"
    }   
    else if (params.type === "workers") {
        url = url.concat("unity/users/download-excel-model");
        filename = "Trabalhadores - Modelo.xlsx"
    }
    else {
        url = url.concat("auto-router/download-excel-model");
        filename = "Roterização - Modelo.xlsx"
    }

    await axios.get(url, { 
        onDownloadProgress: function(progressEvent) {
            setProgress(parseFloat(((progressEvent.loaded / progressEvent.total) * 100).toFixed(1))); 
        }, 
        headers: { 'Content-Type': 'blob', ...getDefaultHeader() }, 
        responseType: 'arraybuffer' }
    ).then(
        (res) => {
            defaultSuccessCallback(dispatch, res, DOWNLOAD_EXCEL_MODEL);
            FileDownload(res.data, filename);
        },
        (error) => {
            defaultErrorCallback(dispatch, error, DOWNLOAD_EXCEL_MODEL);
        }
    );
}

export const downloadExcelConfigData = (params, setProgress) => async dispatch => {
    let filename;
    let url = baseAPI;

    if (params.type === "vehicle") {
        url = url.concat("vehicles/download-excel-config");
        filename = "Veículos - Configurações.xlsx"
    }   
    else {
        url = url.concat("unity/users/download-excel-config");
        filename = "Trabalhadores - Configuraçõe.xlsx"
    }

    await axios.get(url, { 
        onDownloadProgress: function(progressEvent) {
            setProgress(parseFloat(((progressEvent.loaded / progressEvent.total) * 100).toFixed(1))); 
        }, 
        headers: { 'Content-Type': 'blob', ...getDefaultHeader() }, 
        responseType: 'arraybuffer' }
    ).then(
        (res) => {
            defaultSuccessCallback(dispatch, res, DOWNLOAD_EXCEL_CONFIG);
            FileDownload(res.data, filename);
        },
        (error) => {
            defaultErrorCallback(dispatch, error, DOWNLOAD_EXCEL_CONFIG);
        }
    );
}

export const getWorkHoursList = () => async dispatch =>{
    await axios.get(baseAPI + "unity-group/workday",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_WORK_HOURS),
            (error) => defaultErrorCallback(dispatch, error, FETCH_WORK_HOURS)
        );
}

export const updateWorkHour = (workHour) => async dispatch =>{
    await axios.put(baseAPI + "unity-group/"+workHour.unity_group_id+"/update-workday" ,
        workHour.days,
        { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
            ).then(
                (res) => defaultSuccessCallback(dispatch, res, EDIT_WORK_HOUR),
                (error) => defaultErrorCallback(dispatch, error, EDIT_WORK_HOUR)
            );
}

export const deleteAllVehicles = unityId => async dispatch => {
    await axios.delete(baseAPI + "vehicles/delete-all/" + unityId,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, REMOVE_ALL_VEHICLES),
            (error) => defaultErrorCallback(dispatch, error, REMOVE_ALL_VEHICLES)
        );
}

export const deleteAllWorkers = unityId => async dispatch => {
    await axios.delete(baseAPI + "unity/users/delete-all/" + unityId,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, REMOVE_ALL_WORKERS),
            (error) => defaultErrorCallback(dispatch, error, REMOVE_ALL_WORKERS)
        );
}
