import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";

export const FETCH_AUTO_ROUTER_MAP_REPORT_DATA = "fetch_auto_router_map_report_data";
export const FETCH_AUTO_ROUTER_CHART_REPORT_DATA = "fetch_auto_router_chart_report_data";
export const FETCH_AUTO_ROUTER_REPORT_DATA = "fetch_auto_router_report_data";

const baseAPI = process.env.REACT_APP_API_URL;

export const getAutoRouterMapReportData = (params) => async dispatch => {
    await axios.get(baseAPI + "auto-router/input/" + params.autoRouterInputId + "/unity-group/" + params.unityGroupId,
            { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_MAP_REPORT_DATA),
            (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_MAP_REPORT_DATA)
        );
}

export const getAutoRouterChartReportData = (params) => async dispatch => {
    await axios.get(baseAPI + "auto-router/input/" + params.autoRouterInputId + "/unity-group/" + params.unityGroupId + "/charts-data",
            { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_CHART_REPORT_DATA),
            (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_CHART_REPORT_DATA)
        );
}

export const getAutoRouterReportData = (autoRouterInputId) => async dispatch => {
    await axios.get(baseAPI + "auto-router/input/" + autoRouterInputId,
            { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_REPORT_DATA),
            (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_REPORT_DATA)
        );
}