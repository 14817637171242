import { SYNC_REQUEST_LAST } from "../actions/detailedReportActions";

export default function(state = null, action) {
    switch(action.type) {
        case SYNC_REQUEST_LAST:
            console.log(action);
            return action.payload || false;
        default:
            return state;
    }
}