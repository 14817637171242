import { UPDATE_TASK_REQUEST_EXECUTED_AT } from "../actions/autoRouterExecutorActions";

export default function(state = null, action) {
    switch(action.type) {
        case UPDATE_TASK_REQUEST_EXECUTED_AT:
            return action.payload || { datetime: new Date() };
        default:
            return state;
    }
}
