import React, { Component } from 'react';
import './App.css';
import Panel from './page-content/Panel';
import ErrorDialog from './error-dialog/ErrorDialog';
import Login from './login/Login';
import { 
    BrowserRouter,
    Route,
    Switch
} from "react-router-dom";

class App extends Component {

    isLogged = () => {
        return sessionStorage.getItem("token");
    };

    renderPanel = () => {

        if (!this.isLogged()) {
            if (window.location.pathname !== "/login") {
                window.location.pathname = "/login";
            }
        }

        return(
            <Route path="/">
                <Panel />
            </Route>
        );
    }

    renderLogin = () => {
        if (this.isLogged()) {
            if (window.location.pathname === "/login") {
                window.location.pathname = "/";
            }
        }

        return(
            <Login />
        );
    }

    render () {
        return (
            <div style={{ height: "100%" }}>
                <ErrorDialog />

                <BrowserRouter>
                    <Switch>
                        <Route path="/login">
                            {this.renderLogin()}
                        </Route>
                        <Route path="/">
                            {this.renderPanel()}
                        </Route>
                    </Switch>
                    
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
