import React from 'react'   
import PageContent from '../page-content/PageContent';
import  { Button, Modal, Tabs, Upload, notification, Icon, Spin, Checkbox, Select, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { images } from '../../assets';
import UploadTable from './UploadTable';
import RouterTable from './RouterTable';
import { connect } from "react-redux";
import * as autoRouterExecutorActions from "../../actions/autoRouterExecutorActions";
import * as unityGroupActions from "../../actions/unityGroupActions";
import moment from 'moment';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs
const { Option } = Select;

const styles = {
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
        okButton: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            color: '#ffffff',
        }
    },
    autoRouterModalLabel: {
        fontSize: "15px",
        marginBottom: "10px"
    },
    select: {
        width: "100%",
        zIndexOffset: '1001',
    },
    button: {
        backgroundColor: "#782777", 
        border:"0px",
        width: "auto",
        color: "#fff"
    },
    router: {
        button: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: 0,
            color:  "#ffffff",
            fontSize: "12pt",
            marginRight: "16pt"
        },
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        }
    },
    buttonDiv: {
        display: "flex"
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px"
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
    },
    tab: {
        margin:"-30px",
    },
    tabPane: {
      paddingLeft: "30px",
      paddingRight: "30px"  
    },
    loading: {
        zIndex: 1002, 
        backgroundColor:"rgba(0, 0, 0, 0.6)",
        width: window.innerWidth,
        height: window.innerHeight,
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed'
    },
    dateInput: {
        display: "flex",
        flex: 1
    }
};

const dateFormat = 'DD-MM-YYYY';
class AutoRouterExecutor extends PageContent {

    constructor(props) {
        super(props);

        this.state = {
            title: "Roteirização",
            showUploadTaskModal: false,
            dowloadingXls: false,
            loading: false,
            uploadTaskModalData: {
                buttonDisabled: true,
                fileList: []
            },
            showAutoRouterModal: false,
            automaticallyCreateTasksAfterAutoRouter: false,
            unityGroupList: [],
            initialLoadingUnityGroup: true,
            unityGroupIdListToAutoRouter: [],
            loadingMessage: null,
            activeTab: "UPLOAD",
            autoRouterStartDate: null,
            autoRouterEndDate: null,
        }
    }

    componentDidMount() {
        this.props.fetchUnityGroupList();
        this.setState({ initialLoadingUnityGroup: true});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.postXlsToAutoRouter !== this.props.postXlsToAutoRouter) {
            if (!this.props.postXlsToAutoRouter.errorStatus) {
                this.setState({ loading: false, loadingMessage: null });
                this.createUploadTasksSuccessNotification();
                this.props.getAutoRouterList();
            } else {
                this.setState({ loading: false, loadingMessage: null });
            }
        }

        if (prevProps.unityGroupList !== this.props.unityGroupList) {
            if (!this.props.unityGroupList.errorStatus) {
                this.setState({ unityGroupList: this.props.unityGroupList });
            }
            this.setState({ initialLoadingUnityGroup: false });
        }

        if (prevProps.autoRouterSendRequest !== this.props.autoRouterSendRequest) {
            if (!this.props.autoRouterSendRequest.errorStatus) {
                this.createAutoRouterSuccessNotification();
                this.props.getAutoRouterList()
            }
            this.setState({ loading: false, loadingMessage: null });
        }

        if (prevProps.updateTaskRequestExecutedAt !== this.props.updateTaskRequestExecutedAt) {
            if (!this.props.updateTaskRequestExecutedAt.errorStatus) {
                this.handleSendAutoRouterAfterUpdateTaskRequestExecutedAt();
            } else {
                this.setState({ loading: false, loadingMessage: null });
            }
        }

    }

    handleOnChangeAutomaticallyCreateTasksAfterAutoRouterHandle = () => {
        this.setState({ automaticallyCreateTasksAfterAutoRouter: !this.state.automaticallyCreateTasksAfterAutoRouter });
    }

    handleOnUnityGroupToAutoRouterChange = (values) => {
        this.setState({ unityGroupIdListToAutoRouter: values });
    }

    handleOnClickAutoRouterButton = () => {
        let today = moment(new Date())
        this.setState({ 
            showAutoRouterModal: true,
            automaticallyCreateTasksAfterAutoRouter: false,
            unityGroupIdListToAutoRouter: [],
            autoRouterStartDate: today
        });
    }

    handleAutoRouterModalCancel = () => {
        this.setState(
            { 
                showAutoRouterModal: false,
                autoRouterEndDate: null
            }
        );
    }

    handleUploadTaskModalCancel = () => {
        this.setState({showUploadTaskModal: false, uploadTaskModalData: {buttonDisabled: true, fileList: []}});
    }    

    handleShowTaskUploadModal = () => {
        this.setState({showUploadTaskModal: true});
    };

    handleAutoRouterModalOk = () => {
        this.handleSendAutoRouterAfterUpdateTaskRequestExecutedAt();
    }

    handleSendAutoRouterAfterUpdateTaskRequestExecutedAt = () => {
        let unityGruopsId = [];

        if (this.state.unityGroupIdListToAutoRouter.length) {
            unityGruopsId = this.state.unityGroupIdListToAutoRouter;
        } else {
            unityGruopsId = this.state.unityGroupList.map(unityGroup => unityGroup.id);
        }

        let params = {
            startDate: this.state.autoRouterStartDate,
            endDate: this.state.autoRouterEndDate,
            unityGruopsId,
            automaticallyCreateTasksAfterAutoRouter: this.state.automaticallyCreateTasksAfterAutoRouter
        };

        this.props.sendRequestAutoRouter(params);
        
        this.setState(
            {   showAutoRouterModal: false, 
                loading: true, 
                loadingMessage: "Enviando requisição ao roteirizador ..." ,
                autoRouterStartDate: null, 
                autoRouterEndDate: null
            }
        );
    }

    handleUploadTaskModalOk = () => {
        this.setState({ loading: true, showUploadTaskModal: false, uploadTaskModalData: {buttonDisabled: true, fileList: []}, loadingMessage: "Enviando tarefas ..." })
        const params = {
            file: this.state.uploadTaskModalData.fileList[0]
        }
        this.props.uploadXlsToAutoRouter(params);
    }

    handleDownloadXls = () => {
        
    }

    handleonChangeAutoRouterDate = (dates) => {
        this.setState(
            {
                autoRouterStartDate: dates[0],
                autoRouterEndDate: dates[1]
            }
        )
    }

    isAutoRouterStartDateAndEndDateFilled = () => {
        return this.state.autoRouterStartDate && this.state.autoRouterEndDate
    }

    getTitle = () => {
        return this.state.title;
    }

    createAutoRouterSuccessNotification = () => {
        notification.open({
            message: "Requisição enviada ao roteirizador",
            icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
            },
        );
    }

    createUploadTasksSuccessNotification = () => {
        notification.open({
            message: 'Arquivo enviado para validação',
            icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
            },
        );
    };

    renderLoadingModal = () => {
        if (!this.state.loading) {
            return null;
        }
        return (
            <div style={styles.loading}>
                <div style={{ alignContent: "center", alignItems: "center", textAlign: "center" }}>
                    <Spin indicator={<Icon type="loading" style={{fontSize: 56, color: '#781e76'}} spin />} />
                    <p style={{ marginTop: "10px", color: "white" }}>{this.state.loadingMessage}</p>
                </div>
            </div>
        )
    }
    

    renderTopButton = () => {
        return(
                <div style={styles.buttonDiv}>
                    <Button style={styles.router.button} size={'large'} type="primary" onClick={this.handleOnClickAutoRouterButton}>
                        Roteirizar
                    </Button>

                    <Button style={styles.upload.button} size={'large'} type="primary" onClick={this.handleShowTaskUploadModal}>
                        <img src={images.plus_icon} alt={"plus_icon"} />
                        <p style={styles.upload.text}>Upload de tarefas</p>
                    </Button>
                </div>
        );
    }

    renderUnityGroupSelectItem = () => {
        if (!this.state.unityGroupList) {
            return null;
        }

        return this.state.unityGroupList.map(unityGroup => {
            return (
                <Option key={unityGroup.id} value={unityGroup.id}>{unityGroup.name}</Option>
            );
        });
    }

    renderAutoRouterModalContent = () => {
        return(
            <div>
                <div style={styles.dateInput}>
                    <div>
                        <div style={styles.autoRouterModalLabel}>
                            <strong>Data de início*</strong>    
                        </div>
                        <div style={{ marginBottom: "40px" }}>
                            <RangePicker value={[this.state.autoRouterStartDate, this.state.autoRouterEndDate]} locale={locale} size={"large"} placeholder="Início" format={dateFormat} onChange={(dates) => this.handleonChangeAutoRouterDate(dates)} /> 
                        </div>
                    </div>                    
                </div>
                
                <div style={{ marginBottom: "40px" }}>
                    <div style={styles.autoRouterModalLabel}>
                        <strong>Selecione os pólos para roteirizar</strong>
                    </div>

                    <Select
                        size={"large"}
                        allowClear={true}
                        showArrow={true}
                        mode= {"multiple"}
                        style={styles.select}
                        value={this.state.unityGroupIdListToAutoRouter}
                        placeholder={"Todos selecionados"}
                        onChange={this.handleOnUnityGroupToAutoRouterChange}
                        loading={this.state.initialLoadingUnityGroup}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }>
                        
                        {this.renderUnityGroupSelectItem()}

                    </Select>
                </div>
                <Checkbox onChange={this.handleOnChangeAutomaticallyCreateTasksAfterAutoRouterHandle}  checked={this.state.automaticallyCreateTasksAfterAutoRouter} >
                    Criar tarefas automaticamente após a roteirização
                </Checkbox>
                
            </div>
        );
    }

    renderUploadButton = () => {
        const props = {
            onRemove: file => {
              this.setState(state => {
                const index = state.uploadTaskModalData.fileList.indexOf(file);
                const newFileList = state.uploadTaskModalData.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    uploadTaskModalData: {
                        ...state.uploadTaskModalData,  
                        fileList: newFileList,
                    }
                };
              });
            },
            beforeUpload: file => {
              this.setState(state => ({
                uploadTaskModalData: {
                    ...state.uploadTaskModalData,
                    fileList: [file],
                }
              }));
              return false;
            },
            fileList: this.state.uploadTaskModalData.fileList,
        };
        return (
            <div>
                <p style={styles.upload.label}>Escolha um arquivo xls válido</p>
                <Upload {...props}>
                    <Button  style={styles.upload.button} size={'large'} type="primary" >
                        <img src={images.upload_icon} alt={"upload_icon"}/>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                </Upload>
            </div>
        );
    }

    renderAutoRouterModal = () => {
        return (
            <Modal
                title="Roteirizar"
                visible={this.state.showAutoRouterModal}
                onOk={this.handleAutoRouterModalOk}
                onCancel={this.handleAutoRouterModalCancel}
                okButtonProps={{style: !this.isAutoRouterStartDateAndEndDateFilled() ? {...styles.form.okButton, opacity: 0.5} : styles.form.okButton, disabled: !this.isAutoRouterStartDateAndEndDateFilled()}}
            >
                {this.renderAutoRouterModalContent()}
            </Modal>
        );
    }

    renderTaskUploadModal = () => {
        return (
            <Modal
                title="Upload de task"
                visible={this.state.showUploadTaskModal}
                onOk={this.handleUploadTaskModalOk}
                onCancel={this.handleUploadTaskModalCancel}
                okButtonProps={{style: this.state.uploadTaskModalData.fileList.length === 0 ? {...styles.form.okButton, opacity: 0.5} : styles.form.okButton, disabled: this.state.uploadTaskModalData.fileList.length === 0}}
            >
                {this.renderUploadButton()}
            </Modal>
        );
    }


    renderPageContentBody = () => {
        return(
            <div>
                <Tabs style={styles.tab} >
                    <TabPane style={styles.tabPane} tab="Upload" key="UPLOAD">
                        <UploadTable uploading={this.state.loading}/>
                    </TabPane>
                    <TabPane style={styles.tabPane} tab="Roteirizações" key="ROUTERS">
                        <RouterTable uploading={this.state.loading}/>
                    </TabPane>
                </Tabs>
                {this.renderTaskUploadModal()}
                {this.renderAutoRouterModal()}
                {this.renderLoadingModal()}
            </div>
        );
    }

}

function mapStateToProps({ postXlsToAutoRouter, unityGroupList, autoRouterSendRequest, updateTaskRequestExecutedAt }) {
    return { postXlsToAutoRouter, unityGroupList, autoRouterSendRequest, updateTaskRequestExecutedAt };
}

export default connect(mapStateToProps, { ...autoRouterExecutorActions, ...unityGroupActions })(AutoRouterExecutor);
