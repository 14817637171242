import { combineReducers } from "redux";

import allowedFarmsReducer from "./allowedFarmsReducer";
import generateDetailedReportReducer from "./generateDetailedReportReducer"
import defaultErrorReducer from "./defaultErrorReducer";
import issuesListReducer from "./issuesListReducer";
import issueTypesListReducer from "./issueTypesListReducer";
import uploadFileExcelReducer from "./uploadFileExcelReducer";
import uploadFileShapeReducer from "./uploadFileShapeReducer";
import fetchAuthenticationReducer from "./fetchAuthenticationReducer";
import downloadExcelModelReducer from "./downloadExcelModelReducer";
import downloadRouterExcelModelsReducer from "./downloadRouterExcelModelsReducer";
import farmsUsersFileReducer from "./farmsUsersFileReducer";
import fetchActivityShapefileReducer from "./fetchActivityShapefileReducer";
import fetchAllActivitiesReducer from "./fetchAllActivitiesReducer";
import fetchAllVehicleTypesReducer from "./fetchAllVehicleTypesReducer";
import removeShapefileReducer from "./removeShapefileReducer";
import fetchVehiclesReducer from "./fetchVehiclesReducer";
import convertTaskExcelToJsonReducer from "./convertTaskExcelToJsonReducer";
import editActivityReducer from "./editActivityReducer";
import fetchWorkersReducer from "./fetchWorkersReducer";
import uploadExcelVehicleReducer from "./uploadExcelVehicleReducer";
import uploadExcelWorkerReducer from "./uploadExcelWorkerReducer";
import generateConsolidatedReportReducer from "./generateConsolidatedReportReducer";
import fetchAutoRouterListReducer from "./fetchAutoRouterListReducer";
import downloadUploadedTasksReducer from "./downloadUploadedTasksReducer";
import postXlsToAutoRouterReducer from "./postXlsToAutoRouterReducer";
import removeAutoRouterUploadedExcelReducer from "./removeAutoRouterUploadedExcelReducer";
import removeAutoRouterUploadedExcelForcedReducer from "./removeAutoRouterUploadedExcelForcedReducer";
import createTasksFromAutoRouterReducer from "./createTasksFromAutoRouterReducer";
import removeAutoRouterReducer from "./removeAutoRouterReducer";
import downloadExcelGenerateByAutoRouterReducer from "./downloadExcelGenerateByAutoRouterReducer";
import removeTaskCreatedFromAutoRouterReducer from "./removeTaskCreatedFromAutoRouterReducer";
import fetchAutoRouterCreatedListReducer from "./fetchAutoRouterCreatedListReducer";
import unityListReducer from "./unityListReducer";
import unityGroupListReducer from "./unityGroupListReducer";
import autoRouterSendRequestReducer from "./autoRouterSendRequestReducer";
import fetchWorkHoursReducer from "./fetchWorkHoursReducer"
import editWorkHoursReducer from "./editWorkHourReducer"
import autoRouterMapReportDataReducer from "./autoRouterMapReportDataReducer";
import autoRouterChartReportReducer from "./autoRouterChartReportDataReducer";
import autoRouterReportDataReducer from "./autoRouterReportDataReducer";
import fetchAutoRouterInputDetailsReducer from "./fetchAutoRouterInputDetailsReducer";
import fetchAutoRouterUploadDetailsReducer from "./fetchAutoRouterUploadDetailsReducer";
import downloadRouterUnityConfigExcelReducer from "./downloadRouterUnityConfigExcelReducer";
import updateTaskRequestExecutedAtReducer from "./updateTaskRequestExecutedAtReducer";
import removeAllVehiclesReducer from "./removeAllVehiclesReducer";
import removeAllWorkersReducer from "./removeAllWorkersReducer";
import reRouterAutoRouterReducer from "./reRouterAutoRouterReducer";
import resendUploadReducer from "./resendUploadReducer"
import generateReportUrlReducer from "./genereateReportUrlReducer";
import syncRequestResultReducer from "./syncRequestResultReducer";
import syncRequestLastReducer from "./syncRequestLastReducer";
import fetcReportDownloadUrlReducer from "./fetchReportDownloadUrlReducer";
import fetchReportListReducer from "./fetchReportListReducer";
import fetchAllReportListReducer from "./fetchAllReportListReducer";
import generateReportRequestUrlDownloadReducer from "./generateReportRequestUrlDownloadReducer";
import createReportRequestReducer from "./createReportRequestReducer";


export default combineReducers({
    allowedFarms: allowedFarmsReducer,
    generateDetailedReport: generateDetailedReportReducer,
    generateConsolidatedReport: generateConsolidatedReportReducer,
    defaultError: defaultErrorReducer,
    issuesList: issuesListReducer,
    issueTypesList: issueTypesListReducer,
    uploadFileExcel: uploadFileExcelReducer,
    uploadFileShape: uploadFileShapeReducer,
    downloadExcelModel: downloadExcelModelReducer,
    downloadRouterExcelModels : downloadRouterExcelModelsReducer,
    fetchAuthentication: fetchAuthenticationReducer,
    farmsUsersFile: farmsUsersFileReducer,
    fetchActivityShapefile: fetchActivityShapefileReducer,
    fetchAllActivities: fetchAllActivitiesReducer,
    fetchAllVehicleTypes: fetchAllVehicleTypesReducer,
    removeShapefile: removeShapefileReducer,
    fetchVehicles: fetchVehiclesReducer,
    convertTaskExcelToJson: convertTaskExcelToJsonReducer,
    editActivity: editActivityReducer,
    fetchWorkers: fetchWorkersReducer,
    uploadExcelVehicle: uploadExcelVehicleReducer,
    uploadExcelWorker: uploadExcelWorkerReducer,
    fetchAutoRouterList: fetchAutoRouterListReducer,
    downloadUploadedTask: downloadUploadedTasksReducer,
    postXlsToAutoRouter: postXlsToAutoRouterReducer,
    removeAutoRouterUploadedExcel: removeAutoRouterUploadedExcelReducer,
    removeAutoRouterUploadedForcedExcel: removeAutoRouterUploadedExcelForcedReducer,
    createTasksFromAutoRouter: createTasksFromAutoRouterReducer,
    removeAutoRouter: removeAutoRouterReducer,
    downloadExcelGenerateByAutoRouter: downloadExcelGenerateByAutoRouterReducer,
    removeTaskCreatedFromAutoRouter: removeTaskCreatedFromAutoRouterReducer,
    fetchAutoRouterCreatedList: fetchAutoRouterCreatedListReducer,
    unityList: unityListReducer,
    unityGroupList: unityGroupListReducer,
    autoRouterSendRequest: autoRouterSendRequestReducer,
    fetchWorkHours: fetchWorkHoursReducer,
    editWorkHours: editWorkHoursReducer,
    autoRouterMapReportData: autoRouterMapReportDataReducer,
    autoRouterReportData: autoRouterReportDataReducer,
    autoRouterChartReportData: autoRouterChartReportReducer,
    fetchAutoRouterInputDetails: fetchAutoRouterInputDetailsReducer,
    fetchAutoRouterUploadDetails: fetchAutoRouterUploadDetailsReducer,
    downloadRouterUnityConfigExcel: downloadRouterUnityConfigExcelReducer,
    updateTaskRequestExecutedAt: updateTaskRequestExecutedAtReducer,
    removeAllVehicles: removeAllVehiclesReducer,
    removeAllWorkers: removeAllWorkersReducer,
    fetchReRouterAutoRouter: reRouterAutoRouterReducer,
    resendUpload: resendUploadReducer,
    generatedReportUrl: generateReportUrlReducer,
    syncRequestResult: syncRequestResultReducer,
    syncRequestLast: syncRequestLastReducer,
    fetcReportDownloadUrl: fetcReportDownloadUrlReducer,
    fetchReportList: fetchReportListReducer,
    fetchAllReportList: fetchAllReportListReducer,
    generateReportRequestUrlDownload: generateReportRequestUrlDownloadReducer,
    createReportRequest: createReportRequestReducer
});