import React from 'react';
import PageContent from '../page-content/PageContent';
import AutoRouterReportMap from './AutoRouterReportMap';
import AutoRouterReportCharts from './AutoRouterReportCharts';
import AutoRouterReportFilter from './AutoRouterReportFilter';
import { connect } from "react-redux";
import * as autoRouterReportActions from "../../actions/autoRouterReportActions";
import * as unityGroupActions from "../../actions/unityGroupActions";
import { Select, Spin, Icon, Tabs, Button } from 'antd';
import { images } from '../../assets';

const { TabPane } = Tabs
const { Option } = Select;

const styles = {
    datePicker: {
        display: "inline-block",
    },
    select: {
        width: "100%",
        zIndexOffset: '1001',
    },
    loading: {
        zIndex: 1002,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        width: window.innerWidth,
        height: window.innerHeight,
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed'
    },
    tab: {
        margin: "-30px",
    },
    tabPane: {
        paddingLeft: "30px",
        paddingRight: "30px"
    },
    filter: {
        marginTop: '21px',
        borderColor: '#781e76',
        color: '#781e76',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: "10px",
    },
    buttonDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
    },
    text: {
        paddingLeft: '10px',
        margin: 0
    }   
}

class AutoRouterReport extends PageContent {

    constructor(props) {
        super(props);

        this.state = {
            title: "Relatório",
            unityGroup: null,
            unityGroupList: [],
            selectedUnityGroupId: null,
            loading: false,
            loadingCount: 2,
            loadingMessage: null,
            autoRouterMapReportData: null,
            autoRouterChartReportData: null,
            selectedVehicleIds: [],
            autoRouterReportData: null,
            vehicles: [],
            activeTab: "MAP",
            filteredAutoRouterMapReportData: null
        };
    }

    componentDidMount() {
        this.props.getAutoRouterReportData(this.getAutoRouterInputId());
        this.setState({ loading: true, loadingMessage: "Buscando dados da roteirização" });
    }

    componentDidUpdate(prevProps) {
        if (this.props.unityGroupList !== prevProps.unityGroupList) {
            if (!this.props.unityGroupList.errorStatus) {
                this.setState({ unityGroupList: this.props.unityGroupList });
            }

            this.setState({ loading: false, loadingMessage: null });
        }

        if (prevProps.autoRouterMapReportData !== this.props.autoRouterMapReportData) {
            if (!this.props.autoRouterMapReportData.errorStatus) {
                this.setState({ autoRouterMapReportData: this.props.autoRouterMapReportData });

                this.handleLoadingStop();
            }
        }

        if (prevProps.autoRouterChartReportData !== this.props.autoRouterChartReportData) {
            if (!this.props.autoRouterChartReportData.errorStatus) {
                this.setState({ autoRouterChartReportData: this.props.autoRouterChartReportData });

                this.handleLoadingStop();
            }
        }

        if (prevProps.autoRouterReportData !== this.props.autoRouterReportData) {
            if (!this.props.autoRouterReportData.errorStatus) {
                this.setState({ autoRouterReportData: this.props.autoRouterReportData });
            }

            this.setState({
                loading: false,
                loadingMessage: null,
                unityGroupList: this.props.autoRouterReportData.unity_groups
            });
        }
    }


    getTitle = () => {
        if (this.state.autoRouterMapReportData) {
            return this.state.title + " - " +
                this.state.unityGroupList.filter(ug => {
                    return ug.id === this.state.selectedUnityGroupId
                }).map(ug => { return ug.name })
        } else {
            return this.state.title + " - Selecione um pólo";
        }
    }

    getAutoRouterInputId = () => {
        let pathnameSplit = window.location.pathname.split("/");
        return pathnameSplit[pathnameSplit.length - 1];
    }

    handleLoadingStop = () => {
        let count = this.state.loadingCount - 1;
        this.setState({ loadingCount: count });

        if (count === 0) {
            this.setState({ loading: false, loadingMessage: null, loadingCount: 2 });
        }
    }

    handleOnChangeActiveTab = key => {
        this.setState({ activeTab: key })
    }

    handleChangeUnityGroup = (value) => {
        this.setState({ selectedUnityGroupId: value });
        if (value) {
            let params = {
                autoRouterInputId: this.getAutoRouterInputId(),
                unityGroupId: value
            };

            this.props.getAutoRouterMapReportData(params);
            this.props.getAutoRouterChartReportData(params);

            this.setState({ loading: true, loadingMessage: "Buscando dados do relatório" });
        } else {
            this.setState({
                autoRouterMapReportData: null,
                autoRouterChartReportData: null
            });
        }
    }

    renderUnityGroupOptions = () => {
        if (!this.state.unityGroupList) {
            return null;
        }

        return this.state.unityGroupList.map(unityGroup => {
            return (
                <Option key={unityGroup.id} value={unityGroup.id}>{unityGroup.name}</Option>
            );
        });
    }

    handleOnOpenFilter = () => {
        this.setState({ showFilter: true });
    }

    closeFilter = () => {
        this.setState({ showFilter: false });
    }

    filterAutoRouterMapReportData = filteredAutoRouterMapReportData => {
        this.setState({ filteredAutoRouterMapReportData });
        this.closeFilter();
    }

    renderFilterButton = () => {
        if( this.state.activeTab === "CHARTS" || !this.state.autoRouterMapReportData ) {
            return null
        }
        return (
            <div style={styles.buttonDiv}>
                <Button
                    size="large"
                    style={styles.filter}
                    onClick={() => this.handleOnOpenFilter()}
                >
                    <img src={images.filter_icon} alt={"filter_icon"}/>
                    <p style={styles.text}>Filtrar</p>
                </Button>
                <AutoRouterReportFilter 
                    autoRouterMapReportData={this.state.autoRouterMapReportData}
                    autoRouterReportData={this.state.autoRouterReportData}
                    filterAutoRouterMapReportData={this.filterAutoRouterMapReportData}
                    isVisible={this.state.showFilter}
                    closeFilter={this.closeFilter}
                />
            </div>
        );
    }

    renderTopButton = () => {
        return (
            <div style={{ display: "flex" }}>
                {this.renderFilterButton()}
                <div style={{ width: "150px" }}>
                    <h5>Selecione o Pólo:</h5>
                    <Select size={"large"}
                        allowClear={true}
                        showArrow={true}
                        style={styles.select}
                        placeholder={"Selecione o Pólo"}
                        value={this.state.selectedUnityGroupId}
                        onChange={this.handleChangeUnityGroup} >
                        {this.renderUnityGroupOptions()}
                    </Select>
                </div>
            </div>
        )
    }

    renderLoading = () => {
        if (!this.state.loading) {
            return null;
        }

        return (
            <div style={styles.loading}>
                <div style={{ alignContent: "center", alignItems: "center", textAlign: "center" }}>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 56, color: '#781e76' }} spin />} />
                    <p style={{ marginTop: "10px", color: "white" }}>{this.state.loadingMessage}</p>
                </div>
            </div>
        );
    }

    renderPageContentBody = () => {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Tabs style={styles.tab} onChange={this.handleOnChangeActiveTab}>
                    <TabPane style={styles.tabPane} tab="Mapa" key="MAP">
                        <AutoRouterReportMap
                            selectedVehicleIds={this.state.selectedVehicleIds}
                            autoRouterMapReportData={this.state.filteredAutoRouterMapReportData ? this.state.filteredAutoRouterMapReportData : this.state.autoRouterMapReportData}
                            vehiclesData={this.state.autoRouterReportData ? this.state.autoRouterReportData.vehicles_data : null}
                            activityConfig={this.state.autoRouterReportData ? this.state.autoRouterReportData.activity_config : null}
                            unityGroups={this.state.autoRouterReportData ? this.state.autoRouterReportData.unity_groups : null}
                        />
                    </TabPane>
                    <TabPane style={styles.tabPane} tab="Gráficos" key="CHARTS">
                        <AutoRouterReportCharts
                            autoRouterChartReportData={this.state.autoRouterChartReportData}
                        />
                    </TabPane>
                </Tabs>

                {this.renderLoading()}
            </div>
        );
    }

}

function mapStateToProps({ autoRouterChartReportData, autoRouterMapReportData, unityGroupList, autoRouterReportData }) {
    return { autoRouterChartReportData, autoRouterMapReportData, unityGroupList, autoRouterReportData };
}

export default connect(mapStateToProps, { ...autoRouterReportActions, ...unityGroupActions })(AutoRouterReport);