const adminMenu = [1, 2, 3, 4, 5]
const consultantMenu = [1, 2, 4]
const readerMenu = [1]

function getPermission() {
    var role = sessionStorage.getItem("permission");
    var admin_role = sessionStorage.getItem("admin_role");
    return {"role": role, "admin_role": admin_role};
}

export function isAdmin() {
    var permission = getPermission();
    return permission["admin_role"] === "true" || permission["role"] === "ADMIN";
}

function isConsultant() {
    var permission = getPermission();
    return permission["admin_role"] === "false" && permission["role"] === "CONSULTANT";
}

export function permissionMenu() {
    if (isAdmin())
        return adminMenu;
    else if (isConsultant()) 
        return consultantMenu;
    else
        return readerMenu;
}

export function isProtectorAdmin() {
    var permission = getPermission();
    return permission["role"] === "ADMIN" || permission["role"] === "SUPER_ADMIN";
}