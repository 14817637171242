import React, { Component } from 'react';
import { Modal, Steps, Spin, Icon} from 'antd';
import './StepsStyle.css';
import { connect } from "react-redux";
import * as autoRouterExecutorActions from "../../actions/autoRouterExecutorActions";

const { Step } = Steps;

const styles = {
    okButton: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        color: '#ffffff',
    }, 
    footerMessage: {
        paddingTop: "10px",
    }
}

class StatusModal extends Component {
    constructor(props){
        super(props)

        this.state = {
            title: "Status",
            id: null,
            userInfo: null,
            status: null,
            statusType: null,
            loading: true,
            activeStep: 2,
            statusDetails: null,
            steps: this.props.type === "router" ? [
                {
                    key: "CREATED",
                    value: "Criado",
                    status: "",
                    description: <p></p>
                },
                {
                    key: "PROCESSED",
                    value: "Processado",
                    status: "",
                    description: <p></p>
                },
                {
                    key: "TASKS_CREATING",
                    value: "Criando tarefas",
                    status: "",
                    description: <p></p>
                },
                {
                    key: "TASKS_CREATED",
                    value: "Tarefas criadas",
                    status: "",
                    description: <div>
                                    <h2>Sucesso</h2>
                                    <p>Todas tarefas foram criadas</p>
                                </div>
                }
            ] : [
                {
                    key: "VALIDATING",
                    value: "Validando",
                    status: "",
                    description: <div>
                                    <h2>Aguarde, por favor</h2>
                                    <p>O arquivo está sendo validado</p>
                                </div>,
                },
                {
                    key: "NOT_PROCESSED",
                    value: "Não roteirizado",
                    status: "",
                    description: <p></p>
                },
                {
                    key: "IN_PROGRESS",
                    value: "Processando",
                    status: "",
                    description: <p></p>
                },
                {
                    key: "PROCESSED",
                    value: "Roteirizado",
                    status: "",
                    description: <p></p>
                }
            ],
        }
    }
    
    componentDidMount () {
        const statusType = this.props.type, status = this.props.currentRow.status, id = this.props.currentRow.id;
        const userInfo = { 
            user:this.props.currentRow.user, 
            email: this.props.currentRow.email 
        };
        this.setTypeAndActiveStep(status, statusType);
        this.getStatusDetails(status, statusType, id);
        this.setState({status, statusType, id, userInfo});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchAutoRouterUploadDetails !== this.props.fetchAutoRouterUploadDetails){
            if(!this.props.fetchAutoRouterUploadDetails.errorStatus){
                this.updateStatusDescription(this.props.fetchAutoRouterUploadDetails);
            }
        }
        if (prevProps.fetchAutoRouterInputDetails !== this.props.fetchAutoRouterInputDetails){
            if(!this.props.fetchAutoRouterInputDetails.errorStatus){
                this.setState({statusDetails: this.props.fetchAutoRouterInputDetails});
                this.updateStatusDescription(this.props.fetchAutoRouterInputDetails);
            }
        }
    }

    handleOnCloseModal = () => {
        this.props.onClose()
    }

    updateStatusDescription = statusDetails => {
        let description;
        if (this.state.statusType === "router") {
            description = {
                "ERROR":
                <div>
                    <h2>Ocorreu um erro durante o processamento</h2>
                </div>,
                "CREATED": 
                    <div>
                        <h2>As tarefas estão sendo roteirizadas</h2>
                        <p>Roteirizadas: {statusDetails["PROCESSED"]}</p>  
                        <p>Roteirizando: {statusDetails["IN_PROGRESS"]}</p>
                    </div>,
                "PROCESSED": 
                    <div>
                        <h2>As tarefas foram roteirizadas</h2>
                        <p>Tarefas prontas para serem criadas: {statusDetails["TO_EXECUTE"]}</p>  
                        <p>Tarefas que não serão criadas: {statusDetails["TO_NOT_EXECUTE"]}</p>
                        <p>Tarefas com erro: {statusDetails["ERROR"]}</p>
                    </div>,
                "PARTIALLY_PROCESSED":
                    <div>
                        <h2>Não foi possível roterizar todas as tarefas</h2>
                        <p>Tarefas prontas para serem criadas: {statusDetails["TO_EXECUTE"]}</p>  
                        <p>Tarefas que não serão criadas: {statusDetails["TO_NOT_EXECUTE"]}</p>
                        <p>Tarefas com erro: {statusDetails["ERROR"]}</p>
                    </div>,
                "TASKS_CREATING": 
                    <div>
                        <h2>As tarefas estão sendo criadas</h2>
                        <p>Tarefas criadas: {statusDetails["TASK_CREATED"]}</p>
                        <p>Tarefas que serão criadas: {statusDetails["TO_EXECUTE"]}</p>  
                        <p>Tarefas que não serão criadas: {statusDetails["TO_NOT_EXECUTE"]}</p>
                    </div>,
                "TASKS_ANY_CREATED": 
                    <div>
                        <h2>Algumas tarefas foram criadas no protector</h2>
                        <p>Tarefas criadas: {statusDetails["TASK_CREATED"]}</p> 
                        <p>Tarefas com erro durante a criação: {statusDetails["TASK_CREATION_ERROR"]}</p> 
                    </div>,
                "TASKS_CREATED":
                    <div>
                        <h2>Sucesso, todas tarefas foram criadas</h2>
                        <p>Tarefas criadas: {statusDetails["TASK_CREATED"]}</p> 
                        <p>Tarefas que não foram criadas: {statusDetails["TO_NOT_EXECUTE"]}</p>
                        <p>Tarefas que foram executadas: {statusDetails["EXECUTED"]}</p>
                        <p>Tarefas que ainda não foram executadas: {statusDetails["NOT_EXECUTED"]}</p>
                        <p>Tarefas que apresentaram erro durante a roteirização:  {statusDetails["ERROR"]}</p>
                    </div>

            };
        } else {
            description = {
                "ERROR":
                <div>
                    <h2>Não foi possível validar o arquivo</h2>
                </div>,
                "VALIDATION_ERROR":
                <div>
                    <h2>Nenhuma tarefa foi validada</h2>
                </div>,
                "NOT_PROCESSED":
                    <div>
                        <h2>As tarefas estão prontas para serem roteirizadas</h2>
                        <p> Total de linhas do upload: {statusDetails["TOTAL_ROWS"]}</p>
                        <p> Total de linhas duplicadas: {statusDetails["DUPLICATED"]}</p>
                        <p> Tarefas validadas: {statusDetails["VALIDATED"]}</p>
                        <p> Tarefas sendo roteirizadas: {statusDetails["IN_PROGRESS"]}</p>
                        <p> Tarefas com erro de validação: {statusDetails["VALIDATION_ERROR"]}</p>
                        <p> Tarefas em atraso (não serão roteirizadas): {statusDetails["EXPIRED"]}</p>
                    </div>,
                "IN_PROGRESS":  
                    <div>
                        <h2> As tarefas estão sendo roteirizadas.</h2>
                        <p>Tarefas com erro de validação: {statusDetails["VALIDATION_ERROR"]}</p> 
                        <p>Tarefas roteirizadas: {statusDetails["PROCESSED"]}</p>
                        <p>Tarefas criadas no protector: {statusDetails["TASK_CREATED"]}</p>
                        <p>Tarefas que não foram criadas: {statusDetails["TASK_NOT_CREATED"]}</p> 
                        <p>Tarefas que não foram roteirizadas: {statusDetails["NOT_PROCESSED"]}</p>
                    </div>,
                "PROCESSED": 
                    <div>
                        <h2>Todas tarefas foram processadas.</h2>
                        <p>Tarefas criadas no protector: {statusDetails["TASK_CREATED"]}</p>
                        <p>Tarefas que ainda não foram criadas: {statusDetails["TASK_NOT_CREATED"]}</p>
                        <p>Tarefas com erro de validação: {statusDetails["VALIDATION_ERROR"]}</p>
                    </div>
            };
        }
        const steps = this.state.steps;
        steps[this.state.activeStep].description = description[steps[this.state.activeStep].key];
        this.setState({steps, loading: false});
    }

    getStatusDetails = (status, statusType, id) => {
        if (statusType === "router") {
            if (status === "ERROR" || status === "TASKS_ERROR" || status === "TASKS_DELETING"){
                this.setState({loading: false});
                return 0;
            }
            this.props.getAutoRouterInputDetails(id);
        } else {
            if (status === "VALIDATING" || status === "VALIDATING_ERROR"){
                this.setState({loading: false});
                return 0;
            }
            this.props.getAutoRouterUploadDetails(id);
        } 
    }

    setTypeAndActiveStep = ( status, statusType) => {
        let title, activeStep;
        if (statusType === "router") {
            title = "Status de Roteirização" ;
            this.checkUploadFinalStatus(status, statusType);
        } else {
            title = "Status de Upload" ;
            this.checkUploadFinalStatus(status, statusType);
            
        }
        let step = this.state.steps.filter(filter_step => filter_step.key === status)[0];
        activeStep = this.state.steps.indexOf(step);
        this.setState({title, activeStep});
    }

    checkUploadFinalStatus = (status) => {
        let changedStep = this.state.steps
        if (status === "ERROR"){
            changedStep[1] = {
                key: "ERROR",
                value: "Erro",
                status: "error"
            };
            
        }  else if (status === "VALIDATION_ERROR"){ 
            changedStep[1] = {
                key: "VALIDATION_ERROR",
                value: "Erro",
                status: "error"
            };
        } else if (status === "TASKS_ANY_CREATED"){
            changedStep[4] = {
                key: "TASKS_ANY_CREATED",
                value: "Algumas tarefas criadas",
                status: "wait"
            };
        } else if ( status === "TASKS_ERROR"){
            changedStep[2] = { 
                key: "TASKS_ERROR",
                value: "Erro ao criar tarefas",
                status: "error",
                description: <div>
                                <h2>Não foi possível criar nenhuma tarefa</h2>
                            </div>,
            } 
        } else if ( status === "PARTIALLY_PROCESSED"){
            changedStep[1] = {
                key: "PARTIALLY_PROCESSED",
                value: "Parcialmente Roteirizado",
                status: "error"
            }
        } else if (status === "TASKS_DELETING") {
            changedStep[4] = {
                key: "TASKS_DELETING",
                value: "Deletando tarefas",
                status: "",
                description: <p></p>
            }
            this.setState({statusDetails: null})
        }
        this.setState({steps: changedStep})
    }

    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56, color: '#781e76'}} spin />} />
            </div>
        );
    }


    renderUserInfo = () => {
        let userMessage = "", emailMessage = "";
        if(this.state.statusType === "router") { 
            userMessage = "As tarefas foram roteirizadas por " + this.state.userInfo.user;
        } else {
            userMessage = "O upload foi feito por " + this.state.userInfo.user;
        }
        if(this.state.status === "ERROR"  || this.state.status === "VALIDATION_ERROR" || this.state.status === "TASKS_ERROR" || ( this.state.statusDetails && (this.state.statusDetails["VALIDATION_ERROR"] > 0  || this.state.statusDetails["ERROR"] > 0)) ){
            emailMessage = "Um email com os detalhes do erro será enviado para: " + this.state.userInfo.email + (this.state.statusType === "upload" ? ". Você também pode baixar essa planilha para ver os erros clicando no botão Baixar." : "");
        }
        return(
            <div style={styles.footerMessage}>
                <p>{userMessage}</p>
                <p>{emailMessage}</p>
            </div>
            
        );
    }

    renderSteps = () => {
        if (this.state.loading) {
           return(this.renderLoading())
        }
        const steps = this.state.steps;
        return(
            <div>
                <Steps
                    current={this.state.activeStep}
                    className="site-navigation-steps"
                    labelPlacement="vertical"
                    >
                    {
                        steps.map( (step) => (
                            <Step title={step.value} status={step.status}></Step>
                        ))
                    }
                </Steps>
                <div className={"status-details"}>
                    {steps[this.state.activeStep].description}
                    {this.renderUserInfo()}
                </div>
            </div>
        );
    }

    render(){
        return(
            <Modal
                title={this.state.title}
                visible={!!this.props.currentRow}
                onCancel={this.handleOnCloseModal}
                width="700px"
                onOk={this.handleOnCloseModal}
                okButtonProps={{ style: styles.okButton }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                {this.renderSteps()}
            </Modal>
        );
    }
    
}

function mapStateToProps({ fetchAutoRouterUploadDetails, fetchAutoRouterInputDetails}) {
    return { fetchAutoRouterUploadDetails, fetchAutoRouterInputDetails};
}

export default connect(mapStateToProps, { ...autoRouterExecutorActions })(StatusModal);