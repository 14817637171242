import React, { Component } from 'react';
import { Modal, Button } from 'antd';

import { connect } from "react-redux";
import * as actions from "../../actions";

const errorTitle = "Erro";
const defaultErrorDescription = "Houve um erro durante a requisição. Por favor tente novamente.";

class ErrorDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            title: "Erro",
            code: null,
            name: null,
            description: null,
            description_details: null,
            status: null
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.defaultError !== this.props.defaultError) {

            let code = this.props.defaultError.code;
            let title = this.props.defaultError.title ? this.props.defaultError.title : errorTitle;
            let name = this.props.defaultError.name;
            let description = this.props.defaultError.description ? this.props.defaultError.description : defaultErrorDescription;
            let description_details = this.props.defaultError.description_details;
            let status = this.props.defaultError.status;

            this.setState({ code, title, name, description, description_details, visible: true, status });

            // Modal.error({
            //     title: title,
            //     content: description
            // });
        }

    }
    
    handleOk = () => {
        this.setState({visible: false})
    }

    handleCancel = () => {
        this.setState({visible: false})
    }

    renderDescription = () => {
        return this.state.description ? 
        (
            <div>
                {this.state.description}
            </div>
        )  : (
            <div></div>
        )
    };

    renderDescriptionDetails = () => {
         return this.state.description_details.map (
                d => {
                    return <div> {d} </div>
                }
            ) 
    }
    render() {
        return(
            <Modal 
                visible={this.state.visible}
                title = {this.state.title}
                onCancel={this.handleCancel}
                zIndex={1001}
                footer={[
                <Button data-cy= 'general-error-modal-ok-button' type="primary" onClick={this.handleOk}>
                  OK
                </Button>,
              ]}>
                {this.renderDescription()}
                {this.state.description_details && this.renderDescriptionDetails()}
            </Modal>
        );
    }
}

function mapStateToProps({ defaultError }) {
    return { defaultError };
}

export default connect(mapStateToProps, actions)(ErrorDialog);