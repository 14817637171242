import React, {Component} from 'react';

import {Modal, Checkbox, Slider, InputNumber, notification} from 'antd'

import moment from 'moment'

const CheckboxGroup = Checkbox.Group;


const styles = {
    modalDiv: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap'
    },
    filter: {
        div: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: '10px',
            minWidth: "200px"
        },
        checkAll: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            border: '1px solid #E8EAED',
            boxSizing: 'border-box',
            borderRadius: '4px'
        },
        checkBoxGroup: {
            display:'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            flexDirection: 'column',
            overflow: 'auto',
            maxHeight: '150px'
        }
    }
}

class AutoRouterReportFilter extends Component {
    constructor(props){
        super(props)

        this.state = {
            visible: false,
            autoRouterMapReportData: null,
            activitiesInfo: null,
            vehicleData: {
                checkedList: [],
                indeterminate: false,
                checkAll: true,
                filterValue: [],
                vehicleOptions: []
            },
            endDateData: {
                checkedList: [],
                indeterminate: false,
                checkAll: true,
                filterValue: [],
                endDateOptions: []
            },
            activityData: {
                checkedList: [],
                indeterminate: false,
                checkAll: true,
                filterValue: [],
                activityOptions: []
            },
            priorityData: {
                checkedList: [],
                indeterminate: false,
                checkAll: true,
                filterValue: [],
                priorityOptions: []
            },
            areaData: {
                areaRange: [],
                areaValue: null
            },
            farmData: null,
            zoneData: null
        }
    }

    componentDidMount() {
        let priority = this.generatePriorityOptions(this.props.autoRouterReportData);
        this.setState({
            visible: this.props.isVisible,
            priorityData: {
                ...this.state.priorityData,
                checkedList: Object.values(priority),
                priorityOptions: priority
            }
        });
        
    }

    componentDidUpdate(prevProps) {
        if(this.props.autoRouterMapReportData !== this.state.autoRouterMapReportData) {
            let autoRouterMapReportData = this.props.autoRouterMapReportData
            let areaValues = this.generateAreaRange(autoRouterMapReportData);
            let vehicles = this.generateVehicleOptions(autoRouterMapReportData);
            let endDate = this.generateEndDateOptions(autoRouterMapReportData);
            let activities = this.generateActivityOptions(autoRouterMapReportData);

            this.setState({
                autoRouterMapReportData: autoRouterMapReportData,
                vehicleData: {
                    ...this.state.vehicleData,
                    checkedList: Object.values(vehicles),
                    vehicleOptions: vehicles
                },
                endDateData: {
                    ...this.state.endDateData,
                    checkedList: Object.keys(endDate),
                    endDateOptions: endDate
                },
                activityData: {
                    ...this.state.activityData,
                    checkedList: Object.keys(activities),
                    activityOptions: activities
                },
                areaData: {
                    ...this.state.areaData,
                    areaRange: areaValues,
                    areaValue: areaValues
                }
            });
        }
        if(this.props.autoRouterReportData !== prevProps.autoRouterReportData) {
            let priority = this.generatePriorityOptions(this.props.autoRouterReportData)
            this.setState({
                priorityData: {
                    ...this.state.priorityData,
                    checkedList: Object.values(priority),
                    priorityOptions: priority
                }
            })
        }
        if(this.props.isVisible !== this.state.visible) {
            this.setState({visible: this.props.isVisible})
        }
    }

    generateVehicleOptions = autoRouterMapReportData => {
        let vehicles = {};
        autoRouterMapReportData.vehicles.filter(vehicle => {
            let validate = false
            Object.entries(autoRouterMapReportData.task_requests).forEach(entry => {
                const activity_list = entry[1];
                for (const activity of activity_list) {
                    if (activity.vehicle_identifier === vehicle.identifier) {
                        validate = true;
                        break;
                    }
                }
                if (validate === true)
                    return;
            })
            return validate;
        }).forEach(vehicle => {
            vehicles[vehicle.id] = vehicle.identifier
        });
        return vehicles;
    }

    generateEndDateOptions = autoRouterMapReportData => {
        let dates = {};
        Object.values(autoRouterMapReportData.task_requests).map( tasks => 
            tasks.forEach( task => {
                let endDate = task.end_time_window;
                let unix = moment(endDate).unix();
                if (!Object.keys(dates).includes(unix))
                    dates[unix] = moment(endDate).format("DD-MM-YYYY");
            }))
        return dates;
    }

    generateActivityOptions = autoRouterMapReportData => {
        let activities = {};
        Object.values(autoRouterMapReportData.task_requests).map( tasks => 
            tasks.forEach( task => {
                activities[task.activity_id] = task.activity_name;
            }))
        return activities;

    }

    generatePriorityOptions = autoRouterReportData => {
        let activitiesInfo = {};
            autoRouterReportData.activity_config.forEach(activity => {
                activitiesInfo[activity.priority.toString()] = activitiesInfo[activity.priority.toString()] ? activitiesInfo[activity.priority.toString()].push(activitiesInfo[activity.id]) : [activity.id]
            });
        return activitiesInfo;
    }

    generateAreaRange = autoRouterMapReportData => {
        let smaller = 1000, bigger = 0;
        autoRouterMapReportData.protector_area_list.forEach( data => {
            let area = data.properties.area;
            if ( area < smaller ) smaller = area;
            if ( area > bigger) bigger = area;
        })
        return [Number(smaller.toFixed(2)), Number(bigger.toFixed(2))]
    } 


    handleOnClose = () => {
        this.props.closeFilter()
    }

    handleOnFilter = () => {
        const areaValue = this.state.areaData.areaValue;
        let areas = [];
        this.state.autoRouterMapReportData.protector_area_list.forEach( area => (areaValue[0] < area.properties.area && area.properties.area < areaValue[1]) && areas.push(area.id));
    
        let filteredValues = {
            vehicles: this.state.vehicleData.checkedList.length ? (this.state.vehicleData.checkedList.length === this.state.vehicleData.vehicleOptions.length ? [] : this.state.vehicleData.checkedList ) : null,
            endDate: this.state.endDateData.checkedList.length ? (this.state.endDateData.checkedList.length === this.state.endDateData.endDateOptions.length ? [] : this.state.endDateData.checkedList ) : null,
            activity: this.state.activityData.checkedList.length ? (this.state.activityData.checkedList.length === this.state.activityData.activityOptions.length ? [] : this.state.activityData.checkedList ) : null,
            priority: this.state.priorityData.checkedList.length ? (this.state.priorityData.checkedList.length === this.state.priorityData.priorityOptions.length ? [] : this.state.priorityData.checkedList ) : null,
            farm: !this.state.farmData ? '' : this.state.farmData,
            zone: !this.state.zoneData ? '' : this.state.zoneData,
            area: areas
        }

        if (Object.values(filteredValues).includes(null)){
            this.openNotificationError('checkbox');
        } else {
            let task_requests = {}
            Object.entries(this.state.autoRouterMapReportData.task_requests).forEach(date => 
                task_requests[date[0]] = date[1].filter( data => (
                (!filteredValues.vehicles.length || filteredValues.vehicles.includes(data.vehicle_identifier.toString())) && 
                (!filteredValues.endDate.length || filteredValues.endDate.includes(moment(data.end_time_window).unix().toString())) &&
                (!filteredValues.activity.length || filteredValues.activity.includes(data.activity_id.toString())) &&
                (!filteredValues.priority.length || filteredValues.priority.flat().includes(data.activity_id)) &&
                (!filteredValues.area.length || filteredValues.area.includes(data.protector_area_id.toString())) &&
                (Number.isInteger(filteredValues.farm) ? filteredValues.farm === data.raizen_farm_id : filteredValues.farm === '') &&
                (Number.isInteger(filteredValues.zone) ? filteredValues.zone === data.raizen_zone_id :filteredValues.zone === '')
            )));

            let task_requests_not_executed = this.state.autoRouterMapReportData.task_requests_not_executed.filter(data =>
                (!filteredValues.endDate.length || filteredValues.endDate.includes(moment(data.end_time_window).unix().toString())) &&
                (!filteredValues.activity.length || filteredValues.activity.includes(data.activity_id.toString())) &&
                (!filteredValues.priority.length || filteredValues.priority.flat().includes(data.activity_id)) &&
                (!filteredValues.area.length || filteredValues.area.includes(data.protector_area_id.toString())) &&
                (Number.isInteger(filteredValues.farm) ? filteredValues.farm === data.raizen_farm_id : filteredValues.farm === '') &&
                (Number.isInteger(filteredValues.zone) ? filteredValues.zone === data.raizen_zone_id :filteredValues.zone === '')
            );
            if (!Object.values(task_requests).flat().length && !task_requests_not_executed.length) {
                this.openNotificationError('no_data');
            } else {
                let vehicles = this.state.autoRouterMapReportData.vehicles.filter( vehicle => !filteredValues.vehicles.length || filteredValues.vehicles.includes(vehicle.identifier.toString()));
                const filteredData = {
                    ...this.state.autoRouterMapReportData,
                    vehicles,
                    task_requests,
                    task_requests_not_executed
                }
                this.props.filterAutoRouterMapReportData(filteredData);
            }
        }
    }

    handleOnVehicleChange = checkedList => {
        this.setState({
            vehicleData: {
                ...this.state.vehicleData,
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < Object.keys(this.state.vehicleData.vehicleOptions).length,
                checkAll: checkedList.length === Object.keys(this.state.vehicleData.vehicleOptions).length,
            }
        });
    };
    
    handleOnVehicleCheckAllChange = e => {
        this.setState({
            vehicleData: {
                ...this.state.vehicleData,
                checkedList: e.target.checked ? Object.keys(this.state.vehicleData.vehicleOptions) : [],
                indeterminate: false,
                checkAll: e.target.checked,
            }
        });
    };


    handleOnEndDateChange = checkedList => {
        this.setState({
            endDateData: {
                ...this.state.endDateData,
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < Object.keys(this.state.endDateData.endDateOptions).length,
                checkAll: checkedList.length === Object.keys(this.state.endDateData.endDateOptions).length,
            }
        });
    };
    
    handleOnEndDateCheckAllChange = e => {
        this.setState({
            endDateData: {
                ...this.state.endDateData,
                checkedList: e.target.checked ? Object.keys(this.state.endDateData.endDateOptions) : [],
                indeterminate: false,
                checkAll: e.target.checked,
            }
        });
    };

    handleOnPriorityChange = checkedList => {
        this.setState({
            priorityData: {
                ...this.state.priorityData,
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < Object.values(this.state.priorityData.priorityOptions).length,
                checkAll: checkedList.length === Object.values(this.state.priorityData.priorityOptions).length,
            }
        });
    };
    
    handleOnPriorityCheckAllChange = e => {
        this.setState({
            priorityData: {
                ...this.state.priorityData,
                checkedList: e.target.checked ? Object.values(this.state.priorityData.priorityOptions) : [],
                indeterminate: false,
                checkAll: e.target.checked,
            }
        });
    };

    handleOnActivityChange = checkedList => {
        this.setState({
            activityData: {
                ...this.state.activityData,
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < Object.values(this.state.activityData.activityOptions).length,
                checkAll: checkedList.length === Object.values(this.state.activityData.activityOptions).length,
            }
        });
    };
    
    handleOnActivityCheckAllChange = e => {
        this.setState({
            activityData: {
                ...this.state.activityData,
                checkedList: e.target.checked ? Object.keys(this.state.activityData.activityOptions) : [],
                indeterminate: false,
                checkAll: e.target.checked,
            }
        });
    };

    handleOnChangeZone = value => {
        this.setState({zoneData: value});
    }

    handleOnChangeFarm = value => {
        this.setState({farmData: value});
    }

    handleOnSliderAfterChange = areaValue => {
        this.setState({
            areaData: {
                ...this.state.areaData,
                areaValue
            }
        })
    }

    openNotificationError = descriptionType => {
        notification.error({
          message: "Erro ao tentar filtrar",
          description:
            descriptionType === 'checkbox' ? "Não é possível filtrar com nenhuma opção de um grupo selecionada" : 
            "Não foi encontrado nenhum resultado. Verifique se as informações de zona estão corretas"
        });
      };


    renderVehicleOptions = () => {
        return(
            <div style={styles.filter.div}>
                Veículos
                <div style={styles.filter.checkAll}>
                    <div style={{borderBottom: '1px solid #e9e9e9'}}>
                        <Checkbox
                            indeterminate={this.state.vehicleData.indeterminate}
                            onChange={this.handleOnVehicleCheckAllChange}
                            checked={this.state.vehicleData.checkAll}
                        >
                            Selecionar todos
                        </Checkbox>
                    </div>
                    <div>
                        <CheckboxGroup
                            style={styles.filter.checkBoxGroup}
                            value={this.state.vehicleData.checkedList}
                            onChange={this.handleOnVehicleChange}
                        >
                            {Object.entries(this.state.vehicleData.vehicleOptions).map(option =>
                                <div>
                                    <Checkbox value={option[0]}>{option[1]}</Checkbox>
                                </div>
                            )}
                        </CheckboxGroup>
                    </div>
                </div>
            </div> 
        );
    }

    renderEndDateOptions = () => {
        return(
            <div style={styles.filter.div}>
                Data final
                <div style={styles.filter.checkAll}>
                    <div style={{borderBottom: '1px solid #e9e9e9'}}>
                        <Checkbox
                            indeterminate={this.state.endDateData.indeterminate}
                            onChange={this.handleOnEndDateCheckAllChange}
                            checked={this.state.endDateData.checkAll}
                        >
                            Selecionar todos
                        </Checkbox>
                    </div>
                    <div>
                        <CheckboxGroup
                            style={styles.filter.checkBoxGroup}
                            value={this.state.endDateData.checkedList}
                            onChange={this.handleOnEndDateChange}
                        >
                            {Object.entries(this.state.endDateData.endDateOptions).map(option =>
                                <div>
                                    <Checkbox value={option[0]}>{option[1]}</Checkbox>
                                </div>
                            )}
                        </CheckboxGroup>
                    </div>
                </div>
            </div> 
        );
    }

    renderPriorityOptions = () => {
        return(
            <div style={styles.filter.div}>
                Prioridade
                <div style={styles.filter.checkAll}>
                    <div style={{borderBottom: '1px solid #e9e9e9'}}>
                        <Checkbox
                            indeterminate={this.state.priorityData.indeterminate}
                            onChange={this.handleOnPriorityCheckAllChange}
                            checked={this.state.priorityData.checkAll}
                        >
                            Selecionar todos
                        </Checkbox>
                    </div>
                    <div>
                        <CheckboxGroup
                            style={styles.filter.checkBoxGroup}
                            value={this.state.priorityData.checkedList}
                            onChange={this.handleOnPriorityChange}
                        >
                            {Object.entries(this.state.priorityData.priorityOptions).sort((a, b) => a[0]-b[0]).map(option =>
                                <div>
                                    <Checkbox value={option[1]}>{option[0]}</Checkbox>
                                </div>
                            )}
                        </CheckboxGroup>
                    </div>
                </div>
            </div> 
        )
    }

    renderActivityOptions = () => {
        return(
            <div style={styles.filter.div}>
                Atividade
                <div style={styles.filter.checkAll}>
                    <div style={{borderBottom: '1px solid #e9e9e9'}}>
                        <Checkbox
                            indeterminate={this.state.activityData.indeterminate}
                            onChange={this.handleOnActivityCheckAllChange}
                            checked={this.state.activityData.checkAll}
                        >
                            Selecionar todos
                        </Checkbox>
                    </div>
                    <div>
                        <CheckboxGroup
                            style={{...styles.filter.checkBoxGroup, flexDirection: 'row'}}
                            value={this.state.activityData.checkedList}
                            onChange={this.handleOnActivityChange}
                        >
                            {Object.entries(this.state.activityData.activityOptions).map(option =>
                                <div>
                                    <Checkbox value={option[0]}>{option[1]}</Checkbox>
                                </div>
                            )}
                        </CheckboxGroup>
                            
                    </div>
                </div>
            </div> 
        );
    }

    renderModalContent = () => {
        let marks = {
            [this.state.areaData.areaRange[0]]: this.state.areaData.areaRange[0],
            [this.state.areaData.areaRange[1]]: this.state.areaData.areaRange[1]
        }
        return(
            <>
                <div style={styles.modalDiv}>
                    {this.renderVehicleOptions()}
                    {this.renderEndDateOptions()}
                    {this.renderActivityOptions()}
                    {this.renderPriorityOptions()}
                    <div style={{...styles.filter.div, flexDirection: 'row', justifyContent: 'space-around'}}>
                        <div>
                            Fazenda
                            <InputNumber defaultValue={this.state.farmData}
                                onChange={this.handleOnChangeFarm}
                                min={0}
                            />
                        </div>
                        <div>
                            Zona
                            <InputNumber defaultValue={this.state.zoneData}
                                    onChange={this.handleOnChangeZone}
                                    min={0}
                            />
                        </div>
                    </div>
                    <div style={styles.filter.div}>
                        Area:
                        <Slider range onAfterChange={this.handleOnSliderAfterChange}marks={marks} defaultValue={this.state.areaData.areaRange} min={this.state.areaData.areaRange[0]} max={this.state.areaData.areaRange[1]} />
                    </div>
                </div>
                <div>
                </div>
            </>
        );
    }

    render() {
        return(
        <Modal
            visible={this.state.visible}
            onCancel={this.handleOnClose}
            onOk={this.handleOnFilter}
        >
            {this.renderModalContent()}
        </Modal>
        );
    }
}

export default AutoRouterReportFilter;