import React, { Component } from 'react';
import { Table, Button, Icon, Spin, Modal, Dropdown, Menu, Select, Popover } from 'antd';
import StatusModal from './StatusModal';
import { connect } from "react-redux";
import moment from 'moment';
import * as autoRouterExecutorActions from "../../actions/autoRouterExecutorActions";
import * as unityGroupActions from "../../actions/unityGroupActions";
import { images } from '../../assets';

const { Option } = Select;

const styles = {
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
        okButton: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            color: '#ffffff',
        }
    },
    tabHeaderDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: "10px",
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            width: "100%"
            // maxWidth: "135px"
        },
        buttonCreateTask: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            minWidth: "135px"
        },
        status: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            maxWidth: "174px",
            cursor: 'pointer'
        },
        disableButtomCreateTask: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#CBCBCB",
            color: "black",
            minWidth: "135px",
            borderColor: '#781e76',
            borderRadius: "4px"
        },
        disableButtom: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#CBCBCB",
            color: "black",
            maxWidth: "120px",
            borderColor: '#781e76',
            borderRadius: "4px"
        },
        text: {
            margin: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            align: "center",
        },
        actionDropdown: {
            textDecoration: 'underline',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            justifyContent: 'space-around',
            margin: 0,
            color:  "#781e76",
            marginRight: "20px",
            cursor: 'pointer'
        },
    }
};

const tableStatusConfig = {
    "CREATED": {
        color: "orange",
        text: "Roteirizando",
        actions: ["renderDeleteAutoRouterBottom", "renderDownloadXlsAutoRouter", "renderRerouterButton"]
    },
    "PROCESSED": {
        color: "green",
        text: "Roteirizado",
        actions: ["renderDeleteAutoRouterBottom", "renderCreateTaskButtom", "renderDownloadXlsAutoRouter", "renderReportAutoRouterButton", "renderRerouterButton"]
    },
    "PARTIALLY_PROCESSED": {
        color: "green",
        text: "Parcialmente Roteirizado",
        actions: ["renderDeleteAutoRouterBottom", "renderCreateTaskButtom", "renderDownloadXlsAutoRouter", "renderReportAutoRouterButton"]
    },
    "ERROR": {
        color: "red",
        text: "Erro ao roteirizar",
        actions: ["renderDeleteAutoRouterBottom", "renderRerouterButton"]
    },
    "TASKS_CREATED": {
        color: "green",
        text: "Tarefas criadas",
        actions: ["renderDeleteTaskButtom", "renderDownloadXlsAutoRouter", "renderReportAutoRouterButton"]
    },
    "TASKS_CREATING": {
        color: "green",
        text: "Criando tarefas",
        actions: ["renderDownloadXlsAutoRouter", "renderReportAutoRouterButton"]
    },
    "TASKS_ERROR": {
        color: "red",
        text: "Erro ao criar tarefas",
        actions: ["renderDeleteAutoRouterBottom", "renderCreateTaskButtom", "renderDownloadXlsAutoRouter", "renderReportAutoRouterButton"]
    },
    "TASKS_ANY_CREATED": {
        color: "red",
        text: "Possui tarefas criadas",
        actions: ["renderRetryCreateTaskButton", "renderDeleteTaskButtom", "renderDownloadXlsAutoRouter", "renderReportAutoRouterButton"]
    },
    "TASKS_DELETING": {
        color: "orange",
        text: "Deletando tarefas",
        actions: ["renderDownloadXlsAutoRouter", "renderReportAutoRouterButton"]
    },
};

// deleteTaskLoading
class RouterTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            createTaskLoading: [],
            downloadXlsAutoRouterLoading: [],
            autoRouterRowLoading: [],
            autoRoutersData: [],
            reportModalOpen: false,
            currentRow: null,
            showErrorModal: false,
            titleErrorModal: null,
            descriptionErrorModal: null,
            innerWidth: 0,
            selectedUnityGroupId: null,
            unityGroupList: [],
            initialLoadingUnityGroup: true,
            filteredAutoRouters: null
        }
    }

    componentDidMount() {
        this.reloadAutoRouterCreatedTable();
        this.props.fetchUnityGroupList();
        this.setState({ innerWidth: window.innerWidth });
        window.addEventListener('resize', this.updateScreenDimensions);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.createTasksFromAutoRouter !== this.props.createTasksFromAutoRouter){
            if (!this.props.createTasksFromAutoRouter.errorStatus){
                this.props.getAutoRouterCreatedList()
            }

            let createTaskLoading = this.state.createTaskLoading;
            let createdIndex = this.state.createTaskLoading.indexOf(this.props.createTasksFromAutoRouter.id);
            if (createdIndex >= 0) {
                createTaskLoading.splice(createdIndex, 1);
            }
            this.setState({ loading: false, createTaskLoading });
         }

         if (prevProps.downloadExcelGenerateByAutoRouter !== this.props.downloadExcelGenerateByAutoRouter){
            let downloadXlsAutoRouterLoading = this.state.downloadXlsAutoRouterLoading;
            let downloadIndex = downloadXlsAutoRouterLoading.indexOf(this.props.downloadExcelGenerateByAutoRouter.id);
            if (downloadIndex >= 0) {
                downloadXlsAutoRouterLoading.splice(downloadIndex, 1);
            }

            this.setState({ loading: false, downloadXlsAutoRouterLoading });
         }

         if (prevProps.removeTaskCreatedFromAutoRouter !== this.props.removeTaskCreatedFromAutoRouter){
            if (!this.props.removeTaskCreatedFromAutoRouter.errorStatus){
                this.props.getAutoRouterCreatedList()
            }

            let autoRouterRowLoading = this.state.autoRouterRowLoading;
            let autoRouterRowLoadingIndex = autoRouterRowLoading.indexOf(this.props.removeTaskCreatedFromAutoRouter.id);
            if (autoRouterRowLoadingIndex >= 0) {
                autoRouterRowLoading.splice(autoRouterRowLoadingIndex, 1);
            }

            this.setState({ loading: false, autoRouterRowLoading });
         }

         if (prevProps.removeAutoRouter !== this.props.removeAutoRouter){
            if (!this.props.removeAutoRouter.errorStatus){
                this.props.getAutoRouterCreatedList();
            }
            this.setState({ loading: false });
         }

         if (prevProps.fetchAutoRouterCreatedList !== this.props.fetchAutoRouterCreatedList){
            if (!this.props.fetchAutoRouterCreatedList.errorStatus){
                this.formatAutoRoutersData(this.props.fetchAutoRouterCreatedList);
            }
            this.setState({ loading: false })
        }

        if (prevProps.unityGroupList !== this.props.unityGroupList) {
            if (!this.props.unityGroupList.errorStatus) {
                this.setState({ unityGroupList: this.props.unityGroupList });
            }
            this.setState({ initialLoadingUnityGroup: false });
        }

        if (prevProps.fetchReRouterAutoRouter !== this.props.fetchReRouterAutoRouter) {

            let autoRouterInputId = null;
            if (this.props.fetchReRouterAutoRouter.error) {
                autoRouterInputId = this.props.fetchReRouterAutoRouter.error.id;
            } else {
                autoRouterInputId = this.props.fetchReRouterAutoRouter.id;
            }

            let autoRouterRowLoading = this.state.autoRouterRowLoading;
            let autoRouterRowLoadingIndex = autoRouterRowLoading.indexOf(autoRouterInputId);
            if (autoRouterRowLoadingIndex >= 0) {
                autoRouterRowLoading.splice(autoRouterRowLoadingIndex, 1);
            }

            if (!this.props.fetchReRouterAutoRouter.errorStatus){
                this.props.getAutoRouterCreatedList();
                this.setState({ loading: true, autoRouterRowLoading });
            } else {
                this.setState({ loading: false, autoRouterRowLoading });
            }
        }
    }  

    updateScreenDimensions = () => {
        this.setState({ innerWidth: window.innerWidth });
    }

    handleOpenStatusModal = rowData => {
        this.setState({currentRow: rowData});
    }

    handleOnCloseStatusModal = () => {
        this.setState({currentRow: null})
    }

    handleDeleteAutoRouter = autoRouter => {
        Modal.confirm({
            title: 'Deseja excluir ?',
            content: 'Ao confirmar você excluirá essa roteirização',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.props.deleteAutoRouter(autoRouter)
          });
    }

    formatAutoRoutersData = autoRouters => {
        this.setState({
            autoRoutersData: autoRouters.map( autoRouter => ({
                id: autoRouter.id,
                user: autoRouter.user_name,
                email: autoRouter.email,
                createdAt: moment(autoRouter.created_at).format("DD/MM/YYYY HH:mm"),
                status: autoRouter.status,
                taskExcelRequestId: autoRouter.task_excel_request_id,
                creationAutoRouterType: autoRouter.is_automatic ? "Automático" : "Manual",
                unityGroups: autoRouter.unity_groups,
                startDate: autoRouter.start_router_date ? moment(autoRouter.start_router_date).format("DD/MM/YYYY") : '',
                endDate: autoRouter.end_router_date ? moment(autoRouter.end_router_date).format("DD/MM/YYYY") : ''}))
        });
    }

    createTasks = autoRouter => {
        this.setState({createTaskLoading: this.state.createTaskLoading.concat(autoRouter.id)})
        this.props.createTasksAutoRouter(autoRouter, false)
    }

    retryCreateTasks = autoRouter => {
        this.setState({createTaskLoading: this.state.createTaskLoading.concat(autoRouter.id)})
        this.props.createTasksAutoRouter(autoRouter, true)
    }

    downloadXlsAutoRouter = autoRouter => {
        if (autoRouter && autoRouter.taskExcelRequestId) {
            this.setState({downloadXlsAutoRouterLoading: this.state.downloadXlsAutoRouterLoading.concat(autoRouter.id)})
            this.props.downloadExcelGeneratedByAutoRouter(autoRouter);

        } else {
            this.setState({
                titleErrorModal: "XLS indisponível",
                descriptionErrorModal: "O modelo de roteirização não conseguiu definir nenhuma tarefa para ser executada com os parâmetros configurados",
                showErrorModal: true
            });
        }
    }

    deleteTasks = autoRouter => {
        this.setState({autoRouterRowLoading: this.state.autoRouterRowLoading.concat(autoRouter.id)});
        this.props.deleteTaskFromAutoRouter(autoRouter.id);
    }

    showReportModalByRowData = rowData => {
        window.location.pathname = "auto-router-report/" + rowData.id;
    }

    handleOnRerouterClick = autoRouter => {
        Modal.confirm({
            title: 'Deseja reenviar ?',
            content: 'Ao confirmar você excluirá as informações da roteirização ' + autoRouter.id + ' para enviá-la novamente ao roteirizador.',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.rerouter(autoRouter)
          });
    }

    rerouter = autoRouter => {
        this.setState({autoRouterRowLoading: this.state.autoRouterRowLoading.concat(autoRouter.id)});
        
        this.props.reRouterAutoRouter(autoRouter);
    }

    reloadAutoRouterCreatedTable = ()=> {
        this.setState({loading:true})
        this.props.getAutoRouterCreatedList()
    }

    handleErrorModalCancel = () => {
        this.setState({ showErrorModal: false });
    }

    handleReportModalClose = () => {
        this.setState({ reportModalOpen: false });
    }

    handleChangeUnityGroup = (value) => {
        this.setState({ selectedUnityGroupId: value });
        if (value) {
            const filteredAutoRouters = this.state.autoRoutersData.filter(ard => ard.unityGroups.map(ug => ug.id).includes(value))
            this.setState({filteredAutoRouters})
        } else {
            this.setState({filteredAutoRouters: null})
        }
    }
    
    renderTableActionsMenuItems = autoRouter => {
        let automaticRouterException = autoRouter.creationAutoRouterType === "Automático" && autoRouter.status === "PROCESSED";

        const actions = automaticRouterException ? 
                                                tableStatusConfig[autoRouter.status].actions.filter(x => x !== "renderCreateTaskButtom") 
                                                : 
                                                tableStatusConfig[autoRouter.status].actions;
        
        return actions.map(statusRender => {
            return (
                <Menu.Item>
                    <div style={styles.actions.actionDropdown}>
                        {this[statusRender](autoRouter)}
                    </div>
                </Menu.Item>
            );
        }); 
    }
    
    renderTableActionsMenuItemss = (autoRouter) => {
        return(
            <Menu>
                {this.renderTableActionsMenuItems(autoRouter)}
            </Menu>
        );
    }
    
    renderTableActionsItems = autoRouter => {

        if (!tableStatusConfig[autoRouter.status]) {
            return;
        } else {

            if (this.state.autoRouterRowLoading.length !== 0 && this.state.autoRouterRowLoading
                .filter(loading => (!!loading && !!autoRouter.id) && loading.toString() === autoRouter.id.toString()).length !== 0) {
                    return(
                        <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76'}} spin />} />
                    )
            }

            return(
                <Dropdown placement={"bottomCenter"} trigger={['click']} overlay={this.renderTableActionsMenuItemss(autoRouter)}>
                    <div style={styles.actions.actionDropdown}>
                        Ações
                    </div>
                </Dropdown>
            );
        }
    }

    renderTableActions = autoRouter => {

        return(
            <div style={styles.actions.div}>
                {this.renderTableActionsItems(autoRouter)}
            </div>
        );
    }

    renderErrorModal = () => {
        return(
            <Modal
                title={this.state.titleErrorModal}
                visible={this.state.showErrorModal}
                onCancel={this.handleErrorModalCancel}
                onOk={this.handleErrorModalCancel}
                okButtonProps={{ style: styles.form.okButton }}
            >
                <p>{this.state.descriptionErrorModal}</p>
            </Modal>
        );
    }

    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56, color: '#781e76'}} spin />} />
            </div>
        );
    }

    renderDeleteAutoRouterBottom = (rowData, disableButtom) => {
        if (disableButtom) {
            return (
                <Button disabled style={styles.actions.disableButtom} size={'small'} type="primary">
                    <Icon type="delete" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Excluir</p>
                </Button>
            );
        }
        return (
            <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.handleDeleteAutoRouter(rowData)}>
                <Icon type="delete" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Excluir</p>
            </Button>
        );
    }

    renderCreateTaskButtom = (rowData, disableButtom) => {
        if (this.state.createTaskLoading.length !== 0 && this.state.createTaskLoading
            .filter(loading => (!!loading && !!rowData.id) && loading.toString() === rowData.id.toString()).length !== 0) {
                return(
                    <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76', margin: 0, align: "center"}} spin />} />
                )
            }
        if (disableButtom) {
            return (
                <Button disabled style={styles.actions.disableButtomCreateTask} size={'small'} type="primary">
                    <Icon type="snippets" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Criar Tarefas</p>
                </Button>
            );
        }
        return (
            <Button
                style={styles.actions.buttonCreateTask}
                size={'small'}
                type="primary"
                onClick={() => this.createTasks(rowData)}>

                <Icon type="snippets" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Criar Tarefas</p>

            </Button>
        );
    }

    renderRetryCreateTaskButton = (rowData, disableButtom) => {
        if (this.state.createTaskLoading.length !== 0 && this.state.createTaskLoading
            .filter(loading => (!!loading && !!rowData.id) && loading.toString() === rowData.id.toString()).length !== 0) {
                return(
                    <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76', margin: 0, align: "center"}} spin />} />
                )
            }
        if (disableButtom) {
            return (
                <Button disabled style={styles.actions.disableButtom} size={'small'} type="primary">
                    <Icon type="snippets" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Tentar novamente</p>
                </Button>
            );
        }
        return (
            <Button
                style={styles.actions.button}
                size={'small'}
                type="primary"
                onClick={() => this.retryCreateTasks(rowData)}>

                <Icon type="snippets" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Tentar novamente</p>

            </Button>
        );
    }

    renderDownloadXlsAutoRouter = (rowData, disableButtom) => {
        if (this.state.downloadXlsAutoRouterLoading.length !== 0 && this.state.downloadXlsAutoRouterLoading
            .filter(loading => (!!loading && !!rowData.id) && loading.toString() === rowData.id.toString()).length !== 0) {
                return(
                    <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76'}} spin />} />
                )
            }
            
        return (
            <Button
                style={disableButtom ? styles.actions.disableButtom : styles.actions.button}
                size={'small'}
                type="primary"

                onClick={() => this.downloadXlsAutoRouter(rowData)}>

                <Icon type="download" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Baixar</p>

            </Button>
        );
    }

    renderDeleteTaskButtom = rowData => {
        if (this.state.autoRouterRowLoading.length !== 0 && this.state.autoRouterRowLoading
            .filter(loading => (!!loading && !!rowData.id) && loading.toString() === rowData.id.toString()).length !== 0) {
                return(
                    <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76'}} spin />} />
                )
        }
            return (
                <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.deleteTasks(rowData)}>
                    <Icon type="delete" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Excluir Tarefas</p>
                </Button>
            );
    }  

    renderRerouterButton = rowData => {
        return (
            <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.handleOnRerouterClick(rowData)}>
                <img src={images.deadline_icon} alt={"deadline_icon"}/>
                <p style={styles.actions.text}>Reenviar</p>
            </Button>
        );
    }

    renderReportAutoRouterButton = rowData => {
        return (
            <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.showReportModalByRowData(rowData)}>
                <img src={images.map_icon} alt={"map_icon"}/>
                <p style={styles.actions.text}>Relatório</p>
            </Button>
        );
    }

    renderActions = (rowData) => {
        return (
            <div style={styles.actions.div}>
                {this.renderTableActions(rowData)}
            </div>
        );
    } 

    renderStatus = rowData => {
        return(
            <Button
                style={{...styles.actions.status, borderStyle: "solid", borderWidth: "1px"}}
                onClick={() => this.handleOpenStatusModal(rowData)}>

                <p style={{ ...styles.actions.text, color: tableStatusConfig[rowData.status].color}}>{tableStatusConfig[rowData.status].text}</p>
                
            </Button>
        );
    }

    renderUnityGroupOptions = () => {
        return this.state.unityGroupList.map(unityGroup => {
            return (
                <Option key={unityGroup.id} value={unityGroup.id}>{unityGroup.name}</Option>
            );
        });
    }

    renderUnityGroups = (rowData) => {
        const content = <div style={{maxHeight: "100px", overflowY: "scroll"}}>
                            {rowData.unityGroups.map((unityGroup) => <p>{unityGroup.id}-{unityGroup.name}</p>)}
                        </div>
        
        const onlyOne = rowData.unityGroups.length === 1;
        const hoverText = onlyOne ? rowData.unityGroups[0].name : rowData.unityGroups[0].name + " ...";
        return (
            <Popover content={content} title="Pólos">
                <p style={{...styles.actions.text}}>{hoverText}</p>
            </Popover>
            );
    }

    renderUnityGroupsFilter = () => {
        return(
            <div style={{ width: "250px" }}>
                <h5>Filtrar por Pólo:</h5>
                <Select size={"large"}
                    allowClear={true}
                    showArrow={true}
                    style={{width: "100%", zIndexOffset: '1001',}}
                    placeholder={"Selecione o Pólo"}
                    loading={this.state.initialLoadingUnityGroup}
                    value={this.state.selectedUnityGroupId}
                    onChange={this.handleChangeUnityGroup} >
                        {this.renderUnityGroupOptions()}
                </Select>
            </div>
        );
    }

    renderTable = () => {        
        if (this.state.loading) {
            return this.renderLoading();
        }

        const columnsTable = [

            {
                title: "ID",
                width: "60px",
                dataIndex: "id",
                align: "left",
                key: "id"
            },
            {
                title: "Usuário",
                dataIndex: "user",
                align: "left",
                key: "user"
            },
            {
                title: "Status",
                dataIndex: "status",
                align: "left",
                key: "status",
                render: (text, record) => this.renderStatus(record)
            },
            {
                title: "Data de roteirização",
                dataIndex: "createdAt",
                align: "left",
                key: "createdAt"
            },
            {
                title: "Tipo",
                dataIndex: "creationAutoRouterType",
                align: "left",
                key: "creationAutoRouterType"
            },
            {
                title: "Pólos",
                dataIndex: "unityGroups",
                align: "left",
                key: "unityGroups",
                render: (text, record) => this.renderUnityGroups(record)
            },	
            {	
                title: "Data de ínicio",	
                dataIndex: "startDate",	
                align: "left",	
                key: "startDate"	
            },	
            {	
                title: "Data de fim",	
                dataIndex: "endDate",	
                align: "left",	
                key: "endDate"	
            },
            {
                title: "Ações",
                width: "100px",
                dataIndex: "actions",
                fixed: 'right',
                align: "left",
                key: "actions",
                render: (actions, record) => this.renderActions(record)
            }
        ];

        return(
            <div>
                <div style={styles.tabHeaderDiv}>
                    <h2 style={{margin: 0}} >Roteirizações</h2>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {this.renderUnityGroupsFilter()}
                        <Button style={{margin: "10px", paddingTop: "5px"}} type="link" onClick={() => this.reloadAutoRouterCreatedTable()}>
                            <Icon type="reload" style={{color: "#782777", fontSize: '30px', }}/>
                        </Button>
                    </div>
                </div>
                <div>
                    <Table 
                        columns={columnsTable}
                        dataSource={this.state.filteredAutoRouters ? this.state.filteredAutoRouters : this.state.autoRoutersData}
                        scroll={{ x: true, y: false }}
                        style={{paddingBottom: 0}} 
                        pagination={{
                            pageSize: 10
                        }}
                        />
                </div>
            </div>
        );
    }
    
    render(){
        return(
            <div>
                {this.renderErrorModal()}
                {this.renderTable()}
                {this.state.currentRow && 
                    <StatusModal
                        currentRow={this.state.currentRow}
                        type={"router"}
                        onClose={this.handleOnCloseStatusModal}
                    />}
            </div>
        )
    }

}

function mapStateToProps({ createTasksFromAutoRouter, removeAutoRouter, downloadExcelGenerateByAutoRouter,
    removeTaskCreatedFromAutoRouter, fetchAutoRouterCreatedList, unityGroupList, fetchReRouterAutoRouter }) {
    return { createTasksFromAutoRouter, removeAutoRouter, downloadExcelGenerateByAutoRouter, removeTaskCreatedFromAutoRouter,
        fetchAutoRouterCreatedList, unityGroupList,fetchReRouterAutoRouter };
}

export default connect(mapStateToProps, { ...autoRouterExecutorActions, ...unityGroupActions })(RouterTable);
