import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table , Modal, Spin, Icon, Popover, Checkbox, Button } from 'antd';
import moment from 'moment';
import * as reportHistoricActions from "../../actions/reportHistoricActions"
import { isAdmin } from "../../permission"

const styles = {
    tabHeaderDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: "10px",
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            width: "100%"
        },
        text: {
            margin: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            align: "center",
        }
    },
    downloadButton: {
        textDecoration: 'underline',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#781e76",
        marginRight: "20px",
        cursor: 'pointer'
    },
    text: {
        paddingLeft: '10px',
        margin: 0
    },
    buttonDiv: {
        display: "flex"
    },
    progress: {
        paddingRight: '25%'
    }
}

const reportTypeConfig = {
    "DETAILED_COLLECTION": {
        text: "Detalhado"
    },
    "CONSOLIDATED_COLLECTION": {
        text: "Consolidado"
    },
    "CROP_LOSSES": {
        text: "Perdas"
    }
}

const REPORT_STATUS = {
    "NOT_STARTED": "Não iniciado",
    "IN_PROGRESS": "Gerando relatório",
    "PROCESSED": "Finalizado",
    "ERROR": "Erro",
    "NO_DATA": "Sem dados"
}

class ReportHistoric extends Component {
    constructor(props){
        super(props);
    
        this.state = {
            reports: [],
            downloadUrl: null,
            gettingUrl: false,
            loading: false,
            getFromAllUsers: false
        }
    }
    
    componentDidMount() {
        this.loadReportList()
    }
    showModalError = (title, description) => {
        Modal.error(
            {
                title: title,
                content: description
            }
        )
    };

    componentDidUpdate(prevProps) {
        if (prevProps.fetchReportList !== this.props.fetchReportList){
            if (!this.props.fetchReportList.errorStatus){
                this.formatReportData(this.props.fetchReportList)
            }
            this.setState({loading:false})
        }
        if (prevProps.fetchAllReportList !== this.props.fetchAllReportList){
            if (!this.props.fetchAllReportList.errorStatus){
                this.formatReportData(this.props.fetchAllReportList)
            }
            this.setState({loading:false})
        }
        if (prevProps.generateReportRequestUrlDownload !== this.props.generateReportRequestUrlDownload){
            if (!this.props.generateReportRequestUrlDownload.errorStatus){
                this.redirectToDownloadUrl(this.props.generateReportRequestUrlDownload)
            }
        }
        if (prevProps.lastTimeSentRequest !== this.props.lastTimeSentRequest) {
            this.loadReportList();
        }
    }

    formatReportData = unformattedReports => {

        let reports = unformattedReports.map((report, index) => {
            return {
                key: index,
                id: report.id,
                reportEmail: report.email,
                reportPeriod: report.start_date + " -> " + report.end_date,
                endDate: report.end_date,
                startDate: report.start_date,
                farms: report.farms,
                reportType: report.report_type,
                requestedAt: moment(report.requested_at).format("DD/MM/YYYY HH:mm"),
                requestedBy: report.requested_by,
                reportIssueTypes: report.custom_parameters ? report.custom_parameters.issues_type : [],
                reportIssues: report.custom_parameters ? report.custom_parameters.issues : [],
                status: REPORT_STATUS[report.status]
            }
        });

        this.setState({ reports })
    }

    loadReportList = getFromAllUsers => {
        if (getFromAllUsers === undefined) {
            getFromAllUsers = this.state.getFromAllUsers;
        }

        this.setState({ loading:true });
        this.props.getReportListByCurrentUser(getFromAllUsers);
    }

    redirectToDownloadUrl = report => {
        const expiresIn = new URL(report.download_url).searchParams.get("Expires");
        const expired = moment.unix(expiresIn) < moment();

        if (expired) {
            const title = "Relatório expirado";
            const description = "Este relatório está expirado, será necessário gerar novamente.";
            this.showModalError(title, description);
        } else {
            window.location.href = report.download_url;  
        }
    }

    downloadReportUrl = report => {
        return this.props.generateReportRequestDownloadUrl(report.id)
    }

    checkAllReports = (e) => {
        let getFromAllUsers = !this.state.getFromAllUsers;
        this.setState({ getFromAllUsers });

        this.loadReportList(getFromAllUsers);
    }

    handleRefreshButtonOnClick = () => {
        this.loadReportList()
    }

    renderHistoric = () =>{
        if (this.state.loading) {
            return this.renderLoading();
        }

        const columnsTable = [
            {
                title: "ID",
                witdh: "60px",
                dataIndex: "id",
                align: "left",
                key: "id"
            },
            {
                title: "Tipo do relatório",
                dataIndex: "reportType",
                align: "left",
                key: "reportType",
                render: (text, record) => this.renderReportType(record)
            },
            {
                title: "Status",
                dataIndex: "status",
                align: "left",
                key: "status"
            },
            {
                title: "Fazendas",
                dataIndex: "farms",
                align: "left",
                key: "farms",
                render: (text, record) => this.renderFarms(record)
            },
            {
                title: "Período Solicitado",
                dataIndex: "reportPeriod",
                align: "left",
                key: "reportPeriod"
            },
            {
                title: "Problemas",
                dataIndex: "reportIssues",
                align: "left",
                key: "reportIssues",
                render: (actions, record) => this.renderIssues(record)
            },
            {
                title: "Data",
                dataIndex: "requestedAt",
                align: "left",
                key: "requestedAt"
            },
            {
                title: "Email",
                dataIndex: "reportEmail",
                align: "left",
                key: "reportEmail"
            },
            {
                title: "Ações",
                width: "150px",
                dataIndex: "actions",
                fixed: 'right',
                align: "left",
                key: "actions",
                render: (actions, record) => this.renderActions(record)
            }
        ];

        return (
            <div>
                <div style={styles.tabHeaderDiv}>
                    <h2 style={{margin: 0}} >Relatórios</h2>
                    <div>
                        {this.renderAllReportsCheckBox()}
                        <Button style={{margin: "10px", paddingTop: "5px"}} type="link" onClick={this.handleRefreshButtonOnClick}>
                            <Icon type="reload" style={{color: "#782777", fontSize: '30px', }}/>
                        </Button>    
                    </div>
                </div>
                <div>
                    <Table
                        columns={columnsTable}
                        dataSource={this.state.reports}
                        scroll={{ x: true, y: false }}
                        style={{paddingBottom: 0}} 
                        pagination={{
                            pageSize: 7
                        }}
                    />
                        
                </div>
            </div>
        )
    }

    renderAllReportsCheckBox = () => {
        if (isAdmin()) {
            return (
                <Checkbox
                    style={{color: '#781e76'}}
                    onChange={this.checkAllReports}
                    checked={this.state.getFromAllUsers}>
                    Ver todos
                </Checkbox>
            )
        }
    }
    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56, color: '#781e76'}} spin />} />
            </div>
        );
    }

    renderActions = (rowData) => {
        if (rowData.status !== REPORT_STATUS["PROCESSED"]) {
            return null;
        }
        
        return (
            <div style={styles.actions.div}>
                {this.renderTableActions(rowData)}
            </div>
        );
    } 

    renderTableActions = report => {
        return(
            <Button 
                style={styles.actions.button}
                size={'small'}
                type="primary"
                onClick={() => this.downloadReportUrl(report)}
            >
                <Icon type="download" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Baixar</p>
            </Button>
        )
    }

    renderReportType = rowData => {
        if (!rowData.reportType || !reportTypeConfig[rowData.reportType]) {
            return null;
        }
        
        return (
            <p style={styles.actions.text}>
                {reportTypeConfig[rowData.reportType].text}
            </p>
        )
    }

    renderIssueType = rowData => {
        if (!rowData.reportIssueTypes.length) {
            return null;
        }

        const content = <div style={{maxHeight: "100px", overflowY: "scroll"}}>{rowData.reportIssueTypes.map(issueType => <p key={issueType.id}>{issueType.name}</p>)}</div>
        const onlyOne = rowData.reportIssueTypes.length === 1;
        const hoverText = onlyOne ? rowData.reportIssueTypes[0].name : rowData.reportIssueTypes[0].name + " ...";

        return (
            <Popover content={content} title="Tipos de problemas">
                <p style={{...styles.actions.text}}>{hoverText}</p>
            </Popover>
        );
    }

    renderFarms = rowData => {
        if (!rowData.farms.length) {
            return null;
        }

        const content = <div style={{maxHeight: "300px", overflowY: "scroll"}}>{rowData.farms.map(farm => <p key={farm.id}>{farm.name}</p>)}</div>
        const onlyOne = rowData.farms.length === 1;
        const hoverText = onlyOne ? rowData.farms[0].name : rowData.farms[0].name + " ...";

        return (
            <Popover content={content} title="Fazendas">
                <p style={{...styles.actions.text}}>{hoverText}</p>
            </Popover>
        );
    }

    renderIssues = rowData => {
        if (!rowData.reportIssues.length) {
            return null;
        }

        const content = <div style={{maxHeight: "300px", overflowY: "scroll"}}>{rowData.reportIssues.map(issue => <p key={issue.id}>{issue.name}</p>)}</div>
        const onlyOne = rowData.reportIssues.length === 1;
        const hoverText = onlyOne ? rowData.reportIssues[0].name : rowData.reportIssues[0].name + "...";

        return (
            <Popover content = {content} title= "Problemas" style={{ maxHeight: 400 }}>
                <p style={{...styles.actions.text}}>{hoverText}</p>
            </Popover>
        );
    }

    render() {
        return (
            <div>
                {this.renderHistoric()}
            </div>
        )
    }
    
}

function mapStateToProps({fetchReportList, fetchAllReportList, generateReportRequestUrlDownload}){
    return {fetchReportList, fetchAllReportList, generateReportRequestUrlDownload}
}
export default connect(mapStateToProps, {...reportHistoricActions})(ReportHistoric);
