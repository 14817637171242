import React, {Component} from 'react';
import  { Button, Modal, Upload, Spin, Icon, Table, notification} from 'antd';
import { connect } from "react-redux";
import * as autoRouterActions from "../../actions/autoRouterActions"
import { images } from '../../assets';

const styles = {
    sendVehicle: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        color: '#ffffff',
    },
    modalDiv: {
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    uploadVehicleButton: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        color:  "#ffffff",
        fontSize: "12pt",
        marginBottom: "10pt"
    },
    tabHeaderDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        buttonDisabled: {
            backgroundColor: '#dddddd',
            borderColor: '#000',
            color: '#000',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        }
    },
    buttonOk: {
        backgroundColor: '#782777',
        color: '#fff',
        borderColor: '#782777',
    },
    loading: {
        display: "inline-block",
        text: {
                display: "inline-block",
                marginLeft: "10px",
                marginRight: "20px"
            },
        icon: {
            display: "inline-block",
            color: "#782777",
            fontSize: 48
        }
    },
    jsonButton: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#ffffff",
        fontSize: "12pt",
        marginBottom: "16pt"
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            maxWidth: "170px"
        },
        text: {
            margin: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            align: "center",
        },
    }
}

class VehicleManager extends Component {
    constructor(props) {
        super (props)

        this.state = {
            loading: true,
            vehicleData: [],
            showUploadExcelVehicleModal:false,
            createVehicle: {
                buttonDisabled: true,
                fileList: [],
            },
            loadingExcelFile:false
        }
    }

    componentDidMount() {
        this.getVehicleList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchVehicles !== this.props.fetchVehicles) {
            if (!this.props.fetchVehicles.errorStatus){
                this.setState({
                    vehicleData: this.props.fetchVehicles,
                    loading: false,
                })
            } else {
                this.setState({loading: false});
            }
        }
        if (prevProps.uploadExcelVehicle !== this.props.uploadExcelVehicle) {
            if (!this.props.uploadExcelVehicle.errorStatus){
                this.getVehicleList();
                this.uploadNotification();
                this.setState({
                    loadingExcelFile: false,
                })
            } else {
                this.setState({loadingExcelFile: false});
            }
        }
        if (prevProps.removeAllVehicles !== this.props.removeAllVehicles) {
            if (!this.props.removeAllVehicles.errorStatus){
                this.getVehicleList();
                this.setState({
                    loading: false,
                });
                this.deleteNotification();
            } else {
                this.setState({loading: false});
            }
        }
    }

    getVehicleList = () => {
        this.setState({ loading: true });
        this.props.getVehicleList()
    }

    showUploadExcelVehicleModal = () => {
        this.setState({
            showUploadExcelVehicleModal: true
        });
    }

    handleVehicleModalOk = () => {
        let params = {
            file: this.state.createVehicle.fileList[0]
        }
        this.props.postExcelVehicle(params)
        this.setState({
            showUploadExcelVehicleModal: false,
            loadingExcelFile: true,
            createVehicle: {
                buttonDisabled: true,
                fileList: [],
            },
        });
    }

    handleVehicleModalCancel = () => {
        this.setState({
            showUploadExcelVehicleModal:false,
            createVehicle: {
                buttonDisabled:true,
                fileList: []
            }
        })
    }

    confirmDelete = rowData => {
        Modal.confirm({
            title: 'Deseja excluir ?',
            content: 'Ao confirmar você excluirá todos os veículos de ' + rowData.unity_name,
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.deleteAllVehicles(rowData.unity_id)
          });
    }

    uploadNotification = () =>{
        notification.success({
          message: 'Veículos atualizados com sucesso',
          },
        );
    };

    deleteAllVehicles = unityId => {
        this.setState({loading: true});
        this.props.deleteAllVehicles(unityId);
    }

    deleteNotification = () =>{
        notification.open({
          message: 'Todos os veículos da unidade foram excluídos ',
          icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          },
        );
      };


    renderUploadExcelVehicleModalContent = () => {
        const {fileList} = this.state.createVehicle;
        const props = {
            onRemove: file => {
              this.setState(state => {
                const index = state.createVehicle.fileList.indexOf(file);
                const newFileList = state.createVehicle.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    createVehicle: {
                        ...state.createVehicle,  
                        fileList: newFileList,
                    }
                };
              });
            },
            beforeUpload: file => {
              this.setState(state => ({
                createVehicle: {
                    ...state.createVehicle,
                    fileList: [...state.createVehicle.fileList, file],
                }
              }));
              return false;
            },
            fileList,
        };
        return (
            <div>
                <p>Escolha um arquivo xls válido</p>
                <Upload {...props}>
                    <Button style={styles.upload.button} size={'large'} type="primary" onClick={() => {}}>
                        <img src={images.upload_icon} alt={"upload_icon"}></img>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                </Upload>
            </div>
        );
    }

    renderUploadExcelVehicleModal = () => {
        return (
            <Modal
                title="Adicionar veículo"
                visible={this.state.showUploadExcelVehicleModal}
                onOk={this.handleVehicleModalOk}
                onCancel={this.handleVehicleModalCancel}
                okButtonProps={{style: this.state.createVehicle.fileList.length === 0 ? {...styles.sendVehicle, opacity: 0.5} : styles.sendVehicle, disabled: this.state.createVehicle.fileList.length === 0 }}
            >
                {this.renderUploadExcelVehicleModalContent()}
            </Modal>
        );
    }

    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56}} spin />} />
            </div>
        );
    }

    renderLoadingModal = () => {
        return (
            <Modal
                title="Verificando arquivo..."
                visible={this.state.loadingExcelFile}
                closable={false}
                footer={[]}
            >
                <div styles={styles.modalDiv}>
                    <p>Os campos do arquivo enviado estão em verificação.</p>
                    <p>Por favor aguarde...</p>
                    <Spin indicator={<Icon type="loading" style={styles.loading.icon} spin />} ></Spin>
                </div>
            </Modal>

        );
    }

    renderActions = (rowData) => {
        return (
            <div style={styles.actions.div}>
                <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.confirmDelete(rowData)}>
                    <Icon type="delete" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Excluir</p>
                </Button>
            </div>
        );
    }

    renderTable = () => {
        const columns = [
            {
                title: "ID",
                width: "60px",
                dataIndex: "unity_id",
                align: "left",
                key: "unity_id"
            },
            {
                title: "Unidade",
                dataIndex: "unity_name",
                align: "left",
                key: "unity_name"
            },
            {
                title: "Total de veículos",
                dataIndex: "num_total_vehicles",
                align: "left",
                key: "num_total_vehicles"
            },
            {
                title: "Capacidade Total",
                dataIndex: "num_total_max_members",
                align: "left",
                key: "num_total_max_members"
            },
            {
                title: "Ações",
                width: "120px",
                dataIndex: "actions",
                fixed: 'right',
                align: "left",
                key: "actions",
                render: (text, record) => this.renderActions(record)
            }
        ];
        
        if (this.state.loading) {
            return this.renderLoading();
        }

        return(
            <div>
                {this.renderTabHeader()}
                <Table 
                    columns={columns}
                    dataSource={this.state.vehicleData}
                    scroll={{ x: true, y: false }}
                    style={{paddingBottom: 0}} 
                    pagination={{
                        pageSize: 10
                    }} 
                    />

                    {this.renderUploadExcelVehicleModal()}
                    {this.renderLoadingModal()}
            </div>
        );
    }

    renderTabHeader = () => {
        return (
            <div style={styles.tabHeaderDiv}>
                <h2>Veículos</h2>
                <Button style={styles.uploadVehicleButton} size={'large'} type="primary" onClick={() => this.showUploadExcelVehicleModal()}>
                    Adicionar veículos
                </Button>
            </div>
        )
    }

    render() {
        return(
            <div>
                {this.renderTable()}
            </div>
        );
    }

}

function mapStateToProps({ fetchVehicles, uploadExcelVehicle, removeAllVehicles }) {
    return { fetchVehicles, uploadExcelVehicle, removeAllVehicles };
}

export default connect(mapStateToProps, { ...autoRouterActions  })(VehicleManager);
