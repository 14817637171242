import React, { Component } from 'react';
import { Table, Button, Icon, Spin, Modal, notification, Dropdown, Menu} from 'antd';
import StatusModal from './StatusModal';
import { connect } from "react-redux";
import moment from 'moment';
import * as autoRouterExecutorActions from "../../actions/autoRouterExecutorActions";

const styles = {
    tabHeaderDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: "10px",
    },
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
    },
    button: {
        backgroundColor: "#782777", 
        border:"0px",
        width: "auto",
        color: "#fff"
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        buttonDisabled: {
            backgroundColor: '#dddddd',
            borderColor: '#000',
            color: '#000',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        }
    },
    downloadButton: {
        textDecoration: 'underline',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#781e76",
        marginRight: "20px",
        cursor: 'pointer'
    },
    buttonDiv: {
        display: "flex"
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "8px",
            marginLeft: "8px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            maxWidth: "178px",
        },
        text: {
            margin: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            align: "center",
        },
        actionDropdown: {
            textDecoration: 'underline',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            justifyContent: 'space-around',
            margin: 0,
            color:  "#781e76",
            marginRight: "20px",
            cursor: 'pointer'
        }
    },
    progress: {
        paddingRight: '25%'
    }
};

class UploadTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            showAutoRouterModal: false,
            loading: true,
            autoRouterModalData: {
                buttonDisabled: true,
                fileList: []
            },
            taskModalData: {
              buttonDisabled: true,
              fileList: [],
              data: null  
            },
            downloadingUploadedTask: [],
            currentRow: null,
            innerWidth: 0,
            resendingUpload: [],
        }
    }

    componentDidMount() {
        this.props.getAutoRouterList()
        this.setState({ innerWidth: window.innerWidth });
        window.addEventListener('resize', this.updateScreenDimensions);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchAutoRouterList !== this.props.fetchAutoRouterList){
           if (!this.props.fetchAutoRouterList.errorStatus){
               this.formatAutoRoutersData(this.props.fetchAutoRouterList);
           }
           this.setState({loading: false});
        }
        if (prevProps.downloadUploadedTask !== this.props.downloadUploadedTask){
            if (!this.props.downloadUploadedTask.errorStatus) {
                let downloadingUploadedTask = this.state.downloadingUploadedTask;
                downloadingUploadedTask.splice(this.state.downloadingUploadedTask.indexOf(this.props.downloadUploadedTask.id), 1)
                this.setState({downloadingUploadedTask});
            } else {
                let downloadingUploadedTask = this.state.downloadingUploadedTask;
                downloadingUploadedTask.splice(this.state.downloadingUploadedTask.indexOf(this.props.downloadUploadedTask.id), 1)
                this.setState({downloadingUploadedTask});
                this.showWaitGenerateFileModal(this.props.downloadUploadedTask.errorData)
            }
        }
        if (prevProps.removeAutoRouterUploadedExcel !== this.props.removeAutoRouterUploadedExcel) {
            if (!this.props.removeAutoRouterUploadedExcel.errorStatus) {
                this.props.getAutoRouterList();
                this.deleteNotification();
            } else {
                this.props.removeAutoRouterUploadedExcel.errorStatus.name === 'AUTO_ROUTER_EXCEL_FILE_NOT_GENERATED' ? 
                this.showDeleteConfirmModal(this.props.removeAutoRouterUploadedExcel.errorData) :
                this.showModalError(this.props.removeAutoRouterUploadedExcel.errorData);
                this.setState({loading: false});
            }
        }
        if (prevProps.removeAutoRouterUploadedForcedExcel !== this.props.removeAutoRouterUploadedForcedExcel) {
            if (!this.props.removeAutoRouterUploadedForcedExcel.errorStatus) {
                this.props.getAutoRouterList();
                this.deleteForcedNotification();
            } else {
                this.setState({loading: false});
            }
        }
        if (prevProps.resendUpload !== this.props.resendUpload){
            if (!this.props.resendUpload.errorStatus) {
                let resendingUpload = this.state.resendingUpload;
                resendingUpload.splice(this.state.resendingUpload.indexOf(this.props.resendUpload.id), 1)
                this.setState({resendingUpload});
            } else {
                let resendingUpload = this.state.resendingUpload;
                resendingUpload.splice(this.state.resendingUpload.indexOf(this.props.resendUpload.id), 1)
                this.setState({resendingUpload});
                this.showWaitGenerateFileModal(this.props.resendUpload.errorData)
            }
        }
    }

    updateScreenDimensions = () => {
        this.setState({ innerWidth: window.innerWidth });
    }

    reloadAutoRouterTable = ()  => {
        this.setState({loading:true})
        this.props.getAutoRouterList()
    }
    handleDownloadUploadedTask = async (fileId) => {
        this.setState({downloadingUploadedTask: this.state.downloadingUploadedTask.concat(fileId)});
        await this.props.downloadUploadedTaskExcel(fileId);
    };

    handleResendUpload = async (fileId) => {
        this.setState({resendingUpload: this.state.resendingUpload.concat(fileId)});
        await this.props.resendUploadAutoRouter(fileId);
    };

    handleDeleteUploadedExcel = async (fileId) => {
        this.setState({loading: true});
        await this.props.deleteUploadedExcel(fileId);
    };

    handleOpenStatusModal = rowData => {
        this.setState({currentRow: rowData});
    }

    handleOnCloseStatusModal = () => {
        this.setState({currentRow: null})
    }


    formatAutoRoutersData = autoRouters => {
        this.setState({
            autoRoutersData: autoRouters.map( autoRouter => ({
                id: autoRouter.id,
                user: autoRouter.user_name,
                email: autoRouter.email,
                createdAt: moment(autoRouter.created_at).format("DD/MM/YYYY HH:mm"),
                status: autoRouter.status}))
        })
    }

    confirmDelete = uploadId => {
        Modal.confirm({
            title: 'Deseja excluir ?',
            content: 'Ao confirmar você excluirá esse upload',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.deleteUploadedExcel(uploadId)
          });
    }

    showDeleteConfirmModal = (errorData) => {
        Modal.confirm({
            title: errorData.title,
            content: errorData.description,
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.deleteUploadedExcelForced(errorData.autoRouterExcelRequestId)
        });
    }

    showModalError = (errorData) => {
        Modal.error(
            {
                title: errorData.title,
                content: errorData.description
            }
        )
    };

    showWaitGenerateFileModal = (errorData) => {
        Modal.info({
            title: errorData.title,
            content: errorData.description,
            okText: 'Ok'
        });
    }

    deleteUploadedExcelForced = (autoRouterExcelRequestId) => {
        this.props.deleteUploadedExcelForced(autoRouterExcelRequestId)
    }

    deleteUploadedExcel = uploadId => {
        this.setState({loading: true});
        this.props.deleteUploadedExcel(uploadId)
    }

    deleteNotification = () =>{
        notification.open({
          message: 'Upload excluído com sucesso',
          icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          },
        );
    };

    deleteForcedNotification = () => {
        notification.open({
            message: 'Tarefas não roteirizadas excluídas com sucesso',
            icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
            },
          );
    }


    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56, color: '#781e76'}} spin />} />
            </div>
        );
    }


    renderDownloadXlsButton = rowData => {
        if (this.state.downloadingUploadedTask.length !== 0 && this.state.downloadingUploadedTask
            .filter(loading => (!!loading && !!rowData.id) && loading.toString() === rowData.id.toString()).length !== 0) {
            return(
                <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76'}} spin />} />
            )
        }
        return (
            <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.handleDownloadUploadedTask(rowData.id)}>
                <Icon type="download" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Baixar</p>
            </Button>
        );
    }

    renderResendButton = rowData => {
        const status_allowed = ['VALIDATING', 'ERROR']
        if ( !status_allowed.includes(rowData.status) ) {
            return
        }

        if (this.state.resendingUpload.length !== 0 && this.state.resendingUpload
            .filter(loading => (!!loading && !!rowData.id) && loading.toString() === rowData.id.toString()).length !== 0) {
            return(
                <Spin indicator={<Icon type="loading" style={{fontSize: 24, color: '#781e76'}} spin />} />
            )
        }
        return (
            <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.handleResendUpload(rowData.id)}>
                <Icon type="download" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Reenviar</p>
            </Button>
        );
    }

    renderDeleteButton = rowData => {
        return (
            <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.confirmDelete(rowData.id)}>
                <Icon type="delete" style={{color: "#782777"}} />
                <p style={styles.actions.text}>Excluir</p>
            </Button>
        );
    }

    renderStatus = rowData => ({
        'IN_PROGRESS':                  <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "green"}}>Em progresso</p>
                                        </Button>,

        'PROCESSED':                    <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "green"}}>Processada</p>
                                        </Button>,

        'PARTIALLY_PROCESSED':          <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "green"}}>Parcialmente Processada</p>
                                        </Button>,

        'ERROR':                        <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "red"}}>Erro</p>
                                         </Button>,

        'VALIDATION_ERROR':             <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "red"}}>Erro de validação</p>
                                        </Button>,

        'NOT_CREATED_IN_PROTECTOR':    <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "black"}}>Não criada no protector</p>
                                        </Button>,

        'NOT_PROCESSED':                <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "orange"}}>Não processado</p>
                                        </Button>,

        'VALIDATING':                   <Button style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}} onClick={() => this.handleOpenStatusModal(rowData)}>
                                            <p style={{...styles.actions.text, color: "orange"}}>Validando excel</p>
                                        </Button>,
    })[rowData.status];
    
    renderTableActionsMenuItemss = rowData => {
        return(
            <Menu>
                <Menu.Item>
                    <div style={styles.actions.actionDropdown}>
                        {this.renderDeleteButton(rowData)}
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div style={styles.actions.actionDropdown}>
                        {this.renderDownloadXlsButton(rowData)}
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div style={styles.actions.actionDropdown}>
                        {this.renderResendButton(rowData)}
                    </div>
                </Menu.Item>
            </Menu>
        );
    }

    renderActions = (rowData) => {
        if (this.state.innerWidth >= 1350) {
            return (
                <div style={styles.actions.div}>
                    {this.renderDeleteButton(rowData)}
                    {this.renderDownloadXlsButton(rowData)}
                    {this.renderResendButton(rowData)}
                </div>
            );
        } else {
            return (
                <Dropdown placement={"bottomCenter"} trigger={['click']} overlay={this.renderTableActionsMenuItemss(rowData)}>
                    <div style={styles.actions.actionDropdown}>
                        Ações
                    </div>
                </Dropdown>
            );
        }
    }

    renderTable = () => {
        const columns = [
            {
                title: "ID",
                width: "60px",
                dataIndex: "id",
                align: "left",
                key: "id"
            },
            {
                title: "Usuário",
                dataIndex: "user",
                align: "left",
                key: "user"
            },
            {
                title: "Status",
                dataIndex: "status",
                align: "left",
                key: "status",
                render: (text, record) => this.renderStatus(record)
            },
            {
                title: "Data de envio",
                dataIndex: "createdAt",
                align: "left",
                key: "createdAt"
            },
            {
                title: "Ações",
                width: "100px",
                dataIndex: "actions",
                fixed: 'right',
                align: "left",
                key: "actions",
                render: (actions, record) => this.renderActions(record)
            }
        ];
        
        if (this.state.loading) {
            return this.renderLoading();
        }

        return(
            <div>
                <div style={styles.tabHeaderDiv}>
                    <h2 style={{margin: 0}}>Histórico de upload</h2>
                    <Button type="link" onClick={() => this.reloadAutoRouterTable()}>
                        <Icon type="reload" style={{color: "#782777", fontSize: '30px'}}/>
                    </Button>
                </div>


                <Table 
                    columns={columns}
                    dataSource={this.state.autoRoutersData}
                    scroll={{ x: true, y: false }}
                    style={{paddingBottom: 0}} 
                    pagination={{
                        pageSize: 10
                    }}
                    />
            </div>
        );
                
    }

    render(){
        return(
            <div>
                {this.renderTable()}
                {this.state.currentRow && 
                <StatusModal    currentRow={this.state.currentRow}
                                type={"upload"}
                                onClose={this.handleOnCloseStatusModal}
                />}
            </div>
        )
    }
}

function mapStateToProps({ fetchAutoRouterList, downloadUploadedTask, removeAutoRouterUploadedExcel, removeAutoRouterUploadedForcedExcel, resendUpload }) {
    return { fetchAutoRouterList, downloadUploadedTask, removeAutoRouterUploadedExcel, removeAutoRouterUploadedForcedExcel, resendUpload };
}

export default connect(mapStateToProps, { ...autoRouterExecutorActions })(UploadTable);
