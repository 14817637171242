import React, {Component} from 'react';
import  { Button, Modal, Upload, Spin, Icon} from 'antd';
import { images } from '../../assets';
import * as taskManagerActions from "../../actions/taskManagerActions";
import { connect } from "react-redux";


const styles = {
    datePicker: {
        display: "inline-block"
    },
    select: {
        width: "100%"
    },
    label: {
        fontSize: "15px",
        marginBottom: "10px"
    },
    sideNavBar: {
        display: "inline-block",
        margin: "10px",
        verticalAlign: "top",
        float: "left"
    },
    button: {
        backgroundColor: "#782777", 
        border:"0px",
        width: "auto",
        color: "#fff"
    },
    buttonLink: {
        marginRight: "40px",
        color:  "#782777",
        fontSize: "12pt",
        width: "140px"
    },
    bodyItem: {
        display: "block",
        margin: "0px",
        verticalAlign: "top",
        overflow: "hidden",
        width: "auto",
        height: "calc(100% - 70px)"
    },
    buttonOk: {
        backgroundColor: '#782777',
        color: '#fff',
        borderColor: '#782777',
    },
    progress: {
        paddingRight: '25%'
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        buttonDisabled: {
            backgroundColor: '#dddddd',
            borderColor: '#000',
            color: '#000',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        }
    },
    downloadButton: {
        textDecoration: 'underline',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#781e76",
        marginRight: "20px",
        cursor: 'pointer'
    },
    buttomAuto: {
        backgroundColor: '#fff',
        borderColor: '#781e76',
        color: '#781e76'
    },
    loading: {
        display: "inline-block",
        text: {
                display: "inline-block",
                marginLeft: "10px",
                marginRight: "20px"
            },
        icon: {
            display: "inline-block",
            color: "#782777",
            fontSize: 48
        }
    },
    loadingExcelFile: {
        icon: {
            display: "center",
            color: "#782777",
            fontSize: 60
        }
    },
    buttonDiv: {
        display: "flex"
    },
    modalDiv: {
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    }
}

const baseApi = process.env.REACT_APP_API_URL;
const downloadExcelModelPath = "task/download-excel-model"


class TaskModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalSelectExcelFile: false,
            createTask: {
                buttonDisabled: true,
                fileList: [],
            }, 
            loadingExcelFile: false,
            downloadModelProgress: false,
            requestHash: null
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.visible !== this.state.showModalSelectExcelFile){
            this.setState({
                showModalSelectExcelFile: this.props.visible
            })
        }
        if (prevProps.uploadFileExcel !== this.props.uploadFileExcel) {
            if (!this.props.uploadFileExcel.errorStatus) {
                if(this.props.uploadFileExcel.countShapeTask > 0){
                    this.setState({countShapeTask: this.props.uploadFileExcel.countShapeTask})
                } else {
                    let requestHash = this.props.uploadFileExcel.requestHash;
                    this.setState({
                        loadingExcelFile: false,
                        requestHash: requestHash
                    })
                    this.showModalSuccess("Tarefas com campos válidos", "Requisição " + requestHash + ": Todas as tarefas estão sendo processadas e serão enviadas por email");
                    this.props.onClose();
                }
            } else {
                this.setState({
                    loadingExcelFile: false
                })
            }
        }
    }

    setProgress = (downloadModelProgress) => {
        downloadModelProgress = downloadModelProgress === 100 ? false : downloadModelProgress
        this.setState({ downloadModelProgress });
    };

    onDownloadExcelModelClick = async () => {
        await this.props.downloadExcelModel(this.setProgress(this.state.downloadModelProgress));
    };

    getExcelModelDownloadPath = () => {
        return baseApi + downloadExcelModelPath
    }

    handleSelectExcelOK = e => {
        let params = {
            file: this.state.createTask.fileList[0]
        }
        this.props.uploadFileExcelToCreateTasks(params);
        this.setState({
            showModalSelectExcelFile: false,
            loadingExcelFile: true,
            createTask: {
                buttonDisabled: true,
                fileList: [],
            },
        });
    };
        
    handleSelectExcelCancel = e => {
        this.props.onClose();
        this.setState({
            createTask: {
                buttonDisabled: true,
                fileList: [],
            }
        });
    } 

    showModalError = (title, description) => {
        Modal.error(
            {
                title: title,
                content: description
            }
        )
    };

    showModalSuccess = (title, description) => {
        Modal.success(
            {
                title: title,
                content: description
            }
        )
    };

    uploadExcelFile = () => {
        const {fileList} = this.state.createTask;
        const props = {
            onRemove: file => {
              this.setState(state => {
                const index = state.createTask.fileList.indexOf(file);
                const newFileList = state.createTask.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    createTask: {
                        ...state.createTask,  
                        fileList: newFileList,
                    }
                };
              });
            },
            beforeUpload: file => {
              this.setState(state => ({
                createTask: {
                    ...state.createTask,
                    fileList: [...state.createTask.fileList, file],
                }
              }));
              return false;
            },
            fileList,
        };
        return (
            <div>
                <p style={styles.upload.label}>Selecione um arquivo (.xlsx) válido para criar novas atividades </p>
                <Upload {...props}>
                {this.state.createTask.fileList.length > 0 ? (
                    <Button disabled id='uploadButton' style={styles.upload.buttonDisabled} size={'large'} type="primary">
                        <img src={images.upload_icon} alt={"upload_icon"}></img>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                ) : (
                    <Button id='uploadButton' style={styles.upload.button} size={'large'} type="primary">
                        <img src={images.upload_icon} alt={"upload_icon"}></img>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                )}
                </Upload>
            </div>
        );
    }

    renderLoadingModal = () => {
        return (
            <Modal
                title="Verificando arquivo..."
                visible={this.state.loadingExcelFile}
                closable={false}
                footer={[]}
            >
                <div styles={styles.modalDiv}>
                    <p>Os campos do arquivo enviado estão em verificação.</p>
                    <p>Por favor aguarde...</p>
                    <Spin indicator={<Icon type="loading" style={styles.loading.icon} spin />} ></Spin>
                </div>
            </Modal>

        );
    }

    render() {
        return (
            <Modal
                title="Criar tarefa"
                visible={this.props.visible}
                onOk={this.handleSelectExcelOK}
                onCancel={this.handleSelectExcelCancel}
                okButtonProps={{style: this.state.createTask.fileList.length === 0 ? {...styles.buttonOk, opacity: 0.5} : styles.buttonOk, disabled: this.state.createTask.fileList.length === 0 }}

            >
                {this.uploadExcelFile()}
                {this.renderLoadingModal()}
            </Modal>
            
        )
    }

}

function mapStateToProps({ uploadFileExcel}) {
    return { uploadFileExcel};
}

export default connect(mapStateToProps, { ...taskManagerActions })(TaskModal);