import React, { Component } from 'react';
import  { Button, Divider, Table, Modal, Tabs } from 'antd';
import * as dateUtils from "../../utils/date_utils";

import moment from 'moment';

const { TabPane } = Tabs

const vehicleColumns = [
    {
        title: "Veículo",
        dataIndex: "vehicle_identifier",
        align: "left",
        key: "vehicle"
    }, {
        title: "Qtd. Trab.",
        dataIndex: "members_qnt",
        align: "left",
        key: "members_qnt"
    }, {
        title: "Tempo p/ executar",
        dataIndex: "timeToExecute",
        align: "left",
        key: "timeToExecute"
    }
];

const styles = {
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
        okButton: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            color: '#ffffff',
        }
    },
    header: {
        container: {
            width: "100%",
            textAlign: "center"
        },
        title: {
            fontSize: "12pt",
            margin: 0
        }
    },
    tab: {
    },
    tabPane: {
      paddingLeft: "5px",
      paddingRight: "5px"  
    },
    activity: {
        row: {
            width: "100%",
            display: "inline-block",
            paddingLeft: "10px"
        },
        column: {
            width: "40%",
            display: "inline-block"
        }
    },
    body: {
        marginTop: "10px",
        table: {
            actions: { width: "100%", marginTop: "20px", marginBottom: "20px", textAlign: "center" }
        }
    }
};

class AutoRouterActivityDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            selectedTaskRequests: [],
            vehicles: [],
            activityConfig: [],
            tasks: [],
            areaSize: 0,
            selectedVehicle: null,
            activityIdToShowTable: null
        };
    }

    componentDidMount() {
        this.setState({ 
            showModal: this.props.showModal,
            vehicles: this.props.vehicles,
            activityConfig: this.props.activityConfig
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showModal !== this.props.showModal) {
            this.setState({ showModal: this.props.showModal });
        }
        if (prevProps.vehicles !== this.props.vehicles) {
            this.setState({ vehicles: this.props.vehicles });
        }
        if (prevProps.activityConfig !== this.props.activityConfig) {
            this.setState({ activityConfig: this.props.activityConfig });
        }
        if (prevProps.tasks !== this.props.tasks) {
            let tasks = this.props.tasks;
            this.fillStartDate(tasks);
            this.setSelectedVehicle(tasks);
            this.setState({ tasks });
        }
        if (prevProps.areaSize !== this.props.areaSize) {
            this.setState({ areaSize: this.props.areaSize ? this.props.areaSize : 0 });
        }
    }

    handleOk = () => {
        this.props.onClose();
    }

    handleCancel = () => {
        this.props.onClose();
    }

    getActivityByTask = task => {
        return this.state.activityConfig.filter(activity => activity.id === task.activity_id)[0];
    }

    getTotalTime = () => {
        if (!this.state.selectedVehicle) {
            return null;
        }

        let tasks = this.state.tasks;

        let vehicle = this.state.selectedVehicle;
        let totalTime = 0;

        tasks.forEach(task => {
            let activity = this.getActivityByTask(task);
            let pointsQnt = this.getPointsAmount(activity);
            let time = this.getTimeToVehicleExecuteActity(vehicle, activity, pointsQnt)
            if (time) {
                totalTime += time;
            }
        });

        return totalTime;
    }

    getPointsAmount = (task) => {
        let hetaresPerPoints = task.hectares_per_points;

        if (hetaresPerPoints.includes("/")) {
            let values = hetaresPerPoints.split("/");

            let mult = parseInt(values[0]);
            let div = parseInt(values[1]);
            
            let result = this.state.areaSize * mult / div;
            return !result || result < 1 ? 1 : result;
        } else {
            return parseInt(hetaresPerPoints);
        }
    }
    
    setSelectedVehicle = tasks => {
        let selectedVehicle = null;
        if (tasks && tasks.length && tasks[0].vehicle_identifier) {
            selectedVehicle = this.state.vehicles.filter(vehicle => vehicle.vehicle_identifier === tasks[0].vehicle_identifier.toString())[0];
        }

        this.setState({ selectedVehicle });
    }

    fillStartDate = (tasks) => {
        tasks.forEach(task => {
            task.start_date = moment(task.start_date)
        });
    };

    getTimeToVehicleExecuteActity = (vehicle, activity, pointsQnt) => {
        let productivity = activity.productivity;
        let membersQuantity = vehicle.members_qnt;
        let maxWorkers = activity.max_workers;

        if (membersQuantity > maxWorkers) {
            membersQuantity = maxWorkers;
        }

        // if consider min workers the rule is (minWorkers * productivity / membersQuantity) * parseInt(pointsQnt);
        // if consider that 1 point can be done to only 1 worker the rule is parseInt(pointsQnt/membersQuantity) * tempo + (qtd_pontos % qtd_trab ? tempo : 0);
        // if consider proportional (productivity / membersQuantity) * parseInt(pointsQnt);

        return parseInt(pointsQnt/membersQuantity) * productivity + (pointsQnt % membersQuantity ? productivity : 0);
    }

    getVehiclesFromTask = (activity, pointsQnt, a) => {
        let vehicles = this.state.vehicles;

        vehicles.forEach(vehicle => {
            vehicle.timeToExecute = dateUtils.formatMinutesToShow(this.getTimeToVehicleExecuteActity(vehicle, activity, pointsQnt));
            vehicle.key = "VEHICLE_ROW" + activity.id + vehicle.vehicle_identifier;
        });

        return vehicles;
    }
    handleActivityShowChange = activityId => {
        this.setState({ activityIdToShowTable: activityId });
    }
    handleActivityHideChange = () => {
        this.setState({ activityIdToShowTable: null });
    }

    renderVehicleTablePerActivity = (activity, pointsQnt) => {
        if (sessionStorage.getItem("permission") === "ADMIN") {
            if (activity.id === this.state.activityIdToShowTable) {
                let vehicles = this.getVehiclesFromTask(activity, pointsQnt, activity.id);

                return(
                    <div>
                        <Table
                            key={"TABLE_VEHICLE_ACTIVITY" + activity.id}
                            columns={vehicleColumns}
                            dataSource={vehicles}
                            style={{paddingBottom: 0}} 
                            pagination={{
                                pageSize: 3
                            }} />

                        <div style={styles.body.table.actions}>
                            <Button onClick={this.handleActivityHideChange}>Ocultar outros veículos</Button>
                        </div>
                    </div>
                );
            } else {
                return(
                    <div style={styles.body.table.actions}>
                        <Button onClick={() => this.handleActivityShowChange(activity.id)}>Quanto tempo outros veículos gastariam</Button>
                    </div>
                );
            }
        }
    }

    renderSelectedVehicleToExecute = (activity, pointsQnt) => {
        if (!this.state.selectedVehicle) {
            return null;
        }

        const timeToExecute = this.getTimeToVehicleExecuteActity(this.state.selectedVehicle, activity, pointsQnt);

        return(
            <div>
                O veículo <b>{this.state.selectedVehicle.vehicle_identifier}</b> realizará essa atividade com <b>{dateUtils.formatMinutesToShow(timeToExecute)}</b> minutos
            </div>
        );
    }

    renderVehicleInfo = () => {
        if (!this.state.selectedVehicle) {
            return null;
        }

        return(
            <div>
                <b>Veículo:</b> {this.state.selectedVehicle.vehicle_identifier} com {this.state.selectedVehicle.members_qnt} membros
            </div>
        );
    }

    renderActivityContent = task => {
        const activity = this.getActivityByTask(task);
        
        if (!activity) {
            return;
        }

        const pointsToExecute = this.getPointsAmount(activity);

        const pointsToExecuteStr = parseInt(pointsToExecute);

        return(
            <div>

                <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
                    Janela de tempo: <b>{moment(task.start_time_window).format("DD/MM/YYYY")} - {moment(task.end_time_window).format("DD/MM/YYYY")}</b>
                </div>

                <div style={{ ...styles.activity.row, marginBottom: "10px" }}>
                    <b>{pointsToExecuteStr}</b> pontos serão executados para essa atividade
                </div>

                <div style={styles.activity.row}>
                    <div style={styles.activity.column}>
                        <b>Hectares por ponto:</b> {activity.hectares_per_points}
                    </div>
                    <div style={styles.activity.column}>
                        <b>Prod. (min/pontos):</b> {activity.productivity}
                    </div>
                </div>
                <div style={styles.activity.row}>
                    <div style={styles.activity.column}>
                        <b>Min-Max Trab.:</b> {activity.min_workers} - {activity.max_workers}
                    </div>
                    <div style={styles.activity.column}>
                        <b>Prioridade:</b> {activity.priority}
                    </div>
                </div>

                <div style={{ marginTop: "15px" }}>
                    {this.renderVehicleTablePerActivity(activity, pointsToExecute)}
                </div>
                <div>
                    {this.renderSelectedVehicleToExecute(activity, pointsToExecute)}
                </div>
            </div>
        );
    }

    renderActivityTab = () => {
        return this.state.tasks.map(task => {
            return(
                <TabPane style={styles.tabPane} tab={task.activity_name} key={"TAB_ACTIVITY_" + task.activity_id}>
                    {this.renderActivityContent(task)}
                </TabPane>
            );
        });
    };

    renderActivityTabs = () => {
        return(
            <Tabs style={styles.tab} >
                {this.renderActivityTab()}
            </Tabs>
        );
    }

    renderBody = () => {
        return(
            <div>

                <div>
                    {this.renderVehicleInfo()}
                </div>

                <Divider />

                <div>
                    Atividades que serão realizadas
                    {this.renderActivityTabs()}
                </div>

                <Divider />
            </div>
        );
    }

    getModalTitle = () => {
        if ( this.state.tasks[0].status === "TO_NOT_EXECUTE" || this.state.tasks[0].status === "ERROR"){
            return (
                <p style={styles.header.title}>
                    Tarefas não executadas para o talhão <b>{this.state.tasks[0].raizen_area_id}</b>
                </p> 
            );
        }
        return (
            <p style={styles.header.title}>
                Tarefas do dia <b>{this.state.tasks[0].start_date.format("DD/MM/YYYY")}</b> para o talhão <b>{this.state.tasks[0].raizen_area_id}</b>
            </p>
        );                  
    }

    renderHeader = () => {
        const minutes = this.getTotalTime();
        const minutesToShow = dateUtils.formatMinutesToShow(minutes);

        return(
            <div style={styles.header.container}>
                <div>
                    {this.getModalTitle()}
                </div>

                <div>
                    Fazenda: <b>{this.state.tasks[0].raizen_farm_id}</b>, Zona: <b>{this.state.tasks[0].raizen_zone_id}</b>         
                </div>

                <div style={{ display: this.state.selectedVehicle ? "" : "none" }}>
                    <b>{minutesToShow}</b> de execução
                </div>
                
                <div>
                    {this.state.areaSize ? this.state.areaSize.toFixed(2) : 0} ha
                </div>
                
            </div>
        );
    }

    renderContent = () => {
        if (!this.state.tasks || !this.state.tasks.length) {
            return;
        }

        return(
            <div>
                <div>
                    {this.renderHeader()}
                </div>

                <Divider />

                <div style={styles.body}>
                    {this.renderBody()}
                </div>
            </div>
        );
    }

    render() {
        return(
            <Modal
                title="Detalhes"
                visible={this.state.showModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{style: {...styles.form.okButton, opacity: 0.5} }}
            >
                {this.renderContent()}
            </Modal>
        );
    }
}

export default AutoRouterActivityDetailsModal;