import React, { Component } from 'react';
import CustomButton from '../commons/CustomButton';
import { Avatar, Divider, Dropdown, Menu, Spin } from 'antd';
import { images } from '../../assets';
import * as farmActions from "../../actions/farmActions";
import { connect } from "react-redux";

const styles = {
    content: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        height: "60px",
        borderBottom: "1px solid #E8EAED",
        backgroundColor: "white"
    },
    leftContent: {
        display: "inline-block",
        verticalAlign: 'middle',
        marginTop: "10px"
    },
    rightContent: {
        marginTop: "5px",
        float: 'right',
        verticalAlign: 'middle',
        display: "inline-block",
        marginRight: "20px"
    },
    downloadButton: {
        textDecoration: 'underline',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "green",
        fontSize: "10pt",
        marginRight: "12px",
        cursor: 'pointer'
    }
};


const protectorUrl = "http://painel.strider.ag/user/?#/signin/oauth";

class TopNavBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingFarmsUsersFile: false,
            showDownloadUsersButton: false
        };
    }

    componentDidMount() {
        this.setState({ 
            showDownloadUsersButton: sessionStorage.getItem("admin_role") === "true" || sessionStorage.getItem("permission") === "ADMIN"
        });
    }

    componentDidUpdate(prevPropos) {
        
        if (prevPropos.farmsUsersFile !== this.props.farmsUsersFile) {
            this.setState({ loadingFarmsUsersFile: false });
        }

    }

    onAccessProtectorClick = () => {
        window.location.href = protectorUrl;
    }

    handleFarmsUsersDownloadButton = () => {
        this.setState({ loadingFarmsUsersFile: true });
        this.props.downloadFarmUsers();
    }

    handleLogoutButton = () => {
        sessionStorage.setItem("token", "");
        sessionStorage.setItem("permission", "");
        sessionStorage.setItem("admin_role", "");
        window.location.pathname = "/login";
    }

    renderAvatarMenu = () => {
        return (
            <Menu>
                <Menu.Item onClick={this.handleLogoutButton}>Sair</Menu.Item>
            </Menu>
        );
    }

    renderDownloadUsersButton = () => {
        if (!this.state.showDownloadUsersButton) {
            return null;
        }
        
        if (this.state.loadingFarmsUsersFile) {
            return(
                <div style={{ display: "inline-block", marginRight: "50px" }}>
                    <Spin />
                </div>
            );
        }
        return (
            <div style={{ display: "inline-block", marginRight: "20px" }}>
                <p  style={styles.downloadButton} onClick={this.handleFarmsUsersDownloadButton}>
                    Baixar Usuários   
                </p>
            </div>
        );
    }
    renderRight = () => {
        return(
            <div style={styles.rightContent}>
                
                {this.renderDownloadUsersButton()}

                <div style={{ display: "inline-block", marginRight: "20px" }}>
                    <CustomButton
                        text="Acessar o Strider Protector >"
                        color="green"
                        onClick={this.onAccessProtectorClick} />
                </div>

                <div style={{ display: "inline-block", marginRight: "10px" }}>
                    
                        <Dropdown overlay={this.renderAvatarMenu()}>
                            <Avatar size={40} icon="user" />
                        </Dropdown>                       
                    
                </div>
            </div>
        );
    }

    renderLeft = () => {

        return(
            <div style={styles.leftContent}>
                <div style={{ display: "inline-block", marginLeft: "40px", marginRight: "10px" }}>
                    <img data-cy="strider-logo" src={images.top_nav_bar_strider} alt={"top_nav_bar_strider"} />
                </div>

                <Divider type="vertical" />

                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <img src={images.top_nav_bar_raizen} alt={"top_nav_bar_raizen"} />    
                </div>                
            </div>
        );

    }

    render() {
        return (
            <div style={styles.content}>
                {this.renderLeft()}
                {this.renderRight()}
            </div>
        );
    }
}

function mapStateToProps({ farmsUsersFile }) {
    return { farmsUsersFile };
}


export default connect(mapStateToProps, farmActions)(TopNavBar);