import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";

class AutoRouterReportMapLegend extends MapControl {
  createLeafletElement(props) {
    let divItem = {};

    this.setState({ divItem });
  }

  componentDidMount() {

    let legendItems = this.props.legendItems;

    if (!legendItems)
      return null;

    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        let labels = [];
        
        for (let i = 0; i < legendItems.length; i++) {
            labels.push(
                '<i style="background:' +
                    legendItems[i].color +
                    '"></i> ' + legendItems[i].title
                );
        }
        div.innerHTML = labels.join("<br>");

        this.setState({ divItem: div });
        return div;
    };

    const { map } = this.props.leaflet;
    legend.addTo(map);
  }

  componentDidUpdate(prevProps) {

    if (prevProps.legendItems !== this.props.legendItems) {
      let legendItems = this.props.legendItems;

      const legend = L.control({ position: "bottomright" });

      legend.onAdd = () => {
        L.DomUtil.remove(this.state.divItem);

        const div = L.DomUtil.create("div", "info legend");

        let labels = [];

        for (let i = 0; i < legendItems.length; i++) {
            labels.push(
                '<i style="background:' +
                    legendItems[i].color +
                    '"></i> ' + legendItems[i].title
                );
        }

        div.innerHTML = labels.join("<br>");

        this.setState({ divItem: div });
        return div;
      };

      const { map } = this.props.leaflet;
      legend.addTo(map);
    }

  }

  componentWillUnmount() {

  }
  
}

export default withLeaflet(AutoRouterReportMapLegend);
