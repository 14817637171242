import React, { Component } from 'react';
import TopNavBar from '../nav-bar/TopNavBar';
import SideNavBar from '../nav-bar/SideNavBar';
import { images } from '../../assets';
import { 
    Route,
    withRouter
} from "react-router-dom";
import "./blurStyle.css";

import DetailedReportManagement from '../detailed-report-management/DetailedReportManagement'
import TaskManager from '../task-manager/TaskManager';
import AutoRouterManager from '../auto-router-manager/AutoRouterManager';
import AutoRouterExecutor from '../auto-router-executor/AutoRouterExecutor';
import AutoRouterReport from '../auto-router-report/AutoRouterReport';

const defaultItemsData = [{
    id: 1,
    title: "Relatórios",
    description: "Detalhes de coletas",
    icon: images.side_nav_bar_report,
    selected: false,
    clicked: false,
    component: (<DetailedReportManagement />),
    path: "detailed-report"
}, {
    id: 2,
    title: "Agendamento massivo",
    description: "Atividades",
    icon: images.side_nav_bar_task_manager,
    selected: false,
    clicked: false,
    component: (<TaskManager />),
    path: "tasks",
    hidden: false
}, {
    id: 3,
    title: "Configurações de Roteirização",
    description: "Parâmetros",
    icon: images.side_nav_bar_settings,
    selected: false,
    clicked: false,
    component: (<AutoRouterManager />),
    path: "auto-router-manager",
    hidden: true
}, {
    id: 4,
    title: "Roteirização",
    description: "Executor",
    icon: images.side_nav_bar_router,
    selected: false,
    clicked: false,
    component: (<AutoRouterExecutor />),
    path: "auto-router-executor",
    hidden: true
}, {
    id: 5,
    title: "Relatório",
    description: "Relatório",
    icon: images.side_nav_bar_router,
    selected: false,
    clicked: false,
    component: (<AutoRouterReport />),
    path: "auto-router-report/:autoRouterInputId",
    hidden: true
}];

const styles = {
    body: {
        display: "inline",
        height: "calc(100% - 60px)"
    },
    sideNavBar: {
        display: "flex",
        margin: "10px",
        verticalAlign: "top",
        float: "left",
        height: "calc(100% - 20px)"
    },
    bodyItem: {
        display: "block",
        margin: "0px",
        verticalAlign: "top",
        overflow: "hidden",
        width: "auto",
        height: "100%"
    }
};

class Panel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedMenu: null,
            sideMenuItems: this.getSideMenuItemSelectedByPath(defaultItemsData),
            anyClicked: false
        };
    }

    getSideMenuItemSelectedByPath = (sideMenuItems) => {
        let path = window.location.pathname;

        sideMenuItems.forEach(item => {
            item.clicked = "/" + item.path === path;
        });

        sideMenuItems.forEach(item => {
            let selectedPath = path.split("/")[1];
            let itemPath = item.path.split("/")[0];

            item.clicked = itemPath === selectedPath;
        });

        return sideMenuItems;
    };

    componentDidMount() {
        if (this.props.history.location.pathname) {
            let selectedMenu = this.getComponentByPathName(this.props.history.location.pathname);

            let sideMenuItems = this.state.sideMenuItems;

            if (selectedMenu) {
                sideMenuItems.filter(m => m.id === selectedMenu.id).forEach(m => m.clicked = true);
            }

            this.setState({ selectedMenu, setAnyClicked: !!selectedMenu, sideMenuItems });
        }
    }

    sideMenuItemOnClick = (clickedItem) => {
        this.setState({ selectedMenu: clickedItem });
        if (clickedItem) {
            this.props.history.push("/" + clickedItem.path + "/");
        } else {
            this.props.history.push("/");
        }
        
    }

    getComponentByPathName = (path) => {
        return this.state.sideMenuItems.filter(item => {
            let selectedPath = path.split("/")[1];
            let itemPath = item.path.split("/")[0];

            return itemPath === selectedPath;
        })[0];
    };

    setAnyClicked = (anyClicked) => {
        this.setState({ anyClicked });
    };

    renderPageContentBodyButton = () => {

    }

    renderPageContentBody = () => {
        return this.state.selectedMenu ? this.state.selectedMenu.component : null;
    }

    renderPageContent = () => {
        if (this.state.selectedMenu === null)
            return null;

        return(
            <div style={styles.bodyItem}>
                {/* <PageContent
                    title={this.state.selectedMenu ? this.state.selectedMenu.title : null}
                    renderPageContentBody={this.renderPageContentBody} /> */}
                {this.state.selectedMenu.component}
            </div>
        );
    }

    renderRoutes = () => {
        return this.state.sideMenuItems.map(item => {
            return (
                <Route path={"/"+ item.path} key={item.path}>
                    <div style={styles.bodyItem}>
                        {item.component}
                    </div>
                </Route>
            );
        });
    }

    render() {
        return(
            <div style={{display: "flex", flex: 1, flexDirection: "column", height: "100%" }}>

                <TopNavBar />

                <div style={{
                    backgroundImage: this.state.anyClicked ? null : `url(${images.background_raizen})`,
                    backgroundRepeat: "round",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -10 }}>
                        <div style={{ position: "absolute", top: "95%", left: "95%", color: "white" }}>VERSÃO 0.6</div>
                    </div>

                <div className={"div-blur"}></div>

                <div style={styles.body}>

                    <div style={styles.sideNavBar}>
                        <SideNavBar
                            itemsData={this.state.sideMenuItems}
                            sideMenuItemOnClick={this.sideMenuItemOnClick}
                            selectedMenu={this.state.selectedMenu}
                            setAnyClicked={this.setAnyClicked} />
                    </div>

                    {this.renderRoutes()}
                    
                </div>

            </div>
        );
    }

}

export default withRouter(Panel);