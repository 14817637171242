import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback } from "./index";

export const FETCH_AUTHENTICATION = "fetch_authentication";


const baseAPI = process.env.REACT_APP_API_URL;


export const sendAuthentication = (params) => async dispatch => {

    await axios.post(baseAPI + "authentication?username=" + params.username + "&password=" + params.password)
        .then(
            (res) => {
                setSessionStorageAuthentication(res.data.token, res.data.permission, res.data.is_admin_role, null);
                setSessionStorageCognitoData();
                defaultSuccessCallback(dispatch, res, FETCH_AUTHENTICATION);
            },
            (error) => {
                setSessionStorageAuthentication("", "", "");
                setSessionStorageCognitoData();
                defaultErrorCallback(dispatch, error, FETCH_AUTHENTICATION);
            }
        );
}

export const sendCognitoAuthentication = (params) => async dispatch => {
    await axios.post(baseAPI + "authentication-adfs", params)
    .then(
        (res) => {
            setSessionStorageAuthentication(res.data.token, res.data.permission, res.data.is_admin_role, res.data.refresh_token);
            setSessionStorageCognitoData(params.code, params.client_id, params.cognitoAuthentication);
            defaultSuccessCallback(dispatch, res, FETCH_AUTHENTICATION);
        },
        (error) => {
            setSessionStorageAuthentication("", "", "");
            setSessionStorageCognitoData();
            defaultErrorCallback(dispatch, error, FETCH_AUTHENTICATION);
        }
    );
}

export const sendRefreshTokenCognito = async (error) => {

    if (!error || !error.response || error.response.status !== 401) {
        return;
    }

    if (!sessionStorage.getItem("code") || !sessionStorage.getItem("client_id") || 
        !sessionStorage.getItem("refresh_token") || !sessionStorage.getItem("cognito_authentication")) {
        return;
    }
    
    let params = {
        code: sessionStorage.getItem("code"),
        client_id: sessionStorage.getItem("client_id"),
        refresh_token: sessionStorage.getItem("refresh_token"),
        cognito_authentication: sessionStorage.getItem("cognito_authentication")
    };

    await axios.post(baseAPI + "authentication-adfs-refresh-token", params)
    .then(
        (res) => {
            sessionStorage.setItem("token", res.data.token);
        },
        (error) => {
            setSessionStorageAuthentication("", "", "");
        }
    );
}

const setSessionStorageAuthentication = (token, permission, admin_role, refresh_token) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("permission", permission);
    sessionStorage.setItem("admin_role", admin_role);
    sessionStorage.setItem("refresh_token", refresh_token);

    if (refresh_token) {
        sessionStorage.setItem("refresh_token", refresh_token);
    } else {
        sessionStorage.removeItem("refresh_token");
    }
}

const setSessionStorageCognitoData = (code, clientId, cognitoAuthentication) => {
    if (code) {
        sessionStorage.setItem("code", code);
    } else {
        sessionStorage.removeItem("code");
    }
    
    if (clientId) {
        sessionStorage.setItem("client_id", clientId);
    } else {
        sessionStorage.removeItem("client_id");
    }

    if (cognitoAuthentication) {
        sessionStorage.setItem("cognito_authentication", cognitoAuthentication);
    } else {
        sessionStorage.removeItem("cognito_authentication");
    }
    
}