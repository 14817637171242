export const images = {
    side_nav_bar_user_icon: require('./side-nav-bar-item/users-icon.svg'),
    side_nav_bar_enter_icon: require('./side-nav-bar-item/enter-icon.svg'),
    side_nav_bar_selected_enter_icon: require('./side-nav-bar-item/selected-enter-icon.svg'),
    top_nav_bar_strider: require('./top-nav-bar-item/LOGO_STRIDER.svg'),
    top_nav_bar_raizen: require('./top-nav-bar-item/LOGO_RAIZEN.png'),
    side_nav_bar_report: require('./side-nav-bar-item/detailed-report.svg'),
    side_nav_bar_task_manager: require('./side-nav-bar-item/task-manager.svg'),
    side_nav_bar_router: require('./side-nav-bar-item/router.svg'),
    side_nav_bar_settings: require('./side-nav-bar-item/settings.svg'),
    upload_icon: require('./commons/upload.png'),
    plus_icon: require('./commons/plus.png'),
    excel_icon: require('./commons/excel.svg'),
    login_image: require('./commons/login.png'),
    background_raizen: require('./commons/background_raizen.jpg'),
    map_marker_icon: require('./commons/map_marker.png'),
    map_icon: require('./commons/map.svg'),
    info_icon: require('./commons/info.svg'),
    filter_icon: require('./commons/filter.svg'),
    deadline_icon: require('./commons/deadline.svg'),
    request_sync: require('./commons/sync.svg')
};