import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";
import FileDownload from 'js-file-download';

export const FETCH_ALLOWED_FARMS = "fetch_allowed_farms";
export const FETCH_FARMS_USERS_FILES = "fetch_farms_users_files";


const baseAPI = process.env.REACT_APP_API_URL;


export const fetchAllowedFarms = () => async dispatch => {
    await axios.get(baseAPI + "farm/allowed", { headers: getDefaultHeader() } )

        .then(
            (res) => { defaultSuccessCallback(dispatch, res, FETCH_ALLOWED_FARMS) },
            (error) => { defaultErrorCallback(dispatch, error, FETCH_ALLOWED_FARMS) }
        );
}

export const downloadFarmUsers = () => async dispatch => {
    await axios.get(baseAPI + "users/download-users-permissions",
    
    { headers: { ...getDefaultHeader(), "Content-Disposition": "attachment;filename=Usuarios.csv" }}
        )
        .then(
            (res) => {
                defaultSuccessCallback(dispatch, res, FETCH_FARMS_USERS_FILES);
                FileDownload(res.data, "Usuarios.csv");
            },
            (error) => defaultErrorCallback(dispatch, error, FETCH_FARMS_USERS_FILES)
        );
}