import React, {Component} from 'react';
import  { Button, Modal, Upload, Spin, Icon, Table, notification} from 'antd';
import { connect } from "react-redux";
import * as autoRouterActions from "../../actions/autoRouterActions";
import { images } from '../../assets';

const styles = {
    sendWorker: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        color: '#ffffff',
    },
    modalDiv: {
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    uploadWorkerButton: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        color:  "#ffffff",
        fontSize: "12pt",
        marginBottom: "10pt"
    },
    tabHeaderDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        buttonDisabled: {
            backgroundColor: '#dddddd',
            borderColor: '#000',
            color: '#000',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        }
    },
    loading: {
        display: "inline-block",
        text: {
                display: "inline-block",
                marginLeft: "10px",
                marginRight: "20px"
            },
        icon: {
            display: "inline-block",
            color: "#782777",
            fontSize: 48
        }
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            maxWidth: "170px"
        },
        text: {
            margin: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            align: "center",
        },
    }
}


class WorkerManager extends Component {
    constructor(props) {
        super (props)

        this.state = {
            loading:true,
            workerData: [],
            createWorker: {
                buttonDisabled: true,
                fileList: [],
            },
            showUploadExcelWorkerModal:false,
            loadingExcelFile: false,
        }
    }

    componentDidMount() {
        this.getWorkerList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchWorkers !== this.props.fetchWorkers) {
            if (!this.props.fetchWorkers.errorStatus){
                this.setState({
                    workerData: this.props.fetchWorkers,
                    loading: false,
                })
            } else {
                this.setState({loading: false});
            }
        }
        if (prevProps.uploadExcelWorker !== this.props.uploadExcelWorker) {
            if (!this.props.uploadExcelWorker.errorStatus){
                this.getWorkerList();
                this.uploadNotification();
                this.setState({
                    loadingExcelFile: false,
                });
            } else {
                this.setState({loadingExcelFile: false});
            }
        }
        if (prevProps.removeAllWorkers !== this.props.removeAllWorkers) {
            if (!this.props.removeAllWorkers.errorStatus){
                this.getWorkerList();
                this.setState({
                    loading: false,
                });
                this.deleteNotification();
            } else {
                this.setState({loading: false});
            }
        }
    }

    getWorkerList = () => {
        this.setState({ loading: true });
        this.props.getWorkerList()
    }

    handleWorkerModalOk = () => {
        let params = {
            file: this.state.createWorker.fileList[0]
        }
        this.props.postExcelWorker(params)
        this.setState({
            showUploadExcelWorkerModal: false,
            loadingExcelFile: true,
            createWorker: {
                buttonDisabled: true,
                fileList: [],
            },
        });
    }

    handleWorkerModalCancel = () => {
        this.setState({
            showUploadExcelWorkerModal:false,
            createWorker: {
                buttonDisabled:true,
                fileList: []
            }
        })
    }

    confirmDelete = rowData => {
        Modal.confirm({
            title: 'Deseja excluir ?',
            content: 'Ao confirmar você excluirá todos os trabalhadores de ' + rowData.unity_name,
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.deleteAllWorkers(rowData.unity_id)
          });
    }

    uploadNotification = () =>{
        notification.success({
          message: 'Trabalhadores atualizados com sucesso',
          },
        );
    };

    deleteAllWorkers = unityId => {
        this.setState({loading: true});
        this.props.deleteAllWorkers(unityId);
    }

    deleteNotification = () =>{
        notification.open({
          message: 'Todos os trabalhadores da unidade foram excluídos ',
          icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          },
        );
      };

    showUploadExcelWorkerModal = () => {
        this.setState({
            showUploadExcelWorkerModal: true
        });
    }

    renderUploadExcelWorkerModalContent = () => {
        const {fileList} = this.state.createWorker;
        const props = {
            onRemove: file => {
              this.setState(state => {
                const index = state.createWorker.fileList.indexOf(file);
                const newFileList = state.createWorker.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    createWorker: {
                        ...state.createWorker,  
                        fileList: newFileList,
                    }
                };
              });
            },
            beforeUpload: file => {
              this.setState(state => ({
                createWorker: {
                    ...state.createWorker,
                    fileList: [...state.createWorker.fileList, file],
                }
              }));
              return false;
            },
            fileList,
        };
        return (
            <div>
                <p>Escolha um arquivo xls válido</p>
                <Upload {...props}>
                    <Button id='uploadButton' style={styles.upload.button} size={'large'} type="primary" onClick={() => {}}>
                        <img src={images.upload_icon} alt={"upload_icon"}></img>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                </Upload>
            </div>
        );
    }

    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56}} spin />} />
            </div>
        );
    }

    renderLoadingModal = () => {
        return (
            <Modal
                title="Verificando arquivo..."
                visible={this.state.loadingExcelFile}
                closable={false}
                footer={[]}
            >
                <div styles={styles.modalDiv}>
                    <p>Os campos do arquivo enviado estão em verificação.</p>
                    <p>Por favor aguarde...</p>
                    <Spin indicator={<Icon type="loading" style={styles.loading.icon} spin />} ></Spin>
                </div>
            </Modal>

        );
    }

    renderActions = (rowData) => {
        return (
            <div style={styles.actions.div}>
                <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.confirmDelete(rowData)}>
                    <Icon type="delete" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Excluir</p>
                </Button>
            </div>
        );
    }


    renderTable = () => {
        const columns = [
            {
                title: "ID",
                dataIndex: "unity_id",
                align: "left",
                key: "unity_id"
            },
            {
                title: "Unidade",
                dataIndex: "unity_name",
                align: "left",
                key: "unity_name"
            },
            {
                title: "Quantidade de condutores",
                dataIndex: "num_total_leader_workers",
                align: "left",
                key: "num_total_leader_workers"
            },
            {
                title: "Total de trabalhadores",
                dataIndex: "num_total_workers",
                align: "left",
                key: "num_total_workers"
            },
            {
                title: "Ações",
                width: "120px",
                dataIndex: "actions",
                fixed: 'right',
                align: "left",
                key: "actions",
                render: (text, record) => this.renderActions(record)
            }
        ];
        
        if (this.state.loading) {
            return this.renderLoading();
        }

        return(
            <div>
                {this.renderTabHeader()}
                <Table 
                    columns={columns}
                    dataSource={this.state.workerData}
                    scroll={{ x: true, y: false }}
                    style={{paddingBottom: 0}} 
                    pagination={{
                        pageSize: 10
                    }}
                    />
                {this.renderUploadExcelWorkerModal()}
                {this.renderLoadingModal()}
            </div>
        );
    }

    renderTabHeader = () => {
        return (
            <div style={styles.tabHeaderDiv}>
                <h2>Trabalhadores</h2>
                <Button style={styles.uploadWorkerButton} size={'large'} type="primary" onClick={() => this.showUploadExcelWorkerModal()}>
                    Adicionar trabalhadores
                </Button>
            </div>
        )
    }

    renderUploadExcelWorkerModal = () => {
        return (
            <Modal
                title="Adicionar trabalhador"
                visible={this.state.showUploadExcelWorkerModal}
                onOk={this.handleWorkerModalOk}
                onCancel={this.handleWorkerModalCancel}
                okButtonProps={{style: this.state.createWorker.fileList.length === 0 ? {...styles.sendWorker, opacity: 0.5} : styles.sendWorker, disabled: this.state.createWorker.fileList.length === 0 }}
            >
                {this.renderUploadExcelWorkerModalContent()}
            </Modal>
        );
    }

    render() {
        return(
            <div>
                {this.renderTable()}
            </div>
        );
    }

}

function mapStateToProps({ fetchWorkers, uploadExcelWorker, removeAllWorkers}) {
    return { fetchWorkers, uploadExcelWorker, removeAllWorkers};
}

export default connect(mapStateToProps, { ...autoRouterActions  })(WorkerManager);
