import React, { Component } from 'react';
import { images } from '../../assets';

const styles = {
    box: {
        borderRadius: "12px",
        boxShadow: "0px 4px 20px rgba(67, 20, 125, 0.12)",
        padding: "10px",
        margin: "15px",
        cursor: "pointer",
        backgroundColor: "white"
    },
    iconBox: {
        width: "40px",
        height: "40px",
        borderRadius:"8px",
        backgroundColor:"#FFF3FF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    icon: {
        width: "24px",
        height: "24px",
    },
    description: {
        display: "inline"
    },
    enterIconDiv: {
        display: "inline",
        float: "right"
    },
    enterIcon: {
        width: "22px",
        marginLeft: "10vw"
    },
    title: {
        display: "inline",
        verticalAlign: "super",
        marginLeft: "8px",
        fontSize: "16px",
        fontWeight: 500,
    },
    top: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px"
    },
    bottom: {
        marginBottom: "15px"
    }
};

class SideNavBarItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            title: null,
            description: null,
            icon: null,
            selected: false,
            clicked: false,
            anyClicked: false
        };

    }

    componentDidMount() {
        this.setState({
            id: this.props.id,
            title: this.props.title,
            description: this.props.description,
            icon: this.props.icon,
            selected: this.props.selected,
            clicked: this.props.clicked,
            anyClicked: this.props.anyClicked
        });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id });
        }
        if (prevProps.title !== this.props.title) {
            this.setState({ title: this.props.title })
        }
        if (prevProps.description !== this.props.description) {
            this.setState({ description: this.props.description });
        }
        if (prevProps.icon !== this.props.icon) {
            this.setState({ icon: this.props.icon });
        }
        if (prevProps.selected !== this.props.selected) {
            this.setState({ selected: this.props.selected });
        }
        if (prevProps.clicked !== this.props.clicked) {
            this.setState({ clicked: this.props.clicked });
        }
        if (prevProps.anyClicked !== this.props.anyClicked) {
            this.setState({ anyClicked: this.props.anyClicked });
        }
    }

    componentOnClick = () => {
        if (this.props.onClick)
            this.props.onClick(this.state.id);
    };

    componentOnMouseEnter = () => {
        if (this.props.onMouseHover)
            this.props.onMouseHover(this.state.id);
    }

    componentOnMouseLeave = () => {
        if (this.props.onMouseHover)
            this.props.onMouseHover(null);        
    }

    getSelectedLeftMargin = () => {
        if (this.state.anyClicked) {
            return this.state.clicked ? "20px" : null;
        } else {
            return this.state.selected ? "20px" : null;
        }
    };

    getSelectedRightMargin = () => {
        if (this.state.anyClicked) {
            return !this.state.clicked ? "20px" : null;
        } else {
            return !this.state.selected ? "20px" : null;
        }
    };

    getEnterImageByAnyClicked = () => {
        if (this.state.anyClicked) {
            return this.state.clicked ? images.side_nav_bar_selected_enter_icon : images.side_nav_bar_enter_icon;
        } else {
            return this.state.selected ? images.side_nav_bar_selected_enter_icon : images.side_nav_bar_enter_icon;
        }
    };

    renderTop = () => {
        return (
            <div style={styles.top}>
                <div style={styles.iconBox}>
                    <img src={this.state.icon} style={styles.icon} alt={"icon"}/>
                </div>
                <div style={!this.state.clicked ? styles.title : {...styles.title, color: "#782777"}}>
                    {this.state.title}
                </div>                
            </div>
        );
    };

    renderBottom = () => {
        return(
            <div style={styles.bottom}>
                <div style={styles.description}>
                    { this.state.description }
                </div>

                <div style={styles.enterIconDiv}>
                    <img src={this.getEnterImageByAnyClicked()} style={styles.enterIcon} alt={"enterIcon"}/>
                </div>
            </div>
        );
    };

    render () {
        return (
            <div style={{ marginLeft: this.getSelectedLeftMargin(), marginRight: this.getSelectedRightMargin() }}>
                <div
                    style={styles.box}
                    onClick={this.componentOnClick}
                    onMouseEnter={this.componentOnMouseEnter}
                    onMouseLeave={this.componentOnMouseLeave}>

                    { this.renderTop() }
                    { this.renderBottom() }

                </div>
                
            </div>
        );
    }
}

export default SideNavBarItem;