import React, { Component } from 'react';
import "./scrollStyle.css";

const styles = {
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexFlow: "row wrap",
        alingItens: 'center',
        paddingBottom: "24px"
    },
    title: {
        fontSize: "20pt",
        color: "#2F3031"
    },
    body: {
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "30px",
        height: "calc(100% - 64px)",
        overflow: "scroll"
    },
    content: {
        backgroundColor: "#E5E5E5",
        padding: "20px",
        height: "100%"
    }
};

class PageContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Implement getTitle() -> string
     * Implement renderPageContentBody -> Component
     * Implement renderTopButton -> ButtonComponent
     */

    renderBody = () => {
        return(
            <div className="bodyDiv" style={styles.body}>
                <div>
                    {this.renderPageContentBody()}
                </div>
            </div>
        );
    }

    renderTop = () => {
        return(
            <div style={styles.top}>
                <div style={styles.title}>
                    <b>{this.getTitle()}</b>
                </div>
                <div>
                    {this.renderTopButton()}
                </div>
            </div>
        );
    };

    render () {
        return(
            <div style={styles.content}>
                {this.renderTop()}
                {this.renderBody()}
            </div>
        );
    }
}

export default PageContent;