import React, { Component } from "react"

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area, AreaChart } from 'recharts';
import moment from "moment";

const styles = {
    chartDiv: {
        paddingBottom: "24px"
    },
    metricsTableTh: {
        textAlign: "center",
        border: "solid black 1px",
    },
    metricsTableThSub: {
        textAlign: "center",
        minWidth: "50px",
        border: "solid black 1px",
    },
    metricsTableTd: {
        textAlign: "center",
        border: "solid black 1px"
    }
}

const MOMENT_FORMAT = "DD-MM-YYYY";

class AutoRouterReportCharts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            autoRouterChartReportData: null,
            autoRouterMetricsData: null,
            autoRouterChartData: null
        }
    }

    componentDidMount() {
        this.setAutoRouterReportData(this.props.autoRouterChartReportData);
    }

    componentDidUpdate() {
        if (this.state.autoRouterChartReportData !== this.props.autoRouterChartReportData)
            this.setAutoRouterReportData(this.props.autoRouterChartReportData);
    }

    setAutoRouterReportData = data => {
        const metrics = this.getMetricsData(data);
        const charts = this.getChartsData(data);
        this.setState({ 
            autoRouterChartReportData: data,
            autoRouterMetricsData: metrics,
            autoRouterChartData: charts
        });
    }

    getMetricsData = (inputData) => {
        if (!inputData) 
            return null;

        return inputData["metrics"];
    }

    getChartsData = (inputData) => {
        if (!inputData) 
            return null;

        return inputData["charts"];
    }

    getRandomColor = () => {
        const randomColor = Math.floor(Math.random()*16777215).toString(16);
        return '#' + randomColor;
    }

    renderPriorityExecutedTasksChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        let chartData = this.state.autoRouterChartData["priority_executed_tasks_rel"] ?
            this.state.autoRouterChartData["priority_executed_tasks_rel"] : this.state.autoRouterChartData["priority_executed_tasks"];

        const minutesPerVehicleData = Object.entries(chartData["priority"]).map(row => ({
            name: row[0],
            value: (row[1] * 100).toFixed(2)
        }));

        return (
            <div style={styles.chartDiv}>
                <h1>Qntd tarefas executadas por prioridade (%) </h1>
                <BarChart key="priorityExecutedTasksChart"
                width={700}
                height={400}
                data={minutesPerVehicleData}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 30,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis unit="%" domain={[0, 100]}/>
                <Tooltip/>
                <Legend wrapperStyle={{bottom: "20px"}}/>
                <Bar name="Tarefas executadas (%)" dataKey="value" barSize={20} fill="#8884d8" />
                </BarChart>
            </div>
          );
    }

    minutesPerVehicleChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const minutesPerVehicleData = Object.entries(this.state.autoRouterChartData["idle_minutes_per_vehicle"]["perc. idle time"]).map( row => ({
            name: row[0],
            value: row[1].toFixed(2)
        }));
        return (
            <div style={styles.chartDiv}>
                <h1>Tempo de ociosidade por veículo</h1>
                <BarChart key="minutesPerVehicleChart"
                width={700}
                height={400}
                data={minutesPerVehicleData}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 30,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]}/>
                <Tooltip/>
                <Legend wrapperStyle={{bottom: "20px"}}/>
                <Bar name="Percentual de ociosidade (%)" dataKey="value" barSize={20} fill="#8884d8" />
                </BarChart>
            </div>
          );
    }

    minutesPerDayChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const minutesPerDayData = Object.entries(this.state.autoRouterChartData["idle_minutes_per_day"]["idle time"]).map( row => ({
            name: row[0],
            value: row[1].toFixed(2)
        }));

        return (
            <div style={styles.chartDiv}>
                <h1>Tempo de ociosidade por dia</h1>
                <BarChart key="minutesPerDayChart"
                    width={700}
                    height={400}
                    data={minutesPerDayData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 30,
                    }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]}/>
                <Tooltip/>
                <Legend wrapperStyle={{bottom: "20px"}}/>
                <Bar name="Percentual de ociosidade (%)" dataKey="value" barSize={20} fill="#8884d8" />
                </BarChart>
            </div>
        );
    }

    burndownPriorityPerDayHaChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const formatedData = this.formatData(this.state.autoRouterChartData["burndown_priority_per_day_ha"])
        const burndownPriorityPerDayHaData = this.generateBurndownData(formatedData);

        return(
            <div style={styles.chartDiv}>
                <h1>Hectares cobertos por prioridade</h1>
                <AreaChart key="burndownPriorityEndDateChart"
                    width={700}
                    height={400}
                    data={burndownPriorityPerDayHaData}
                    margin={{
                        top: 10, right: 30, left: 30, bottom: 30
                    }}
                >
                    <Legend
                        verticalAlign="bottom"
                        wrapperStyle={{
                            bottom: 20,
                            left: 60
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {this.drawAreaRecharts(burndownPriorityPerDayHaData)}
                </AreaChart>
            </div>
        );
    }

    burndownExecutedPerDayHaChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const formatedData = this.formatData(this.state.autoRouterChartData["burndown_executed_per_day_ha"]);
        const burndownExecutedPerDayHaData = this.generateBurndownData(formatedData);

        return(
            <div style={styles.chartDiv}>
                <h1>Hectares cobertos por dia</h1>
                <AreaChart key="burndownExecutedEndDateChart"
                    width={700}
                    height={400}
                    data={burndownExecutedPerDayHaData}
                    margin={{
                        top: 10, right: 30, left: 30, bottom: 30
                    }}
                >
                    <Legend
                        verticalAlign="bottom"
                        wrapperStyle={{
                            bottom: 20,
                            left: 60
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis unit="ha"/>
                    <Tooltip />
                    {this.drawAreaRecharts(burndownExecutedPerDayHaData)}
                </AreaChart>
            </div>
        );
    }

    burndownExecutedEndDateChart = () => {
        if (!this.state.autoRouterChartData)
            return null;
  
        const burndownEndDateData = this.generateBurndownData(this.state.autoRouterChartData["burndown_executed_per_day"]);

        return(
            <div style={styles.chartDiv}>
                <h1>Execução por data de vencimento</h1>
                <AreaChart key="burndownExecutedEndDateChart"
                    width={700}
                    height={400}
                    data={burndownEndDateData}
                    margin={{
                        top: 10, right: 30, left: 30, bottom: 30
                    }}
                >
                    <Legend
                        verticalAlign="bottom"
                        wrapperStyle={{
                            bottom: 20,
                            left: 60
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {this.drawAreaRecharts(burndownEndDateData)}
                </AreaChart>
            </div>
        );
    }

    burndownExecutedPriorityChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const burndownPriorityData = this.generateBurndownData(this.state.autoRouterChartData["burndown_priority_per_day"]);

        return(
            <div style={styles.chartDiv}>
                <h1>Execução por prioridade</h1>
                <AreaChart key="burndownExecutedPriorityChart"
                    width={700}
                    height={400}
                    data={burndownPriorityData}
                    margin={{
                        top: 10, right: 30, left: 30, bottom: 30
                    }}
                >
                    <Legend
                        verticalAlign="bottom"
                        layout="horizontal"
                        align="center"
                        wrapperStyle={{
                            bottom: 20,
                            left: 60
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {this.drawAreaRecharts(burndownPriorityData)}
                </AreaChart>
            </div>
        );
    }

    formatData = data => {
        let result = {};

        Object.entries(data).forEach(entry => {
            if (Array.isArray(entry[1])) {
                result[entry[0]] = entry[1].map(value => value.toFixed(2));
            } else if (typeof entry[1] === "object") {
                result[entry[0]] = {};

                Object.entries(entry[1]).forEach(_entry => {
                    result[entry[0]][_entry[0]] = _entry[1].toFixed(2);
                });
            } else {
                result[entry[0]] = entry[1].toFixed(2);
            }
        });

        return result;
    }

    generateBurndownData = (dataList) => {
        let burndownData = []
        for (const [iKey] of Object.entries(dataList)) {
            for (const [jKey, jValue] of Object.entries(dataList[iKey])) {
                let inserted = burndownData.filter(line => line.name === jKey)[0]
                if (!inserted) {
                    let line = { name: jKey, [iKey]: jValue};
                    burndownData.push(line);
                }
                else{
                    inserted[[iKey]] = jValue;
                }
            }
        }
        
        if (burndownData.length && burndownData[0].name && moment(burndownData[0].name, MOMENT_FORMAT).isValid()) {
            burndownData = burndownData.sort((d1, d2) => {
                let date1 = moment(d1.name, MOMENT_FORMAT);
                let date2 = moment(d2.name, MOMENT_FORMAT);

                return date1.unix() - date2.unix();
            });
        }

        return burndownData;
    }

    drawAreaRecharts = dataList => {
        let keys = Object.keys(dataList[0]).filter(key => key !== "name");
        
        return keys.map(key => { 
            let color = this.getRandomColor()
            return(
                <Area type="monotone" dataKey={key} stackId="1" stroke={color} 
                        fill={color} />
            )}
        )
    }

    perVehicleChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const perVehicleData = Object.keys(this.state.autoRouterChartData["per_day_vehicle"]["minutes"]).map( vehicle => ({
            name: vehicle,
            horas: this.state.autoRouterChartData["per_day_vehicle"]["minutes"][vehicle].toFixed(0),
            area: this.state.autoRouterChartData["per_day_vehicle"]["area_covered_ha / 10"][vehicle].toFixed(2),
            distancia: this.state.autoRouterChartData["per_day_vehicle"]["distance"][vehicle].toFixed(2),
            quantidade: this.state.autoRouterChartData["per_day_vehicle"]["quantity"][vehicle]
        }));

        return(
            <div style={styles.chartDiv}>
                <h1>Por veículo</h1>
                <BarChart key="perVehicleChart"
                    width={700}
                    height={400}
                    data={perVehicleData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 20,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend
                        verticalAlign="bottom"
                        layout="horizontal"
                        align="center"
                        wrapperStyle={{
                            bottom: 20
                        }}
                    />
                    <Bar dataKey="horas" fill={this.getRandomColor()} />
                    <Bar dataKey="area" name="area(ha) / 10" fill={this.getRandomColor()} />
                    <Bar dataKey="distancia" name="distância(km)" fill={this.getRandomColor()} />
                    <Bar dataKey="quantidade" fill={this.getRandomColor()} />
                </BarChart>
            </div>
        );
    }

    perDayChart = () => {
        if (!this.state.autoRouterChartData)
            return null;

        const perDayData =  Object.keys(this.state.autoRouterChartData["per_day"]["minutes"]).map( day => ({
            name: day,
            horas: this.state.autoRouterChartData["per_day"]["minutes"][day].toFixed(0),
            area: this.state.autoRouterChartData["per_day"]["area_covered_ha / 10"][day].toFixed(2),
            distancia: this.state.autoRouterChartData["per_day"]["distance"][day].toFixed(2),
            quantidade: this.state.autoRouterChartData["per_day"]["quantity"][day]
        }));
        return(
            <div style={styles.chartDiv}>
                <h1>Por dia</h1>
                <BarChart key="perDayChart"
                    width={700}
                    height={400}
                    data={perDayData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend
                        verticalAlign="bottom"
                        wrapperStyle={{
                            bottom: 20
                        }}
                    />
                    <Bar dataKey="horas" fill={this.getRandomColor()} />
                    <Bar dataKey="area" name="area(ha) / 10" fill={this.getRandomColor()} />
                    <Bar dataKey="distancia" name="distância(km)" fill={this.getRandomColor()} />
                    <Bar dataKey="quantidade" fill={this.getRandomColor()} />
                </BarChart>
            </div>
        );
    }

    renderCharts = () => {
        return(
            <div>
                <div key="chartsRow1" style={{display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap"}}>
                    {this.burndownExecutedPerDayHaChart()}
                    {this.burndownPriorityPerDayHaChart()}
                </div>
                <div key="chartsRow2" style={{display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap"}}>
                    {this.burndownExecutedEndDateChart()}
                    {this.burndownExecutedPriorityChart()}
                </div>
                <div key="chartsRow3" style={{display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap"}}>
                    {this.renderPriorityExecutedTasksChart()}
                    {this.perDayChart()}
                </div>
                <div key="chartsRow4" style={{display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap"}}>
                    {this.perVehicleChart()}
                    {this.minutesPerDayChart()}
                </div>
                <div key="chartsRow5" style={{display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap"}}>
                    {this.minutesPerVehicleChart()}
                </div>
            </div>
        );
    }

    renderMetrics = () => {
        if (sessionStorage.getItem("permission") !== "ADMIN") {
            return null;
        }

        return(
            <div key="metrics" style={{display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap", paddingBottom: "20px"}}>
                <table style={{width: "90%", bortder: "solid black 1px"}}>
                    <thead>
                    <tr>
                        <th style={styles.metricsTableTh}>Métricas</th>
                        <th style={styles.metricsTableTh} colSpan='2'>TA</th>
                        <th style={styles.metricsTableTh} colSpan='2'>TMT</th>
                        <th style={styles.metricsTableTh} colSpan='2'>CP</th>
                        <th style={styles.metricsTableTh} colSpan='2'>VDO</th>
                        <th style={styles.metricsTableTh} colSpan='2'>TMO</th>
                        <th style={styles.metricsTableTh} colSpan='2'>HC</th>
                        <th style={styles.metricsTableTh} colSpan='1'> Distância </th>
                    </tr>
                    <tr>
                        <th style={styles.metricsTableThSub}>Tarefas</th>
                        <th style={styles.metricsTableThSub}>Abs</th>
                        <th style={styles.metricsTableThSub}>Rel(%)</th>
                        <th style={styles.metricsTableThSub}>Abs</th>
                        <th style={styles.metricsTableThSub}>Rel(%)</th>
                        <th style={styles.metricsTableThSub}>Abs</th>
                        <th style={styles.metricsTableThSub}>Rel(%)</th>
                        <th style={styles.metricsTableThSub}>Abs</th>
                        <th style={styles.metricsTableThSub}>Rel(%)</th>
                        <th style={styles.metricsTableThSub}>Abs</th>
                        <th style={styles.metricsTableThSub}>Rel(%)</th>
                        <th style={styles.metricsTableThSub}>Abs</th>
                        <th style={styles.metricsTableThSub}>Rel(%)</th>
                        <th style={styles.metricsTableThSub}>Abs(km)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["total_tasks_in_input"]}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["ta_abs"]}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {(this.state.autoRouterMetricsData["response"]["ta_rel"] * 100).toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["tmt_abs"].toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {(this.state.autoRouterMetricsData["response"]["tmt_rel"] * 100).toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["cp_abs"]}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {(this.state.autoRouterMetricsData["response"]["cp_rel"] * 100).toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["vdo_abs"]}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {(this.state.autoRouterMetricsData["response"]["vdo_rel"] * 100).toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["tmo_abs"].toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {(this.state.autoRouterMetricsData["response"]["tmo_rel"] * 100).toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["hc_abs"].toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {(this.state.autoRouterMetricsData["response"]["hc_rel"] * 100).toFixed(2)}
                        </td>
                        <td style={styles.metricsTableTd}>
                            {this.state.autoRouterMetricsData["response"]["data_info"] ? (this.state.autoRouterMetricsData["response"]["data_info"]["total_traveled_distance"]).toFixed(2) : "N/A"}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        if (this.state.autoRouterChartData && this.state.autoRouterMetricsData) {
            return(
                <div style={styles.chartDiv}>
                    {this.renderMetrics()}
                    {this.renderCharts()}
                </div>
            );
        }
        else {
            return(
                <div><center><h3>Sem dados de Métricas/Gráficos.</h3></center></div>
            );
        }
    }
}

export default AutoRouterReportCharts;