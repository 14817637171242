import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";
import { sendRefreshTokenCognito } from './authenciationActions';


export const GENERATE_DETAILED_REPORT = "generate_detailed_report";
export const GENERATE_CONSOLIDATED_REPORT = "generate_consolidated_report";
export const DOWNLOAD_REPORT = "download_report";
export const GENERATE_REPORT_URL = "generate_report_url";
export const SYNC_REQUEST_RESULT = "sync_request_result";
export const SYNC_REQUEST_LAST = "sync_request_last";
export const SEND_REPORT_REQUEST = "send_report_request";

const baseAPI = process.env.REACT_APP_API_URL;


const convertParamsToQueryString = (params) => {
    let farmsId = params.farmsId.map(farm => `farm-ids=${farm}`).join('&');
    let issuesId = params.issuesId.map(issue => `issue-ids=${issue}`).join('&');
    let issueTypeIds = params.issueTypeIds.map(issueType => `issue-type-id=${issueType}`).join('&');

    let startDate = params.startDate;
    let endDate = params.endDate;
    let identifier = params.identifier;
    let forConsolidate = params.forConsolidate;
    let reportType = params.reportType;

    let queryString = farmsId;
    if (issuesId) {
        queryString += "&" + issuesId;
    }
    if (issueTypeIds) {
        queryString += "&" + issueTypeIds;
    }
    if (startDate) {
        queryString += "&start-date=" + startDate;
    }
    if (endDate) {
        queryString += "&end-date=" + endDate;
    }
    if (identifier) {
        queryString += "&identifier=" + identifier;
    }
    if (forConsolidate) {
        queryString += "&forConsolidate=" + forConsolidate;
    }
    if (reportType) {
        queryString += "&report-type=" + reportType;
    }

    return queryString;
}

export const fetchGenerateDetailedReportNew = (params, already_refresh) => async dispatch => {
    
    await axios.get(baseAPI + "detailed-report/generate-report-new?" + convertParamsToQueryString(params)
    , { headers: getDefaultHeader() })
        .then(
            (res) => {
                res.data.farmId = params.farmsId[0];
                res.data.startDate = params.startDate;
                defaultSuccessCallback(dispatch, res, GENERATE_DETAILED_REPORT);
            },
            async (error) => {
                if (!already_refresh && error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                    fetchGenerateDetailedReportNew(params, true)(dispatch);
                } else {
                    if (params.farmName) {
                        if (error.response && error.response.title) {
                            error.response.title = params.farmName + " - " + error.response.title;
                        }
                    }
                    
                    defaultErrorCallback(dispatch, error, GENERATE_DETAILED_REPORT, params.remainingAttemps);
                }
            }
        );
}

export const fetchGenerateConsolidatedReport = (params, already_refresh) => async dispatch => {
    await axios.get(baseAPI + "detailed-report/generate-consolidated-report?" + convertParamsToQueryString(params)
    , { headers: getDefaultHeader() })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, GENERATE_CONSOLIDATED_REPORT),
            async (error) => {
                if (!already_refresh && error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                    fetchGenerateConsolidatedReport(params, true)(dispatch);
                } else {
                    defaultErrorCallback(dispatch, error, GENERATE_CONSOLIDATED_REPORT);
                }                
            }
        );
}

export const consolidateReport = (identifier, already_refresh) => async dispatch => {
    await axios.get(baseAPI + "detailed-report/consolidate?identifier=" + identifier
    , { headers: getDefaultHeader() })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, GENERATE_CONSOLIDATED_REPORT),
            async (error) => {
                if (!already_refresh && error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                    consolidateReport(identifier, true);
                } else {
                    defaultErrorCallback(dispatch, error, GENERATE_CONSOLIDATED_REPORT);
                }
            }
        );
}

export const generateReportDownloadUrl = (identifier, already_refresh) => async dispatch => {
    await axios.get(baseAPI + "detailed-report/download-generated-report/" + identifier,
    
    { headers: getDefaultHeader() })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, GENERATE_REPORT_URL),
            async (error) => {
                if (!already_refresh && error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                    generateReportDownloadUrl(identifier, true)(dispatch);
                } else {
                    defaultErrorCallback(dispatch, error, GENERATE_REPORT_URL);
                }
            }
        );
}

export const requestSync = () => async dispatch => {
    await axios.get(baseAPI + "report/sync_request",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, SYNC_REQUEST_RESULT),
            async (error) => {
                if (error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                } else {
                    defaultErrorCallback(dispatch, error, SYNC_REQUEST_RESULT);
                }
            }
        );
}

export const getLastSyncRequest = () => async dispatch => {
    await axios.get(baseAPI + "report/sync_request/last",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, SYNC_REQUEST_LAST),
            async (error) => {
                if (error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                } else {
                    defaultErrorCallback(dispatch, error, SYNC_REQUEST_LAST);
                }
            }
        )
}

export const sendCreateReportRequest = (reportRequest, already_refresh) => async dispatch => {
    // TODO - fix the issueTypeIds
    await axios.post(baseAPI + "report/report-request?" + convertParamsToQueryString(reportRequest), {}
    , { headers: getDefaultHeader() })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, SEND_REPORT_REQUEST),
            async (error) => {
                if (!already_refresh && error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                    sendCreateReportRequest(reportRequest, true)(dispatch);
                } else {
                    defaultErrorCallback(dispatch, error, SEND_REPORT_REQUEST);
                }                
            }
        );
}