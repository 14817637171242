import React from 'react';
import PageContent from '../page-content/PageContent';

import  { Button, DatePicker, Divider, Select, Row, Col, Spin, Icon, Modal, Progress, Tabs, Checkbox } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import * as detailedReportActions from "../../actions/detailedReportActions";
import * as farmActions from "../../actions/farmActions";
import * as issueActions from "../../actions/issueActions";
import { connect } from "react-redux";
import { images } from '../../assets';
import ReportHistoric from "./ReportHistoric"
import { isAdmin, isProtectorAdmin } from '../../permission'

import moment from 'moment';

const { Option } = Select;
const { TabPane } = Tabs

const MAX_ATTEMPT_TO_GENERATE_REPORT = 3;

const styles = {
    datePicker: {
        display: "inline-block",
    },
    select: {
        width: "100%",
        zIndexOffset: '1001',
    },
    label: {
        fontSize: "15px",
        marginBottom: "10px"
    },
    buttonDiv: {
        display: 'flex'
    },
    statusSync: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color: "#2f3031",
        marginRight: "20px",
        cursor: 'pointer'
    },
    sync: {
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        icon: {
            height: '16px'
        },
        icon_animate: {
            height: '16px',
            "animation": "rotation 2s infinite linear"
        }
    },
    button: {
        generateReport: {
            marginLeft: '16px',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "#782777",
            borderColor: "#782777"
        },
        generateText: {
            marginLeft: 10,
            marginBottom: 0
        },
        downloadReport: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
        downloadText: {
            margin: 0,
            color: "#782777",
            fontSize: "12pt",
        },
        downloadDiv: {
            paddingLeft: '24px'
        },
        downloadPsText: {
            fontSize: '8pt',
            margin: 0,
            color: '#782777',
            borderWidth: 2,
            padding: 0
        }
    },
    loading: { 
        display: "inline-block", 
        marginRight: "30px" 
    },
    tab: {
        margin:"-30px",
    },
    tabPane: {
        paddingLeft: "30px",
        paddingRight: "30px"  
    }
};

const { RangePicker } = DatePicker;

const dateFormat = 'DD-MM-YYYY';
const americanFormatDate = 'YYYY-MM-DD';

const REPORT_TAB_KEY = "REPORT";
const HISTORIC_TAB_KEY = "REPORT-HISTORIC";

const DETAILED_REPORT_TYPE = "DETAILED_COLLECTION";
const CONSOLIDATED_REPORT_TYPE = "CONSOLIDATED_COLLECTION";
const CROP_LOSSES = "CROP_LOSSES";

const SHOW_GENERATE_ASYNC_REPORT = false;
const MAX_RANGE_OF_DAYS_TO_REPORT = 60;

class DetailedReportManagement extends PageContent {

    constructor(props) {
        super(props);

        this.state = {
            title: null,
            startDate: null,
            endDate: null,
            dateValue: [],
            hackDateValue: [],
            dates: [],
            selectedIssueTypeId: [],
            listIssueId: [],
            farms: [],
            issues: [],
            listFarmId: [],
            initialLoadingFarm: true,
            initialLoadingIssue: true,
            initialLoadingIssueType: true,
            loading: false,
            successfullyReportGenerated: false,
            generatedReportInfo: null,
            downloadProgress: false,
            reportType: DETAILED_REPORT_TYPE,
            paramsToGenerateReport: null,
            reportDetails: null,
            periodIntervalMonths: 3,
            consolidating: false,
            generatingUrl: false,
            downloadUrl: null,
            isRunningSyncRequest: false,
            generateAssync: true,
            activeTabKey: REPORT_TAB_KEY,
            lastTimeSentRequest: null
        }
    }

    componentDidMount() {
        this.setState({ title: "Relatórios" });
        this.props.fetchAllowedFarms();
        this.props.fetchIssues();
        this.props.fetchIssueTypes();
        if (isAdmin()) {
            this.props.getLastSyncRequest();
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.allowedFarms !== this.props.allowedFarms) {
            if (!this.props.allowedFarms.errorStatus) {
                let farms = this.props.allowedFarms;
                this.setState({ farms });
            }
            this.setState({ initialLoadingFarm: false });
        }
        if (prevProps.generateDetailedReport !== this.props.generateDetailedReport) {

            if (this.props.generateDetailedReport.errorStatus !== true) {
                let generatedReportInfo = this.props.generateDetailedReport;
                let paramsToGenerateReport = this.state.paramsToGenerateReport;

                if (generatedReportInfo.identifier) {
                    let identifier = generatedReportInfo.identifier.split(".")[0];
                    paramsToGenerateReport.identifier = identifier;
                } else if (this.state.generatedReportInfo) {
                    generatedReportInfo.identifier = this.state.generatedReportInfo.identifier;
                }
                this.setState({ generatedReportInfo, paramsToGenerateReport });
                this.generateDetailedReportNew(this.props.generateDetailedReport.farmId, MAX_ATTEMPT_TO_GENERATE_REPORT, this.props.generateDetailedReport.startDate);
            } else {
                if (this.state.reportDetails.currentAttempt > 0) {
                    let farmIdToGenerate = this.state.reportDetails ? this.state.reportDetails.currentFarmId : null;
                    let startDateToGenerate = this.state.reportDetails ? this.state.reportDetails.startDate : null;
                    this.generateDetailedReportNew(farmIdToGenerate, this.state.reportDetails.currentAttempt - 1, startDateToGenerate);
                } else {
                    this.setState({ loading: false });
                }
            }            
        }

        if (prevProps.generateConsolidatedReport !== this.props.generateConsolidatedReport) {
            if (this.props.generateConsolidatedReport.errorStatus !== true) {
                this.setState({ generatedReportInfo: this.props.generateConsolidatedReport });
                this.setState({ consolidating: false });
                this.generateDownloadUrl(this.props.generateConsolidatedReport.identifier);
            } else {
                this.setState({ loading: false });
            }
        }
        if (prevProps.issuesList !== this.props.issuesList) {
            if (!this.props.issuesList.errorStatus) {
                this.setState({ issues: this.props.issuesList });
            }
            this.setState({ initialLoadingIssue: false });
        }
        if (prevProps.issueTypesList !== this.props.issueTypesList) {
            if (!this.props.issueTypesList.errorStatus) {
                this.setState({ issueTypes: this.props.issueTypesList });
            }
            this.setState({ initialLoadingIssueType: false });
        }

        if (prevProps.generatedReportUrl !== this.props.generatedReportUrl) {
            if (!this.props.generatedReportUrl.errorStatus) {
                this.setState({ downloadUrl: this.props.generatedReportUrl.download_url, generatingUrl: false });
            } else {
                this.setState({ generatedReportInfo: null });
            }
            this.setState({ loading: false });
        }

        if (prevProps.createReportRequest !== this.props.createReportRequest) {
            if (!this.props.createReportRequest.errorStatus) {
                this.setState({ lastTimeSentRequest: new Date(), activeTabKey: HISTORIC_TAB_KEY });
            }

            this.setState({ loading: false });
        }
     }
    
    getTitle = () => {
        return this.state.title;
    }

    onCalendarChange = (value) => {
        this.setState({
            generatedReportInfo: null,
            dates: value
        });
    }

    onChangeDate = (value) => {
        this.setState({
            generatedReportInfo: null,
            dateValue: value,
            startDate: value[0],
            endDate: value[1],
        });
    }

    onOpenChange = open => {
        if (open) {
            this.setState({
                generatedReportInfo: null,
                hackDateValue: [],
                dates: []
            });
        } else {
            this.setState({
                generatedReportInfo: null,
                hackDateValue: undefined
            });
        }
      };

      disabledDate = current => {
          if (!this.state.dates || this.state.dates.length === 0) {
            return false;
          }
          const tooLate = this.state.dates[0] && (current.diff(this.state.dates[0], 'days') > MAX_RANGE_OF_DAYS_TO_REPORT || current.diff(this.state.dates[0], 'days') < (MAX_RANGE_OF_DAYS_TO_REPORT * -1));
          const tooEarly = this.state.dates[1] && (this.state.dates[1].diff(current, 'days') > MAX_RANGE_OF_DAYS_TO_REPORT || this.state.dates[1].diff(current, 'days') < (MAX_RANGE_OF_DAYS_TO_REPORT * -1));
          return tooEarly || tooLate;
      };

    handleChangeReportType = (value) => {
        this.setState({ listIssueId: [], reportType: value, selectedIssueTypeId: [] })
    }

    handleChangeFarms = (value) => {
        this.setState({ listFarmId: value, generatedReportInfo: null });
    }

    handleChangeIssueType = (value) => {
        let listIssueId = this.state.listIssueId;

        if (!value) {
            value = [];
        }

        let selectedIssueTypeId = Array.isArray(value) ? value : [value];

        if (listIssueId.length) {
            if (listIssueId[0]) {
                listIssueId = this.state.issues
                    .filter(issue => !this.state.listIssueId.length || this.state.listIssueId.includes(issue.id))
                    .filter(issue => !selectedIssueTypeId.length || selectedIssueTypeId.includes(issue.issue_type.id))
                    .map(issue => issue.id);
            } else {
                listIssueId = [];
            }
        }

        this.setState({
            listIssueId,
            selectedIssueTypeId,
            generatedReportInfo: null
        })
    }

    handleChangeIssue = (value) => {
        let listIssueId = [];

        if (value) {
            listIssueId = this.state.reportType === DETAILED_REPORT_TYPE ? value : [value];
        }

        this.setState({ listIssueId, generatedReportInfo: null })
    }

    handleOnChangeActiveTab = activeTabKey => {
        this.setState({ activeTabKey })
    }

    selectIssueByIssueType = (issueTypeIdSelected) => {
        let issuesFiltered = this.state.issues.filter(issue => issue.issueTypeId === issueTypeIdSelected)
        let issuesFilteredId = issuesFiltered.map(i => i.id)
        this.setState({ listIssueId: this.state.listIssueId.concat(issuesFilteredId) })
    }

    deselectIssueByIssueType = (issueTypeIdDeselected) => {
        let issuesFiltered = this.state.issues.filter(issue => issue.issueTypeId === issueTypeIdDeselected)
        let issuesFilteredId = issuesFiltered.map(i => i.id)
        this.setState({ listIssueId: this.state.listIssueId.filter(x => !issuesFilteredId.includes(x)) })
    }

    handleSubmit = async () => {
        let listFarmId = this.state.listFarmId;
        let listIssueId = this.state.listIssueId;
        let selectedIssueTypeId = this.state.selectedIssueTypeId;
        
        if (!listFarmId || !listFarmId.length) {
            listFarmId = this.state.farms ? this.state.farms.map(farm => farm.id) : [];
        }

        if (!listIssueId.length && selectedIssueTypeId.length) {
            listIssueId = this.state.issues.filter(issue => selectedIssueTypeId.includes(issue.issue_type.id)).map(issue => issue.id);
        }

        if (listFarmId.length > 0 && this.state.endDate && this.state.startDate) {
            let startDateList = []
            let endDateList = []
            let numberOfIntervals = Math.floor(this.state.endDate.diff(this.state.startDate, "month") / this.state.periodIntervalMonths)

            let startDate;
            let endDate = moment(this.state.startDate).add(-1, 'd')
            for (let i = 0; i <= numberOfIntervals - 1; i++) {
                startDate = moment(endDate).add(1, 'd').format(americanFormatDate);
                endDate = moment(startDate).add(this.state.periodIntervalMonths - 1, 'M').endOf('month').format(americanFormatDate);

                startDateList.push(startDate);
                endDateList.push(endDate);
            }

            startDate = moment(endDate).add(1, 'd').format(americanFormatDate);
            endDate = moment(this.state.endDate).format(americanFormatDate);

            startDateList.push(startDate);
            endDateList.push(endDate);

            let params = {
                farmsId: listFarmId,
                issueTypeIds: selectedIssueTypeId.length > 1 ? [] : selectedIssueTypeId,
                issuesId: listIssueId,
                endDateList: endDateList,
                startDateList: startDateList,
                identifier: null,
                reportType: this.state.reportType
            }

            // set paramsToGenerateReport
            await this.setState({ paramsToGenerateReport: params, generatedReportInfo: null });
            
            if (this.state.generateAssync) {
                this.props.sendCreateReportRequest({ 
                    ...params,
                    startDate: moment(this.state.startDate).format(americanFormatDate),
                    endDate: moment(this.state.endDate).format(americanFormatDate)
                });
            } else {
                this.generateDetailedReportNew(null, MAX_ATTEMPT_TO_GENERATE_REPORT, null);
            }
            this.setState({ loading: true, generatedReportInfo: null })
        } else {
            this.showModalError("Campos em branco", "Existem campos obrigatórios não preenchidos")
        }

    }

    getFarmNameById = farmId => {
        return this.state.farms.filter(f => f.id === farmId).map(f => f.name)[0];
    }

    generateDetailedReportNew = (lastFarmId, currentAttemptCount, lastStartDate) => {
        let farmsId = this.state.paramsToGenerateReport.farmsId;
        let reportDetails = null;

        let startDateList = this.state.paramsToGenerateReport.startDateList
        let endDateList = this.state.paramsToGenerateReport.endDateList

        let isRetrying = currentAttemptCount !== MAX_ATTEMPT_TO_GENERATE_REPORT;

        let lastPeriodIndex = lastStartDate ? startDateList.indexOf(lastStartDate) : null
        let lastFarmIdIndex = lastFarmId ? farmsId.indexOf(lastFarmId) : null

        let periodEnded = lastPeriodIndex === startDateList.length - 1;
        let periodIndex = this.calculatePeriodIndex(lastPeriodIndex, isRetrying, periodEnded);

        let periodIsIterating = periodIndex !== lastPeriodIndex && !periodEnded && lastPeriodIndex !== null;
        let farmIdIndex = this.calculateFarmIdIndex(lastFarmIdIndex, isRetrying, periodIsIterating)

        if (farmsId.length > farmIdIndex) {
            let params = { ...this.state.paramsToGenerateReport };
            params.farmsId = [farmsId[farmIdIndex]];
            params.remainingAttemps = currentAttemptCount;
            params.startDate = startDateList[periodIndex];
            params.endDate = endDateList[periodIndex];

            let currentFarmName = this.getFarmNameById(params.farmsId[0]);
            params.farmName = currentFarmName;

            reportDetails = {
                currentQuantityFarms: farmIdIndex + 1,
                currentFarmId: farmsId[farmIdIndex],
                quantityFarms: farmsId.length,
                currentFarmName: currentFarmName,
                attemptCount: MAX_ATTEMPT_TO_GENERATE_REPORT - currentAttemptCount,
                currentAttempt: currentAttemptCount,
                startDate: params.startDate,
                periodString: `${moment(params.startDate).format(dateFormat)} -> ${moment(params.endDate).format(dateFormat)}`,
                periodProgress: `${periodIndex + 1}/${startDateList.length}`
            };

            params.forConsolidate = this.state.reportType === CONSOLIDATED_REPORT_TYPE;
            this.setState({ reportDetails });
            this.props.fetchGenerateDetailedReportNew(params);
        } else {
            if (this.state.reportType === DETAILED_REPORT_TYPE) {
                if (this.state.paramsToGenerateReport.identifier) {
                    this.generateDownloadUrl(this.state.paramsToGenerateReport.identifier + ".csv");
                } else {
                    this.setState({ loading: false });
                }
            } else {
                if (this.state.paramsToGenerateReport.identifier) {
                    this.props.consolidateReport(this.state.paramsToGenerateReport.identifier);
                    this.setState({ consolidating: true });
                } else {
                    this.setState({ loading: false });
                }
            }
        }

    }

    calculateIndex(lastIndex, isRetrying) {
        let newIndex = 0;
        if (lastIndex !== null) {
            newIndex = isRetrying ? lastIndex : lastIndex + 1;
        }
        return newIndex;
    }

    calculatePeriodIndex(lastIndex, isRetrying, periodEnded) {
        if (periodEnded) {
            return 0;
        } else {
            return this.calculateIndex(lastIndex, isRetrying);
        }
    }

    calculateFarmIdIndex(lastIndex, isRetrying, periodIsIterating) {
        if (periodIsIterating) {
            return lastIndex;
        }
        return this.calculateIndex(lastIndex, isRetrying);
    }

    redirectToDownloadUrl = () => {
        const expiresIn = new URL(this.state.downloadUrl).searchParams.get("Expires");
        const expired = moment.unix(expiresIn) < moment();

        if (expired) {
            const title = "Relatório expirado";
            const description = "Este relatório está expirado, será necessário gerar novamente.";
            this.showModalError(title, description);
        } else {
            window.location.href = this.state.downloadUrl;
        }
    }

    handleSyncRequest = () => {
        this.props.requestSync();
        this.setState({ isRunningSyncRequest: true });
    }

    generateDownloadUrl = identifier => {
        this.setState({ generatingUrl: true });
        return this.props.generateReportDownloadUrl(identifier);
    }

    showModalError = (title, description) => {
        Modal.error(
            {
                title: title,
                content: description
            }
        )
    };

    getIssueSelectPlaceholder = () => {
        let allSelected = this.state.selectedIssueTypeId.length ? "Todos os formulários dos tipos selecionados" : "Todos os formulários selecionados";
        let selectIssue = this.state.selectedIssueTypeId.length ? "Todos os formulários do tipo selecionado" : "Selecione um formulário";
        let placeholder = this.state.reportType === DETAILED_REPORT_TYPE ? allSelected : selectIssue;

        return placeholder;
    }

    handleOnChangeGenerateAssync = e => {
        this.setState({generateAssync: e.target.checked})
    }

    renderAttemptCount = () => {
        if (!this.state.reportDetails || !this.state.reportDetails.attemptCount) {
            return "";
        }

        return "(Retentativa " + this.state.reportDetails.attemptCount + " de " + MAX_ATTEMPT_TO_GENERATE_REPORT + ") ";
    }

    renderFarmLoading = () => {
        if (!this.state.reportDetails) {
            return null;
        }

        let farmDetail = `${this.state.reportDetails.currentFarmName} (${this.state.reportDetails.currentQuantityFarms}/${this.state.reportDetails.quantityFarms})`;
        let periodDetail = `${this.state.reportDetails.periodString} (${this.state.reportDetails.periodProgress})`;

        let content = !this.state.consolidating ? 
                        <div style={{ display: "inline-block"}}>
                            {this.renderAttemptCount()}{farmDetail}
                            <p>{periodDetail}</p>
                        </div>
                    : <p>Consolidando ...</p>;
        return(
            <div>
                {content}
            </div>
        );
    }

    renderLoading = () => {
        return (
            <div>
                <div>
                    <div style={styles.loading}>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 48 }} spin />} />
                    </div>

                    <div style={{ display: "inline-block" }}>
                        Gerando relatório ...
                    </div>
                </div>
                {this.renderFarmLoading()}
            </div>
        );
    }

    renderTopButton = () => {
        if (isAdmin()) {
            return (
                <div style={styles.buttonDiv}>
                    {
                        this.props.syncRequestLast &&
                        <div style={styles.statusSync}>
                            Última sincronização em&nbsp;<strong>{moment(this.props.syncRequestLast.started_at).format("DD/MM/YYYY [às] HH:mm")}</strong>
                        </div>
                    }

                    {this.state.isRunningSyncRequest || (this.props.syncRequestLast && this.props.syncRequestLast.is_syncing) ?
                        <Button style={styles.sync.button} size={'large'} type="primary" disabled={true}>
                            <img style={styles.sync.icon_animate} src={images.request_sync} alt={"request_sync"} />
                            <p style={styles.sync.text}>Sincronizando...</p>
                        </Button>
                        :
                        <Button style={styles.sync.button} size={'large'} type="primary" onClick={this.handleSyncRequest}>
                            <img style={styles.sync.icon} src={images.request_sync} alt={"request_sync"} />
                            <p style={styles.sync.text}>Sincronizar</p>
                        </Button>
                    }
                </div>
            )
        }
    }

    renderOptionFarms = () => {
        if (!this.state.farms)
            return null;

        return this.state.farms.map(farm => {
            return(
                <Option data-cy={'detailed-report-farm-'+farm.id} key={farm.id} value={farm.id}>{farm.name}</Option>
            );
        });
    };

    renderOptionsIssueType = () => {
        if (!this.state.issueTypes)
            return null;

        return this.state.issueTypes.map(issue_type => {
            return(
                <Option data-cy={'detailed-report-issue-type-'+issue_type.id} key={issue_type.id} value={issue_type.id}>{issue_type.name}</Option>
            );
        });
    }

    renderOptionsIssues = () => {
        if (!this.state.issues)
            return null;

        let issues = this.state.issues;
        
        if (this.state.selectedIssueTypeId.length) {
            issues = issues.filter(issue => this.state.selectedIssueTypeId.includes(issue.issue_type.id));
        }

        return issues.map(issue => {
            return(
                <Option data-cy={'detailed-report-issue-'+issue.id} value={issue.id}>{issue.name}</Option>
            );
        });
    }

    renderPeriod = () => {
        return (
            <div >
                <Row>
                    <div style={styles.label}> 
                        <strong>Período *</strong>
                    </div>
                </Row>

                <Row type="flex" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="bottom">
                    <Col span={12} >
                    <RangePicker size={"large"} style={{ width: "100%" }}
                        value={this.state.hackDateValue || this.state.dateValue}
                        locale={locale}
                        format={dateFormat}
                        disabledDate={this.disabledDate}
                        onCalendarChange={val => this.onCalendarChange(val)}
                        onChange={val => this.onChangeDate(val)}
                        onOpenChange={this.onOpenChange}
                    />
                    </Col>
                    {this.renderAssyncCheckBox()}
                </Row>

            </div>

        );
    }

    renderAssyncCheckBox = () =>{
        if (isProtectorAdmin() && SHOW_GENERATE_ASYNC_REPORT) {
            return (
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={4}>
                    <div style={styles.datePicker}>
                      <Checkbox onChange={this.handleOnChangeGenerateAssync} checked={this.state.generateAssync}>Gerar Assíncrono</Checkbox>
                    </div>
                </Col>
            )
        }
    }

    renderType = () => {
        return (
            <Row >
                    <div style={styles.label}> 
                        <strong>Tipo</strong>
                    </div>
                    <Col span={8} >
                        <Select data-cy='detailed-report-select-report-type' size={"large"}
                            allowClear={true}
                            showArrow={true}
                            style={styles.select}
                            value={this.state.reportType}
                            onChange={this.handleChangeReportType} >
                                <Option data-cy='detailed-report-report-type-detailed' key={DETAILED_REPORT_TYPE} value={DETAILED_REPORT_TYPE}>Detalhado</Option>
                                <Option data-cy='detailed-report-report-type-consolidated' key={CONSOLIDATED_REPORT_TYPE} value={CONSOLIDATED_REPORT_TYPE}>Consolidado</Option>
                        </Select>
                    </Col>
            </Row>
        );
    }


    renderDetails = () => {
        return (
            <div>
                <div style={styles.label}>
                    <strong>Detalhes</strong>
                </div>
                <div style={{ marginRight: "10px" }}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" align="bottom" style={{ marginBottom: "30px" }}>
                            <Col span={8} >
                                <h4>Fazendas: </h4>
                                <Select data-cy= "detailed-report-select-farms"
                                    size={"large"}
                                    allowClear={true}
                                    showArrow={true}
                                    mode="multiple"
                                    style={styles.select}
                                    placeholder="Todas as fazendas selecionadas"
                                    onChange={this.handleChangeFarms}
                                    loading={this.state.initialLoadingFarm}
                                    filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }>
                                        {this.renderOptionFarms()}                                                       
                                </Select>
                            </Col>
                            
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" align="bottom" style={{ marginBottom: "30px" }}>
                        <Col span={8} className="gutter-row" >
                            <h4>Tipos de Problemas:</h4>
                                <Select data-cy="detailed-report-select-issue-type" size={"large"}
                                    allowClear={true}
                                    showArrow={true}
                                    mode={this.state.reportType === DETAILED_REPORT_TYPE && "multiple"}
                                    style={styles.select}
                                    value={this.state.selectedIssueTypeId}
                                    placeholder={"Selecione um tipo de problema"}
                                    onChange={this.handleChangeIssueType}
                                    loading={this.state.initialLoadingIssueType}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }>
                                {this.renderOptionsIssueType()}
                            </Select>
                        </Col>

                        <Col span={8} className="gutter-row" >
                            <h4 data-cy='detailed-report-issues-label'>{this.state.reportType === DETAILED_REPORT_TYPE ? "Problemas" : "Problemas*" }: </h4>
                            <Select data-cy='detailed-report-select-issues' size={"large"}
                                    allowClear={true}
                                    showArrow={true}
                                    mode={this.state.reportType === DETAILED_REPORT_TYPE && "multiple"}
                                    style={styles.select}
                                    value={this.state.listIssueId}
                                    placeholder={this.getIssueSelectPlaceholder()}
                                    onChange={this.handleChangeIssue}
                                    loading={this.state.initialLoadingIssue}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }>
                                {this.renderOptionsIssues()}
                            </Select>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    renderButtonDownload = () => {
        return (
            <div>
                <div style={{ display: "inline-block" }}>
                    <Button data-cy = 'detailed-report-download-report-button' type="primary" size={"large"} style={styles.button.downloadReport} onClick={this.redirectToDownloadUrl}>
                        <p style={styles.button.downloadText} >Baixar Relatório</p>
                        <p style={styles.button.downloadPsText}>(Abrir o relatório utilizando o Excel)</p>
                    </Button>
                </div>
            </div>
        );
    }

    renderDownloadLink = () => {
        return (
            <div style={styles.button.downloadDiv}>
                {this.state.generatedReportInfo.identifier ?
                    this.renderButtonDownload()
                    :
                    <p data-cy='detailed-report-no-data-found-message'>Nenhum dado foi encontrado para os parâmetros selecionados</p>
                }   
            </div>
        );
    }

    renderButton = () => {
        return (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" align="middle">
                <div>
                    <Button data-cy='detailed-report-generate-report-button' style={styles.button.generateReport} size={'large'} type="primary" onClick={this.handleSubmit}>
                        <img src={images.excel_icon} alt={"excel_icon"}/>
                        <p style={styles.button.generateText}>Gerar relatório</p>
                    </Button>
                </div>
                <div style={styles.downloadDiv}>
                    {this.state.generatedReportInfo && this.renderDownloadLink()}
                </div>
            </Row>
        )
    }

    renderProgress = () => {

        if (this.state.downloadProgress) {
            return (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" align="middle">
                    <div style={{ marginTop: "20px" }}>
                        <Progress type="circle" percent={this.state.downloadProgress} />
                    </div>
                </Row>
            );
        }
    }

    renderPageContentBody = () => {
        return (
            <div>
                <Tabs style={styles.tab} onChange={this.handleOnChangeActiveTab} activeKey={this.state.activeTabKey}>
                    <TabPane style={styles.tabPane} tab={"Relatório"} key={REPORT_TAB_KEY}>
                        {this.renderPeriod()}

                        <Divider />
                            {this.renderType()}
                        <Divider />
                        
                        {this.renderDetails()}
                        {this.state.loading ? this.renderLoading() : this.renderButton()}
                        {this.renderProgress()}
                    </TabPane>
                    <TabPane style={styles.tabPane} tab={"Histórico"} key={HISTORIC_TAB_KEY}>
                        <ReportHistoric lastTimeSentRequest={this.state.lastTimeSentRequest} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps({ generateDetailedReport, generateConsolidatedReport, generatedReportUrl, allowedFarms, issuesList, issueTypesList, createReportRequest, syncRequestResult, syncRequestLast }) {
    return { generateDetailedReport, generateConsolidatedReport, generatedReportUrl, allowedFarms, issuesList, issueTypesList, createReportRequest, syncRequestResult, syncRequestLast };
}

export default connect(mapStateToProps, { ...detailedReportActions, ...farmActions, ...issueActions })(DetailedReportManagement);