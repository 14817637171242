import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";
import { sendRefreshTokenCognito } from './authenciationActions';

export const FETCH_URL_REPORT_HISTORIC_FILE = "fetch_url_report_historic_file";
export const FETCH_REPORT_LIST = "fetch_report_list";
export const FETCH_ALL_REPORT_LIST = "fetch_all_report_list";
export const GENERATE_REPORT_REQUEST_URL_DOWNLOAD = "generate_report_request_url_download"

const baseAPI = process.env.REACT_APP_API_URL;


export const fetchReportDownloadUrl = (identifier, already_refresh) => async dispatch => {
    await axios.get(baseAPI + "detailed-report/download-generated-report/" + identifier,
    
    { headers: getDefaultHeader() })
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_URL_REPORT_HISTORIC_FILE),
            async (error) => {
                if (!already_refresh && error && error.response && error.response.status === 401) {
                    await sendRefreshTokenCognito(error);
                    fetchReportDownloadUrl(identifier, true)(dispatch);
                } else {
                    defaultErrorCallback(dispatch, error, FETCH_URL_REPORT_HISTORIC_FILE);
                }
            }
        );
}

export const getReportListByCurrentUser = (getFromAllUsers) => async dispatch => {
    const subpath = getFromAllUsers ? "/all" : "";

    await axios.get(baseAPI + "report/report-request" + subpath, { headers: getDefaultHeader() }).then(
        (res) => defaultSuccessCallback(dispatch, res, FETCH_REPORT_LIST),
        async (error) => {
            defaultErrorCallback(dispatch, error, FETCH_REPORT_LIST);
        }
    )

}

export const generateReportRequestDownloadUrl = (params) => async dispatch => {
    await axios.get(baseAPI + "report/report-request/download-report-request-file/" + params,
    {headers: { "Content-Type": "application/json", ...getDefaultHeader() } })
    .then(
        (res) => defaultSuccessCallback(dispatch, res, GENERATE_REPORT_REQUEST_URL_DOWNLOAD),
        (error) => defaultErrorCallback(dispatch, error, GENERATE_REPORT_REQUEST_URL_DOWNLOAD)
    )

}