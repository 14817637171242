import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";

export const FETCH_ISSUES_LIST = "fetch_issues_list";
export const FETCH_ISSUE_TYPES_LIST = "fetch_issue_types_list";


const baseAPI = process.env.REACT_APP_API_URL;


export const fetchIssues = () => async dispatch => {
    await axios.get(baseAPI + "issue/", { headers: getDefaultHeader() })
        .then(
            (res) => { defaultSuccessCallback(dispatch, res, FETCH_ISSUES_LIST) },
            (error) => { defaultErrorCallback(dispatch, error, FETCH_ISSUES_LIST) }
        );
}

export const fetchIssueTypes = () => async dispatch => {
    await axios.get(baseAPI + "issue-type/", { headers: getDefaultHeader() })
        .then(
            (res) => { defaultSuccessCallback(dispatch, res, FETCH_ISSUE_TYPES_LIST) },
            (error) => { defaultErrorCallback(dispatch, error, FETCH_ISSUE_TYPES_LIST) }
        );
}