import React, {Component} from 'react';

import {Table, Button, Icon, Input, Modal, Spin, notification, Form, Tooltip, Checkbox, InputNumber, Select} from 'antd';
import { images } from '../../assets';
import { connect } from "react-redux";
import * as autoRouterActions from "../../actions/autoRouterActions";
import * as taskManagerActions from "../../actions/taskManagerActions";

const FormItem = Form.Item;

const { Option } = Select;

const styles = {
    form: {
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
        row: { 
            display: "flex",
            flexDirection: "row"
        },
        input: {
            width: "200px", 
            height: "36px"
        },
        tooltip: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "32px"
        },
        select: {
            width: "436px"
        }
    },
    buttonOk: {
        backgroundColor: '#782777',
        color: '#fff',
        borderColor: '#782777',
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px"
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
    }
}

const MIN_PERCENTAGE_TEXT  = "É a porcentagem mínima que o trabalhador deve realizar para conluir a tarefa. Se a tarefa possui 10 pontos de amostra, e a porcentagem mínima é 50%, o trabalhador deverá realizar pelo menos 5 pontos para concluir a tarefa.";
const PRODUCTIVITY_TEXT  = "Tempo que o trabalhador gasta para realizar um ponto dessa atividade. (minutos/ponto)";
const PRIORITY_TEXT = "Nivel de prioridade dessa atividade. O modelo irá considerar esse nível para definir quais tarefas serão criadas."
const POINTS_PER_HECTARES_TEXT = "Quantidade de pontos por hectares ou a quantidade absoluta de pontos. Para quantidade absoluta de pontos, digite apenas o número. Se digitar 5 o trabalhador deverá fazer 5 pontos para cada área. Para definir pontos de acordo com o tamanho da área, digite {pontos}/{hectares}. Por exemplo, se digitar 1/20 o trabalhador irá fazer 1 ponto a cada 20 hectares.";
const MIN_WORKERS_TEXT = "O modelo irá considerar que não é possível realizar essa tarefa com menos trabalhadores do que o especificado aqui";
const MAX_WORKERS_TEXT = "A quantidade máxima de trabalhadores que consegue realizar essa tarefa ao mesmo tempo";
const VEHICLE_TYPE_TEXT = "Os tipos de veículos que podem realizar esta tarefa."

class ActivityManager extends Component {
    constructor(props) {
        super (props)

        this.state = {
            activities: [],
            vehicleTypes: [],
            showEditModal: false,
            modalData: null,
            loading: true
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.fetchAllVehicleTypes !== this.props.fetchAllVehicleTypes) {
            if(!this.props.fetchAllVehicleTypes.errorStatus){
                this.setState({vehicleTypes: this.formatVehicleTypes(this.props.fetchAllVehicleTypes)})
            }
        }
        if (prevProps.fetchAllActivities !== this.props.fetchAllActivities){
            if(!this.props.fetchAllActivities.errorStatus){
                this.setState({activities: this.formatActivities(this.props.fetchAllActivities), 
                               vehicleTypes: this.formatVehicleTypes(this.props.fetchAllVehicleTypes), 
                               loading: false});
            } else {
                this.setState({loading: false});
            }
        }
        if (prevProps.editActivity !== this.props.editActivity){
            if(!this.props.editActivity.errorStatus){
                this.props.getAllActivities();
                this.successNotification();
            } else {
                this.setState({loading: false});
            }
        }
    } 

    componentDidMount = () => {
        this.props.getAllActivities();
        this.props.getAllVehicleTypes();
    }

    formatVehicleTypes = (vehicleTypes) => {
        return vehicleTypes.map(vehicleType => ({
            id: vehicleType.id,
            name: vehicleType.name
        }))
    }

    formatActivities = (activities, isInvalid = false) => {
        return activities.map(activity => ({
            key: activity.id,
            id: activity.id,
            name: activity.name,
            maxWorkers: activity.max_workers,
            minWorkers: activity.min_workers,
            pointsPerHectares: activity.hectares_per_points,
            productivity: activity.productivity,
            minSamplesPercentage: isInvalid ? activity.minimum_samples_percentage * 100 : activity.minimum_samples_percentage * 100 + "%",
            priority: activity.priority,
            useShapefile: activity.use_shapefile,
            vehicleTypes: Object.values(activity.vehicle_types).map(vehicleType => vehicleType.id)
        }))
    }

    formatVehicleTypesIds = (vehicleTypes) => {
        
    }

    isModalDataValid = activity => {
        const invalids = Object.entries(activity).filter(value => parseInt(value[1]) === 0 ? false : !value[1] );
        return invalids.length === 0 ? true : false
    }
        
    handleModalOk = () => {
        const activity = {
            id: this.state.modalData.id,
            name: this.state.modalData.name,
            max_workers: this.state.modalData.maxWorkers !== '' ? parseInt(this.state.modalData.maxWorkers) : null,
            min_workers: this.state.modalData.minWorkers !== '' ? parseInt(this.state.modalData.minWorkers) : null,
            hectares_per_points: this.state.modalData.pointsPerHectares && this.state.modalData.pointsPerHectares.match(/^\d+\/\d+$|^[0-9]+$/) ? 
                                    String(this.state.modalData.pointsPerHectares) : null,
            productivity: parseInt(this.state.modalData.productivity),
            minimum_samples_percentage: this.state.modalData.minSamplesPercentage,
            priority: this.state.modalData.priority !== '' ? parseInt(this.state.modalData.priority) : null,
            vehicle_types: this.state.modalData.vehicleTypes
        }
        const isDataValid = this.isModalDataValid(activity);
        activity.use_shapefile = this.state.modalData.useShapefile;
        if (isDataValid){
            activity.minimum_samples_percentage = activity.minimum_samples_percentage / 100;
            this.setState({loading: true, showEditModal: false, modalData: null});
            this.props.updateActivity(activity);
        } else {
            this.setState({modalData: this.formatActivities([activity], true)[0]})
        }
    }

    handleShowEditActivity = (rowData) => {
        this.setState({
            showEditModal: true, 
            modalData: {
                ...rowData,
                minSamplesPercentage: rowData.minSamplesPercentage.includes('%') ?
                rowData.minSamplesPercentage.slice(0, -1): rowData.minSamplesPercentage
            }
        })
    }

    handleCancelModal = () => {
        this.setState({showEditModal: false, modalData: null})
    }

    handleOnChangeMaxWorkers = value => {
        this.setState({modalData: {...this.state.modalData, maxWorkers: value}});
    }

    handleOnChangeMinWorkers = value => {
        this.setState({modalData: {...this.state.modalData, minWorkers: value}});
    }

    handleOnChangeminSamplesPercentage = value => {
        this.setState({modalData: {...this.state.modalData, minSamplesPercentage: value}});
    }

    handleOnChangePointsPerHectares = ({ target: { value } }) =>  {
        this.setState({modalData: {...this.state.modalData, pointsPerHectares: value}});
    }

    handleOnChangePriority = value => {
        this.setState({modalData: {...this.state.modalData, priority: value}});
    }

    handleOnChangeProductivity = value => {
        this.setState({modalData: {...this.state.modalData, productivity: value}});
    }

    handleOnChangeCheckbox = () => {
        this.setState({modalData: {...this.state.modalData, useShapefile: !this.state.modalData.useShapefile}})
    }

    handleOnChangeVechicleTypes = value => {
        this.setState({modalData: {...this.state.modalData, vehicleTypes: value}})
    }

    successNotification = () =>{
        notification.open({
          message: 'Atividade atualizada com sucesso',
          icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          },
        );
      };

    renderEditModal = () => {
        return(
            <div>
                <Form formLayout = {'horizontal'}>
                    <FormItem style={{...styles.form.label, flex:2}}>
                        Nome:
                        <Input  disabled  
                                value={this.state.modalData.name} 
                            />
                    </FormItem>
                    <div style={styles.form.row}>
                        <FormItem   style={{...styles.form.label, flex:1,  marginRight: "5px"}}
                                    validateStatus={this.state.modalData.minWorkers === null && "error"}>
                            <div style={styles.form.tooltip}>
                                Mínimo de trabalhadores:
                                <Tooltip title={MIN_WORKERS_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                                </Tooltip>
                            </div>
                            <InputNumber style={styles.form.input}
                                    defaultValue={this.state.modalData.minWorkers}
                                    onChange={this.handleOnChangeMinWorkers}
                                    min={2}
                                />
                        </FormItem>
                        <FormItem   style={{...styles.form.label, flex:1, marginRight: "5px"}}
                                    validateStatus={this.state.modalData.maxWorkers === null && "error"}>
                            <div style={styles.form.tooltip}>
                                Máximo de trabalhadores:
                                <Tooltip title={MAX_WORKERS_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                                </Tooltip>
                            </div>
                            <InputNumber  style={styles.form.input}
                                    defaultValue={this.state.modalData.maxWorkers}
                                    onChange={this.handleOnChangeMaxWorkers}
                                    min={1}
                            />
                        </FormItem>
                    </div>
                    <div style={styles.form.row}>
                        <FormItem   style={{...styles.form.label, flex:1,  marginRight: "5px"}}
                                    validateStatus={this.state.modalData.minSamplesPercentage === null && "error"}>
                             <div style={styles.form.tooltip}>
                                Mínimo para conclusão (%): 
                            <Tooltip title={MIN_PERCENTAGE_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                            </Tooltip>
                               </div>
                            <InputNumber  style={styles.form.input}
                                    defaultValue={this.state.modalData.minSamplesPercentage}
                                    max={100}
                                    min={0}
                                    onChange={this.handleOnChangeminSamplesPercentage}
                                    suffix="%"
                                />
                        </FormItem>
                        <FormItem   style={{...styles.form.label, flex:1,  marginRight: "5px"}}
                                    validateStatus={this.state.modalData.pointsPerHectares === null && "error"}>
                            <div style={styles.form.tooltip}>
                                Pontos por hectares:
                                <Tooltip title={POINTS_PER_HECTARES_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                                </Tooltip>
                            </div>
                            <Input style={styles.form.input}
                                    type={"text"}
                                    syze={"small"}
                                    defaultValue={this.state.modalData.pointsPerHectares}
                                    onChange={this.handleOnChangePointsPerHectares} 
                                />
                        </FormItem>
                        
                    </div>
                    <div style={styles.form.row}>
                        <FormItem   style={{...styles.form.label, flex:1,  marginRight: "5px"}}
                                    validateStatus={this.state.modalData.priority === null && "error"}>
                            <div style={styles.form.tooltip}>
                                Prioridade:
                                <Tooltip title={PRIORITY_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                                </Tooltip>
                            </div>
                            <InputNumber style={styles.form.input}
                                    defaultValue={this.state.modalData.priority}
                                    onChange={this.handleOnChangePriority} 
                                    min={0}
                                />
                        </FormItem>
                        <FormItem   style={{...styles.form.label, flex:1,  marginRight: "5px"}}
                                    validateStatus={this.state.modalData.productivity === null && "error"}>
                            <div style={styles.form.tooltip}>
                                Produtividade (min/ponto):
                                <Tooltip title={PRODUCTIVITY_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                                </Tooltip>
                            </div>
                            <InputNumber style={styles.form.input}
                                    defaultValue={this.state.modalData.productivity}
                                    min={1}
                                    onChange={this.handleOnChangeProductivity}
                                />
                        </FormItem>
                    </div>

                    <div style={styles.form.row}>
                        <FormItem style={{...styles.form.label, flex:1,  marginRight: "5px"}}>
                            <div style={styles.form.tooltip}>
                                Tipos de Veículos:
                                <Tooltip title={VEHICLE_TYPE_TEXT}>
                                        <img src={images.info_icon} alt={"info_icon"} />
                                </Tooltip>
                            </div>
                            <Select
                                size={"large"}
                                allowClear={true}
                                showArrow={true}
                                mode= {"multiple"}
                                style={styles.form.select}
                                value={this.state.modalData.vehicleTypes}
                                placeholder={"Todos selecionados"}
                                onChange={this.handleOnChangeVechicleTypes}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }>
                                
                                {this.renderVehicleTypeSelectItem()}

                            </Select>
                        </FormItem>
                    </div>

                    <Checkbox defaultChecked={this.state.modalData.useShapefile} onChange={() => this.handleOnChangeCheckbox()} style={{marginTop: "10px", fontSize: "16px"}}>Usa shapefile? </Checkbox>
                </Form>
            </div>
        );
    }

    renderVehicleTypeSelectItem = () => {
        if (!this.state.vehicleTypes) {
            return null;
        }

        return this.state.vehicleTypes.map(vehicleType => {
            return (
                <Option key={vehicleType.id} value={vehicleType.id}>{vehicleType.name}</Option>
            );
        });
    }
    
    renderEdit = (rowData) => {
        return(
            <div>
                <Button style={styles.actions.button} size={'small'} type="primary" onClick={() => this.handleShowEditActivity(rowData)}>
                    <Icon type="edit" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Editar</p>
                </Button>
            </div>
        );
    }

    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56}} spin />} />
            </div>
        );
    }

    renderUseShapefile = rowData => {
        if(rowData.useShapefile){
            return (
                <p>Sim</p>
            );
        } else {
            return (
                <p>Não</p>
            );
        }
    }

    renderTable = () => {
        if (this.state.loading){
            return this.renderLoading();
        }

        const columns = [
            {
                title: "Nome",
                dataIndex: "name",
                align: "left",
                key: "activity"
            },
            {
                title: "Max de trabalhadores",
                dataIndex: "maxWorkers",
                align: "left",
                key: "maxWorkers"
            },
            {
                title: "Min de trabalhadores",
                dataIndex: "minWorkers",
                align: "left",
                key: "minWorkers"
            },
            {
                title: "Min. para conclusão",
                dataIndex: "minSamplesPercentage",
                align: "left",
                key: "minSamplesPercentage"
            },
            {
                title: "Prioridade",
                dataIndex: "priority",
                align: "left",
                key: "priority"
            },
            {
                title: "Pontos por hectares",
                dataIndex: "pointsPerHectares",
                align: "left",
                key: "pointsPerHectares"
            },
            {
                title: "Produt. (min/ponto)",
                dataIndex: "productivity",
                align: "left",
                key: "productivity"
            },
            {
                title: "Usa shapefile",
                dataIndex: "use_shapefile",
                align: "left",
                key: "use_shapefile",
                render: (text, record) => this.renderUseShapefile(record)
            },
            {
                title: "Editar",
                width: "100px",
                dataIndex: "edit",
                fixed: 'right',
                align: "left",
                key: "edit",
                render: (text, record) => this.renderEdit(record)
            }
        ];

        return (
            <Table 
                columns={columns}
                dataSource={this.state.activities}
                scroll={{ x: true, y: false }}
                style={{paddingBottom: 0}} 
                pagination={{
                    pageSize: 10
                }}
            />
        );
    }

    render() {
        return(
            <div>
                <h2>Atividades</h2>
                {this.renderTable()}
                <Modal
                    title="Editar"
                    visible={this.state.showEditModal}
                    onOk={() => this.handleModalOk()}
                    style={{paddingTop: "12px"}}
                    onCancel={this.handleCancelModal}
                    >
                    {this.state.modalData && this.renderEditModal()}
                </Modal>
            </div>
        );
    }
}

function mapStateToProps({ editActivity, fetchAllActivities, fetchAllVehicleTypes}) {
    return { editActivity, fetchAllActivities, fetchAllVehicleTypes};
}

export default connect(mapStateToProps, { ...autoRouterActions, ...taskManagerActions })(ActivityManager); 