import axios from "axios";
import { defaultSuccessCallback, defaultErrorCallback, getDefaultHeader } from "./index";
import FileDownload from 'js-file-download';

export const FETCH_AUTO_ROUTER_LIST = "fetch_auto_router_list";
export const DOWNLOAD_UPLOADED_TASK_EXCEL = "download_uploaded_task_excel";
export const POST_XLS_TO_AUTO_ROUTER = "post_xls_to_auto_router";
export const REMOVE_AUTO_ROUTER_UPLOADED_EXCEL = "remove_auto_router_uploaded_excel";
export const REMOVE_AUTO_ROUTER_UPLOADED_EXCEL_FORCED = "remove_auto_router_uploaded_excel_forced";
export const CREATE_TASKS_FROM_AUTO_ROUTER = "create_tasks_from_auto_router";
export const REMOVE_AUTO_ROUTER = "remove_auto_router";
export const DOWNLOAD_EXCEL_GENERATE_BY_AUTO_ROUTER = "download_excel_generate_by_auto_router";
export const REMOVE_TASK_CREATED_FROM_AUTO_ROUTER = "delete_task_created_from_auto_router";
export const FETCH_AUTO_ROUTER_CREATED_LIST = "fetch_auto_router_created_list";
export const AUTO_ROUTER_SEND_REQUEST = "auto_router_send_request";
export const FETCH_AUTO_ROUTER_UPLOAD_DETAILS = "fetch_auto_router_upload_details";
export const FETCH_AUTO_ROUTER_INPUT_DETAILS = "fetch_auto_router_input_details";
export const UPDATE_TASK_REQUEST_EXECUTED_AT =  "update_task_request_executed_at";
export const RE_ROUTER_AUTO_ROUTER = "re_router_auto_router";
export const RESEND_UPLOAD = "resend_upload"; 


const baseAPI = process.env.REACT_APP_API_URL;

export const getAutoRouterList = () => async dispatch => {
    await axios.get(baseAPI + "auto-router/uploaded-file/all",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
        )
        .then(
            (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_LIST),
            (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_LIST)
        );
}

export const downloadUploadedTaskExcel = (uploadedTaskId) => async dispatch => {

    await axios.get(baseAPI + "auto-router/" + uploadedTaskId + "/download-uploads-excel", { 
        headers: { 'Content-Type': 'blob', ...getDefaultHeader() }, 
        responseType: 'arraybuffer' }
    ).then(
        (res) => {
            FileDownload(res.data, "tarefas_" + uploadedTaskId + ".xlsx");
            res.data = { id: uploadedTaskId, datetime: new Date() };
            defaultSuccessCallback(dispatch, res, DOWNLOAD_UPLOADED_TASK_EXCEL);
        },
        (error) => {

            var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
            var errorData = JSON.parse(decodedString)
            
            dispatch({
                type: DOWNLOAD_UPLOADED_TASK_EXCEL,
                payload: { errorStatus: true, errorData: { ...errorData, uploadedTaskId }},
                error: error.response ? error.response : null,
                status: error.response ? error.response.status : null
            });
        }
    );
}

export const uploadXlsToAutoRouter = (params) => async dispatch => {
    let formData = new FormData()
    formData.append('file', params.file)

    await axios.post(baseAPI + "auto-router/create-from-excel",
        formData,
        { headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ...getDefaultHeader() } }
    ).then(
        (res) => {
            defaultSuccessCallback(dispatch, res, POST_XLS_TO_AUTO_ROUTER);
        },
        (error) => defaultErrorCallback(dispatch, error, POST_XLS_TO_AUTO_ROUTER)
    );
}

export const deleteUploadedExcel = (autoRouterExcelRequestId) => async dispatch => {
    await axios.delete(baseAPI + "auto-router/" + autoRouterExcelRequestId,
        { headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ...getDefaultHeader() } }
    ).then(
        (res) => {
            defaultSuccessCallback(dispatch, res, REMOVE_AUTO_ROUTER_UPLOADED_EXCEL);
        },
        (error) => {
            dispatch({
                type: REMOVE_AUTO_ROUTER_UPLOADED_EXCEL,
                payload: { errorStatus: true, errorData: { ...error.response.data, autoRouterExcelRequestId }},
                error: error.response ? error.response : null,
                status: error.response ? error.response.status : null
            });
        }
    );
}

export const deleteUploadedExcelForced = (autoRouterExcelRequestId) => async dispatch => {
    await axios.delete(baseAPI + "auto-router/" + autoRouterExcelRequestId + "/forced",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => {
            defaultSuccessCallback(dispatch, res, REMOVE_AUTO_ROUTER_UPLOADED_EXCEL_FORCED);
        },
        (error) => defaultErrorCallback(dispatch, error, REMOVE_AUTO_ROUTER_UPLOADED_EXCEL_FORCED)
    )
}

export const resendUploadAutoRouter = (autoRouterExcelRequestId) => async dispatch => {
    await axios.post(baseAPI + "auto-router/" + autoRouterExcelRequestId + "/resend-upload",
    null,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then(
        (res) => {
            res.data = { id: autoRouterExcelRequestId, datetime: new Date() };
            defaultSuccessCallback(dispatch, res, RESEND_UPLOAD);
        },
        (error) => {
            dispatch({
                type: RESEND_UPLOAD,
                payload: { errorStatus: true, errorData: { ...error.response.data, autoRouterExcelRequestId }},
                error: error.response ? error.response : null,
                status: error.response ? error.response.status : null
            });
        }
    );
}

export const createTasksAutoRouter = (autoRouter, retry) => async dispatch => {
    let route = ""
    if (retry) {
        route = "/retry-tasks-creation"
    } else {
        route = "/release-tasks"
    }
    await axios.post(baseAPI + "auto-router/"+ autoRouter.id + route, null,
    { headers: { ...getDefaultHeader(), "Content-Type": "application/json" } }
        )
        .then(
            (res) => {
                res.data = { id: autoRouter.id}
                defaultSuccessCallback(dispatch, res, CREATE_TASKS_FROM_AUTO_ROUTER)
            },
            (error) => {
                if (error.response) {
                    error.response.id = autoRouter.id;
                    error.response.datetime = new Date();
                } else {
                    error.response = {id: autoRouter.id, datetime: new Date()}
                }
                
                defaultErrorCallback(dispatch, error, CREATE_TASKS_FROM_AUTO_ROUTER)
            }
        );
}

export const reRouterAutoRouter = (autoRouter) => async dispatch => {
    let route = "/rerouter"

    await axios.post(baseAPI + "auto-router/"+ autoRouter.id + route, null,
    { headers: { ...getDefaultHeader(), "Content-Type": "application/json" } }
        )
        .then(
            (res) => {
                res.data = { id: autoRouter.id, date: new Date() }
                defaultSuccessCallback(dispatch, res, RE_ROUTER_AUTO_ROUTER);
            },
            (error) => {
                if (error.response) {
                    error.response.id = autoRouter.id;
                    error.response.datetime = new Date();
                } else {
                    error.response = {id: autoRouter.id, datetime: new Date()}
                }
                
                defaultErrorCallback(dispatch, error, RE_ROUTER_AUTO_ROUTER);
            }
        );
}


export const deleteAutoRouter = (autoRouter) => async dispatch => {
    await axios.delete(baseAPI + "auto-router/input/" + autoRouter.id,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => {
            defaultSuccessCallback(dispatch, res, REMOVE_AUTO_ROUTER);
        },
        (error) => {
            defaultErrorCallback(dispatch, error, REMOVE_AUTO_ROUTER);
        }
    )
}

export const downloadExcelGeneratedByAutoRouter = (autoRouter) => async dispatch => {
    await axios.get(baseAPI + "auto-router/" + autoRouter.taskExcelRequestId + "/download-tasks-excel",
    { headers: { 'Content-Type': 'blob', ...getDefaultHeader() }, 
    responseType: 'arraybuffer' }
    ).then (
        (res) => {

            FileDownload(res.data, "tarefasRoterizadas_" + autoRouter.id + ".xlsx");
            res.data = {id: autoRouter.id}
            defaultSuccessCallback(dispatch, res, DOWNLOAD_EXCEL_GENERATE_BY_AUTO_ROUTER);
        },
        (error) => {

            let decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
            let decodedResponseData = JSON.parse(decodedString);

            let response = {data: decodedResponseData, id: autoRouter.id, datetime: new Date()};

            defaultErrorCallback(dispatch, {response}, DOWNLOAD_EXCEL_GENERATE_BY_AUTO_ROUTER);
        }
    )
}

export const deleteTaskFromAutoRouter = (autoRouterInputId) => async dispatch => {
    await axios.delete(baseAPI + "auto-router/delete-protector-tasks/" + autoRouterInputId,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => {
            res.data = {id: autoRouterInputId}
            defaultSuccessCallback(dispatch, res, REMOVE_TASK_CREATED_FROM_AUTO_ROUTER);
        },
        (error) => {
            if (error.response) {
                error.response.id = autoRouterInputId;
                error.response.datetime = new Date();
            } else {
                error.response = {id: autoRouterInputId, datetime: new Date()}
            }
            defaultErrorCallback(dispatch, error, REMOVE_TASK_CREATED_FROM_AUTO_ROUTER)
        }
    )
}

export const getAutoRouterCreatedList = (autoRouter) => async dispatch => {
    await axios.get(baseAPI + "auto-router/input/",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_CREATED_LIST),
        (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_CREATED_LIST)
    )
}

export const sendRequestAutoRouter = (params) => async dispatch => {
    let unityGruopsIdParam = params.unityGruopsId.map(unityGroup => `unity-groups=${unityGroup}`).join('&');
    let createTasks = params.automaticallyCreateTasksAfterAutoRouter ? "create-tasks=true" : null;
    let startDate = params.startDate;
    let endDate = params.endDate;

    let paramsStr = "?" + unityGruopsIdParam;
    if (createTasks) {
        paramsStr += "&" + createTasks;
    }
    if (startDate) {
        paramsStr += "&startDate=" + startDate
    }
    if (endDate) {
        paramsStr += "&endDate=" + endDate
    }

    await axios.post(baseAPI + "auto-router/send" + paramsStr, null,
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => {
            defaultSuccessCallback(dispatch, res, AUTO_ROUTER_SEND_REQUEST);
        },
        (error) => defaultErrorCallback(dispatch, error, AUTO_ROUTER_SEND_REQUEST)
    )
}

export const getAutoRouterUploadDetails = id => async dispatch => {
    await axios.get(baseAPI + "auto-router/" + id + "/details",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_UPLOAD_DETAILS),
        (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_UPLOAD_DETAILS)
    )
}

export const getAutoRouterInputDetails = id => async dispatch => {
    await axios.get(baseAPI + "auto-router/input/" + id + "/details",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => defaultSuccessCallback(dispatch, res, FETCH_AUTO_ROUTER_INPUT_DETAILS),
        (error) => defaultErrorCallback(dispatch, error, FETCH_AUTO_ROUTER_INPUT_DETAILS)
    )
}

export const sendUpdateTaskRequestExecutedAt = () => async dispatch => {
    await axios.get(baseAPI + "auto-router/force-update-tasks",
    { headers: { "Content-Type": "application/json", ...getDefaultHeader() } }
    ).then (
        (res) => defaultSuccessCallback(dispatch, res, UPDATE_TASK_REQUEST_EXECUTED_AT),
        (error) => defaultErrorCallback(dispatch, error, UPDATE_TASK_REQUEST_EXECUTED_AT)
    )
}