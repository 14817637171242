export const DEFAULT_ERROR_TYPE = "default_error";

// export const getAuthHeader = () => {
//     return {
//         username: sessionStorage.getItem("username"),
//         password: sessionStorage.getItem("password")
//     }
// }

export const getDefaultHeader = () => {
    return {
        "X-Auth-Token": sessionStorage.getItem("token")
    }
}

export const dispatchHttpError = (dispatch, res) => {
    dispatch({
        type: DEFAULT_ERROR_TYPE,
        payload: res ? res.data : null,
        status: res ? res.status : null
    });
}

export const defaultSuccessCallback = (dispatch, res, type) => {
    dispatch({
        type: type,
        payload: res.data,
        status: res.status
    });
}

export const defaultErrorCallback = (dispatch, res, type, hideDialog) => {
    dispatch({
        type: type,
        payload: res ? { errorStatus: true, ...res.response } : { errorStatus: true },
        error: res.response ? res.response : null,
        status: res.response ? res.response.status : null
    });

    if (!hideDialog) {
        dispatchHttpError(dispatch, res.response);
    }
}