import React from 'react';
import {Modal, Button, Tabs, Upload, Spin, Icon, Menu, Dropdown, Progress} from 'antd';
import PageContent from '../page-content/PageContent';
import { images } from '../../assets';
import ActivityManager from './ActivityManager';
import WorkerManager from './WorkerManager';
import VehicleManager from './VehicleManager';
import WorkHoursManager from "./WorkHoursManager"
import { connect } from "react-redux";
import * as autoRouterActions from "../../actions/autoRouterActions"
const { TabPane } = Tabs

const styles = {
    loading: {
        display: "inline-block",
        text: {
                display: "inline-block",
                marginTop: "8pt",
                marginRight: "8pt"
            },
        icon: {
            display: "inline-block",
            color: "#782777",
            fontSize: 24
        }
    },
    progress: {
        paddingRight: '25%'
    },
    sendAutoRouterRequest: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        color: '#ffffff',
    },
    select: {
        div: {
            display:'flex',
            flexDirection:'column',
            marginBottom:'10px'
        },
        label: {
            marginBottom: 0
        },
        form: {
            width: '80%'
        }
    },
    upload: {
        excelButton: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: 0,
            color:  "#ffffff",
            fontSize: "12pt",
            marginRight: "16pt"
        },
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        }
    },
    downloadButton: {
        textDecoration: 'underline',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#781e76",
        marginRight: "14px",
        cursor: 'pointer'
    },
    jsonButton: {
        backgroundColor: '#781e76',
        borderColor: '#781e76',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#ffffff",
        fontSize: "12pt",
        marginRight: "16pt"
    },
    buttonDiv: {
        display: "flex"
    },
    tab: {
        paddingLeft: 30, 
        paddingRight: 30
    }
}

class AutoRouterManager extends PageContent {

    constructor(props) {
        super(props);

        this.state = {
            title: "Roteirização",
            activeTab: 'TASK',
            showModalSelectExcelFile: false,
            loadingExcelFile: false,
            downloadModelProgress: false,
            downloadConfigProgress: false,
            convertTaskExcelModalData: {
                buttonDisabled: true,
                fileList: [],
            },
            convertingTaskExcelToJson: false
        }
    }

    componentDidMount() {
        this.setState({ title: "Roteirização" });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.uploadFileExcel !== this.props.uploadFileExcel) {
            if (!this.props.uploadFileExcel.errorStatus) {
                this.setState({
                    loadingExcelFile: false,
                    showModalSelectExcel:false,
                    showModalSelectExcelFile: false
                });
            } else {
                this.setState({loadingExcelFile: false});
            }
        }
        if (prevProps.convertTaskExcelToJson !== this.props.convertTaskExcelToJson) {
            if (!this.props.convertTaskExcelToJson.errorStatus) {
                let convertTaskExcelModalData = this.state.convertTaskExcelModalData;
                convertTaskExcelModalData.fileList = [];
                this.setState({ convertingTaskExcelToJson: false, showConvertExcelModal: false, convertTaskExcelModalData });
            } else {
                this.setState({ convertingTaskExcelToJson: false, showConvertExcelModal: false, convertTaskExcelModalData: {fileList: []}});
            }
        }
    }

    handleConvertExcelModalCancel = e => {
        this.setState({
            showConvertExcelModal: false,
            convertTaskExcelModalData: {fileList: []}
        });
    }

    handleConvertExcelModalOk = e => {
        let convertTaskExcelModalData = this.state.convertTaskExcelModalData;

        let params = {
            file: convertTaskExcelModalData.fileList[0]
        }

        this.props.uploadFileExcelToConvertJson(params);

        this.setState({
            convertingTaskExcelToJson: true,
            convertTaskExcelModalData: {
                fileList: [],
                buttonDisabled : true
            }
        });
    };
      

    handleOnChangeActiveTab = tab => {
        this.setState({activeTab: tab})
    }

    showConvertExcelModal = () => {
        this.setState({ showConvertExcelModal: true });
    }

    getTitle = () => {
        return this.state.title;
    }

    setModelProgress = (downloadModelProgress) => {
        downloadModelProgress = downloadModelProgress === 100 ? false : downloadModelProgress
        this.setState({ downloadModelProgress });
    };

    setConfigProgress = (downloadConfigProgress) => {
        downloadConfigProgress = downloadConfigProgress === 100 ? false : downloadConfigProgress
        this.setState({ downloadConfigProgress });
    };

    handleModelDownloadOnClick = (event) => {
        let params = {
            type: event.key
        };

        this.props.downloadExcelTemplate(params, this.setModelProgress);
    };

    handleConfigDownloadOnClick = (event) => {
        let params = {
            type: event.key
        };

        this.props.downloadExcelConfigData(params, this.setConfigProgress);
    };

    getDropdownDownloadMenuConfig = () => {
        return( <Menu> {this.getDropdownDownloadMenuConfigItems()} </Menu> );
    };

    getDropdownDownloadMenuConfigItems = () => {
        const list = [
            {
                id: 0,
                title: "Configuração de Veículos",
                value: "vehicle"
            },
            {
                id: 1,
                title: "Configuração de Trabalhadores",
                value: "workers"
            }
        ];

        return list.map(c => {
            return (
                <Menu.Item key={c.value} onClick={(event) => this.handleConfigDownloadOnClick(event)}>
                    {c.title}
                </Menu.Item>
            );
        });
    }


    renderDownloadConfig = () => {
        if (this.state.downloadConfigProgress) {
            return (
                <Progress type="circle" width={42} style={styles.progress} strokeColor= "#782777" percent={this.state.downloadConfigProgress} />
            );
        }

        return (
            <div style={styles.downloadButton}>
                <Dropdown overlay={this.getDropdownDownloadMenuConfig()} trigger={['click']}>
                    <div style={styles.downloadButton}>
                        <p style={styles.upload.text}>Baixar Configurações</p>
                    </div>
                </Dropdown>
            </div>
        );
    }

//DIVISAO

    getDropdownDownloadMenuExcelTemplate = () => {
        return( <Menu> {this.getDropdownDownloadMenuExcelTemplateItems()} </Menu> );
    };

    getDropdownDownloadMenuExcelTemplateItems = () => {
        const list = [
            {
                id: 0,
                title: "Modelo Veículos",
                value: "vehicle"
            },
            {
                id: 1,
                title: "Modelo Trabalhadores",
                value: "workers"
            },
            {
                id: 2,
                title: "Modelo Roteirização",
                value: "router"
            }
        ]

        return list.map(c => {
            return (
                <Menu.Item key={c.value} onClick={(event) => this.handleModelDownloadOnClick(event)}>
                    {c.title}
                </Menu.Item>
            );
        });
    }


    renderDownloadModel = () => {
        if (this.state.downloadModelProgress) {
            return (
                <Progress type="circle" width={42} style={styles.progress} strokeColor= "#782777" percent={this.state.downloadModelProgress} />
            );
        }

        return (
            <div onClick={this.onDownloadCsvModelClick}>
                <Dropdown overlay={this.getDropdownDownloadMenuExcelTemplate()} trigger={['click']}>
                    <Button style={styles.jsonButton} size={'large'} type="primary">
                        Baixar Modelo
                    </Button>
                </Dropdown>
            </div>
        );
    }

    renderTopButton = () => {
        return(
            <div style={styles.buttonDiv}>
                {this.renderDownloadConfig()}
                {this.renderDownloadModel()}
            </div>
        );
    }

    renderConvertExcelModalContent = () => {
        const {fileList} = this.state.convertTaskExcelModalData;
        const props = {
            onRemove: file => {
              this.setState(state => {
                const index = state.convertTaskExcelModalData.fileList.indexOf(file);
                const newFileList = state.convertTaskExcelModalData.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    convertTaskExcelModalData: {
                        ...state.convertTaskExcelModalData,  
                        fileList: newFileList,
                    }
                };
              });
            },
            beforeUpload: file => {
              this.setState(state => ({
                convertTaskExcelModalData: {
                    ...state.convertTaskExcelModalData,
                    fileList: [...state.convertTaskExcelModalData.fileList, file],
                }
              }));
              return false;
            },
            fileList,
        };
        return (
            <div>
                <p style={styles.upload.label}>Escolha um arquivo xls válido</p>
                <Upload {...props}>
                    <Button id='uploadButton' style={styles.upload.button} size={'large'} type="primary" onClick={() => {}}>
                        <img src={images.upload_icon} alt={"upload_icon"}/>
                        <p style={styles.upload.text}>Escolher arquivo</p>
                    </Button>
                </Upload>
            </div>
        );
    }

    renderConvertExcelModalLoading = () => {
        if (this.state.convertingTaskExcelToJson) {
            return (
                <div>
                    <Spin indicator={<Icon type="loading" style={styles.loading.icon} spin />} ></Spin>
                </div>
            )
        }
    }

    renderLoading = () => {
        if (this.state.loadingExcelFile) {
            return (
                <div>
                    <Spin indicator={<Icon type="loading" style={styles.loading.icon} spin />} ></Spin>
                </div>
            )
        }
    }


    renderConvertExcelModal = () => {
        return (
            <Modal
                title="Converter XLS para JSON"
                visible={this.state.showConvertExcelModal}
                onOk={this.handleConvertExcelModalOk}
                onCancel={this.handleConvertExcelModalCancel}
                okButtonProps={{style: this.state.convertTaskExcelModalData.fileList.length === 0 ? {...styles.sendAutoRouterRequest, opacity: 0.5} : styles.sendAutoRouterRequest, disabled: this.state.convertTaskExcelModalData.fileList.length === 0 }}
            >
                {this.renderConvertExcelModalContent()}
                {this.renderConvertExcelModalLoading()}
            </Modal>
        );
    }


    renderPageContentBody = () => {
        return(
            <div>
                <Tabs style={{margin:"-30px"}} onChange={this.handleOnChangeActiveTab}>
                    <TabPane style={styles.tab} tab="Atividades" key="TASK">
                        <ActivityManager/>
                    </TabPane>
                    <TabPane style={styles.tab} tab="Veículos" key="VEHICLE">
                        <VehicleManager/>
                    </TabPane>
                    <TabPane style={styles.tab} tab="Trabalhadores" key="WORKER">
                        <WorkerManager/> 
                    </TabPane>
                    <TabPane style={styles.tab} tab="Horas de Trabalho" key="WORKHOURS">
                        <WorkHoursManager/> 
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps({ uploadFileExcel, downwloadRouterExcelModels, downloadRouterUnityConfigExcel, convertTaskExcelToJson }) {
    return { uploadFileExcel, downwloadRouterExcelModels, downloadRouterUnityConfigExcel, convertTaskExcelToJson };
}

export default connect(mapStateToProps, { ...autoRouterActions })(AutoRouterManager); 