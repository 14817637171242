import React from 'react';
import PageContent from '../page-content/PageContent';
import  { Button, Modal, Spin, Icon, Progress, Table, notification} from 'antd';
import { images } from '../../assets';
import * as taskManagerActions from "../../actions/taskManagerActions";
import { connect } from "react-redux";
import moment from "moment";
import ShapefileModal from "./ShapefileModal";
import TaskModal from "./TaskModal";

const styles = {
    datePicker: {
        display: "inline-block"
    },
    select: {
        width: "100%"
    },
    label: {
        fontSize: "15px",
        marginBottom: "10px"
    },
    sideNavBar: {
        display: "inline-block",
        margin: "10px",
        verticalAlign: "top",
        float: "left"
    },
    button: {
        backgroundColor: "#782777", 
        border:"0px",
        width: "auto",
        color: "#fff"
    },
    buttonLink: {
        marginRight: "40px",
        color:  "#782777",
        fontSize: "12pt",
        width: "140px"
    },
    bodyItem: {
        display: "block",
        margin: "0px",
        verticalAlign: "top",
        overflow: "hidden",
        width: "auto",
        height: "calc(100% - 70px)"
    },
    buttonOk: {
        backgroundColor: '#782777',
        color: '#fff',
        borderColor: '#782777',
    },
    progress: {
        paddingRight: '25%'
    },
    upload: {
        label: {
            marginBottom: 0
        },
        button: {
            backgroundColor: '#ffffff',
            borderColor: '#781e76',
            color: '#781e76',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        },
        shapefileButton: {
            backgroundColor: '#781e76',
            borderColor: '#781e76',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: 0,
            color:  "#ffffff",
            fontSize: "12pt",
            marginRight: "16pt"
        },
        text: {
            paddingLeft: '10px',
            margin: 0
        },
        buttonDisabled: {
            backgroundColor: '#dddddd',
            borderColor: '#000',
            color: '#000',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            
        }
    },
    downloadButton: {
        textDecoration: 'underline',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0,
        color:  "#781e76",
        marginRight: "20px",
        cursor: 'pointer'
    },
    buttomAuto: {
        backgroundColor: '#fff',
        borderColor: '#781e76',
        color: '#781e76'
    },
    loading: {
        display: "inline-block",
        text: {
                display: "inline-block",
                marginLeft: "10px",
                marginRight: "20px"
            },
        icon: {
            display: "inline-block",
            color: "#782777",
            fontSize: 48
        }
    },
    loadingExcelFile: {
        icon: {
            display: "center",
            color: "#782777",
            fontSize: 60
        }
    },
    buttonDiv: {
        display: "flex"
    },
    modalDiv: {
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    actions: {
        div: {
            display: "flex",
            flexDirection: "row"
        },
        button: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginRight: "10px",
            backgroundColor: "#F0F3F7",
            color: "black",
            borderColor: '#781e76',
            borderRadius: "4px",
            maxWidth: "170px"
        },
        text: {
            margin: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            align: "center",
        },
    },
    form: {
        input: {
            border:"1px solid #d9d9d9",
            padding: "11px",
            height: "40px",
            borderRadius: "4px"
        },
        label: {
            display: "flex",
            flexDirection: "column",
            margin: "10px 0"
        },
    }
};

class taskManager extends PageContent {

    constructor(props) {
        super(props);

        this.state = {
            title: "Agendamento massivo",
            showModalSelectExcelFile: false,
            showModalSelectShape: false,
            modalSelectShapeData: null,
            createTask: {
                buttonDisabled: true,
                fileList: [],
            }, 
            loading: true,
            loadingExcelFile: false,
            downloadModelProgress: false,
            countShapeTask: 1,
            requestHash: null,
            shapefileData: [],
            activities: [],
            activityShapefileList: []
        }

    }


    componentDidMount() {
        this.getShapefileList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.uploadFileShape !== this.props.uploadFileShape) {
            if (!this.props.uploadFileShape.errorStatus) {
                this.setState({uploadShape: { buttonDisabled: true, fileList: []},uploadingShape: false, showModalSelectShape: false})
                this.props.getActivityShapefile();
                this.insertNotification();
            } else {
                this.setState({uploadingShape: false})
            }
        }
        if (prevProps.fetchActivityShapefile !== this.props.fetchActivityShapefile) {
            if (!this.props.fetchActivityShapefile.errorStatus){
                this.setState({
                    shapefileData: this.formatShapefileData(this.props.fetchActivityShapefile),
                    loading: false,
                    activityShapefileList: this.props.fetchActivityShapefile 
                })
            } else {
                this.setState({loading: false});
            }
        }
        if (prevProps.removeShapefile !== this.props.removeShapefile) {
            if (!this.props.removeShapefile.errorStatus) {
                this.props.getActivityShapefile();
                this.deleteNotification();
            } else {
                this.setState({loading: false});
            }
        }
    }

    getShapefileList = () => {
        this.setState({ loading: true });
        this.props.getActivityShapefile()
    }

    setProgress = (downloadModelProgress) => {
        downloadModelProgress = downloadModelProgress === 100 ? false : downloadModelProgress
        this.setState({ downloadModelProgress });
    };

    onDownloadExcelModelClick = () => {
        this.props.downloadExcelModel(this.setProgress);
    };

    convertActivityShapefile = (shapefileData) => {
        return shapefileData.map( (data, index) => ({
            key: index,
            id: data.id,
            user: data.created_by_name,
            modifiedAt: moment(data.modified_at),
            activity: {
                name: data.activity,
                id: data.activity_id
            },
            dueDate: moment(data.expiration_date)
        }));
    };

    formatShapefileData = (shapefileData) => {
        shapefileData.sort(
            function(a, b){
                var keyA = new Date(a.modified_at),
                    keyB = new Date(b.modified_at);
                if(keyA > keyB) return -1;
                if(keyA < keyB) return 1;
                return 0;
            } 
        );
        return shapefileData.map( (data, index) => ({
            key: index,
            id: data.id,
            user: data.created_by_name,
            modifiedAt: moment(data.modified_at).format("DD/MM/YYYY"),
            activity: {
                name: data.activity,
                id: data.activity_id
            },
            dueDate: moment(data.expiration_date).format("DD/MM/YYYY"),
            status: data.status,
            syncedAt: data.synced_at ? moment(data.synced_at).format("DD/MM/YYYY") : ""
        }));
    }

    getTitle = () => {
        return this.state.title;
    }

    showModal = () => {
        this.setState({
            showModalSelectExcelFile: true,
        })
    };

    showModalSelectShape = (rowData = null) => {
        this.setState({
            showModalSelectShape: true,
            modalSelectShapeData: rowData ? this.convertActivityShapefile(this.state.activityShapefileList).filter(a => a.id === rowData.id)[0] : null
        });
    }


    handleSelectExcelOK = e => {
        let params = {
            file: this.state.createTask.fileList[0]
        }
        this.props.uploadFileExcelToCreateTasks(params);
        this.setState({
            showModalSelectExcelFile: false,
            loadingExcelFile: true,
            createTask: {
                buttonDisabled: true,
                fileList: [],
            },
        });
    };
        
    handleSelectExcelCancel = e => {
        this.setState({
            showModalSelectExcelFile: false,
            createTask: {
                buttonDisabled: true,
                fileList: [],
            }
        });
    } 


    renderLoading = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                <Spin indicator={<Icon type="loading" style={{fontSize: 56}} spin />} />
            </div>
        );
    }

    renderTopButton = () => {
        return(
                <div style={styles.buttonDiv}>
                    {this.state.downloadModelProgress ?
                        <Progress data-cy="task-manager-download-model-progress" type="circle" width={42} style={styles.progress} strokeColor= "#782777" percent={this.state.downloadModelProgress} /> :
                        <div style={styles.downloadButton} onClick={this.onDownloadExcelModelClick}>
                            <p style={styles.upload.text}  data-cy="task-manager-download-model-button">Baixar Modelo</p>
                        </div>
                    }
                    <Button data-cy="task-manager-add-shapefile-button" style={styles.upload.shapefileButton} size={'large'} type="primary" onClick={() => this.showModalSelectShape()}>
                        Adicionar Shapefile
                    </Button>
                    <Button style={styles.upload.button} size={'large'} type="primary" onClick={this.showModal}>
                        <img src={images.plus_icon} alt={"plus_icon"} />
                        <p style={styles.upload.text}>Criar tarefas</p>
                    </Button>
                </div>
        );
    }


    confirmDelete = shapeId => {
        Modal.confirm({
            title: 'Deseja excluir ?',
            content: 'Ao confirmar você excluirá esse shapefile',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => this.deleteShapefile(shapeId),
            okButtonProps: {data_cy: 'confirm-delete-shapefile-ok-button'}
          });
    }

    deleteShapefile = shapeId => {
        this.setState({loading: true});
        this.props.deleteShapefile(shapeId);
    }

    insertNotification = () => {
        notification.open({
            message: 'Shapefile inserido com sucesso',
            icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
            },
        );
    }
    deleteNotification = () =>{
        notification.open({
          message: 'Shapefile excluído com sucesso',
          icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          },
        );
      };

    renderActions = (rowData) => {
        return (
            <div style={styles.actions.div}>
                <Button id={"task-manager-shapefile-edit-button-"+rowData.id} style={styles.actions.button} size={'small'} type="primary" onClick={() => this.showModalSelectShape(rowData)}>
                    <Icon type="edit" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Editar</p>
                </Button>
                <Button id={"task-manager-shapefile-delete-button-"+rowData.id} style={styles.actions.button} size={'small'} type="primary" onClick={() => this.confirmDelete(rowData.id)}>
                    <Icon type="delete" style={{color: "#782777"}} />
                    <p style={styles.actions.text}>Excluir</p>
                </Button>
            </div>
        );
    }

    renderStatus = (rowStatus) => ({
        'ACTIVE':           <div style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}}>
                                <p style={{...styles.actions.text, color: "black"}}>Válido</p>
                            </div>,

        'EXPIRED':          <div style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}}>
                                <p style={{...styles.actions.text, color: "red"}}>Expirado</p>
                            </div>,

        'NOT_PROCESSED':    <div style={{...styles.actions.button, borderStyle: "solid", borderWidth: "1px"}}>
                                <p style={{...styles.actions.text, color: "orange"}}>Processando</p>
                            </div>,
    })[rowStatus];

    onCloseShapefileModal = () => {
        this.setState({
            showModalSelectShape: false,
            modalSelectShapeData: null
        });
    }

    onCloseTaskModal = () => {
        this.setState({
            showModalSelectExcelFile: false
        })
    }

    renderTable = () => {
        const columns = [
            {
                title: "ID",
                width: "60px",
                dataIndex: "id",
                align: "left",
                key: "id"
            },
            {
                title: "Atividade",
                dataIndex: "activity.name",
                align: "left",
                key: "activity"
            },
            {
                title: "Status",
                dataIndex: "status",
                align: "left",
                key: "status",
                render: (text, record) => this.renderStatus(record.status)
            },
            {
                title: "Data de validade",
                dataIndex: "dueDate",
                align: "left",
                key: "dueDate"
            },
            {
                title: "Usuário",
                dataIndex: "user",
                align: "left",
                key: "user"
            },
            {
                title: "Última modificação",
                dataIndex: "modifiedAt",
                align: "left",
                key: "modifiedAt"
            },
            {
                title: "Última sincronização",
                dataIndex: "syncedAt",
                align: "left",
                key: "syncedAt"
            },
            {
                title: "Ações",
                width: "200px",
                dataIndex: "actions",
                fixed: 'right',
                align: "left",
                key: "actions",
                render: (text, record) => this.renderActions(record)
            }
        ];
        
        if (this.state.loading) {
            return this.renderLoading();
        }

        return(
            <div>
                <h2>Arquivos Shapes</h2>
                <Table 
                    data-cy='task-manager-shapefile-table'
                    columns={columns}
                    dataSource={this.state.shapefileData}
                    scroll={{ x: true, y: false }}
                    style={{paddingBottom: 0}} 
                    pagination={{
                        pageSize: 10
                    }}
                    />
            </div>
        );
                
    }

    renderPageContentBody = () => {
        
        return(
            <div>
                {this.renderTable()}
                <TaskModal 
                    visible={this.state.showModalSelectExcelFile}
                    onClose={this.onCloseTaskModal} />
                <ShapefileModal
                    visible={this.state.showModalSelectShape}
                    data={this.state.modalSelectShapeData}
                    onClose={this.onCloseShapefileModal}
                    getShapefileList={this.getShapefileList} />
                
            </div>
        );
    }

}

function mapStateToProps({ uploadFileExcel, uploadFileShape, downloadExcelModel, fetchActivityShapefile, fetchAllActivities, removeShapefile, fetchAllVehicleTypes }) {
    return { uploadFileExcel, uploadFileShape, downloadExcelModel, fetchActivityShapefile, fetchAllActivities, removeShapefile, fetchAllVehicleTypes };
}

export default connect(mapStateToProps, { ...taskManagerActions })(taskManager);